import React from 'react';
import { oneOfType, func, shape, object, node, elementType } from 'prop-types';
import TextField from '@material-ui/core/TextField';

const validRef = oneOfType([func, shape({ current: elementType })]);

const inputComponent = ({ inputRef, ...rest }) => (
  <div ref={inputRef} {...rest} />
);

inputComponent.propTypes = {
  inputRef: validRef.isRequired,
};

const Control = props => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
};

Control.propTypes = {
  children: node.isRequired,
  innerProps: shape({
    onMouseDown: func.isRequired,
  }).isRequired,
  innerRef: validRef.isRequired,
  selectProps: oneOfType([object]).isRequired,
};

export default Control;
