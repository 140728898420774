import { object, string, mixed } from 'yup';

export default object().shape({
  title: string()
    .required()
    .label('Title'),
  file: mixed()
    .required()
    .label('Logo'),
});
