import React from 'react';
import { func, node, oneOf } from 'prop-types';
import {
  Snackbar as MuiSnackBar,
  SnackbarContent,
  IconButton,
} from '@material-ui/core';
import {
  Info as InfoIcon,
  Error as ErrorIcon,
  Close as CloseIcon,
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
} from '@material-ui/icons';
import { amber, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  danger: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  danger: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const propTypes = {
  onClose: func.isRequired,
  message: node.isRequired,
  variant: oneOf(['success', 'warning', 'danger', 'info']).isRequired,
};

const Snackbar = ({ message, variant, ...props }) => {
  const classes = useStyles();
  const Icon = variantIcon[variant];
  const { onClose } = props;

  return (
    <MuiSnackBar {...props}>
      <SnackbarContent
        className={classes[variant]}
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={`${classes.icon} ${classes.iconVariant}`} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </MuiSnackBar>
  );
};

Snackbar.propTypes = propTypes;

export default Snackbar;
