import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { node } from 'prop-types';
import Drawer from '../Drawer/Drawer';
import AppBar from '../AppBar/AppBar';
import Footer from '../Footer/Footer';

const useStyles = makeStyles(theme => ({
  root: { display: 'flex' },
  wrapper: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    overflowY: 'auto',
    maxHeight: '100vh',
    flexDirection: 'column',
  },
  content: {
    flex: '1 1 0',
    display: 'flex',
    paddingBottom: 0,
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginTop: theme.spacing(8),
  },
  footer: { flex: '0 0 auto' },
}));

const propTypes = {
  children: node.isRequired,
};

const LoggedInLayout = ({ children }) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  return (
    <div className={classes.root}>
      <AppBar toggleHandler={handleDrawerToggle} />
      <Drawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
      <main className={classes.wrapper}>
        <div className={classes.content}>{children}</div>
        <div className={classes.footer}>
          <Footer align="left" />
        </div>
      </main>
    </div>
  );
};

LoggedInLayout.propTypes = propTypes;

export default LoggedInLayout;
