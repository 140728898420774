import React from 'react';
import { bool, func, shape } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '../../../components/TextField/TextField';
import AutoCompleteSelectField from '../../../components/AutoCompleteSelectField/AutoCompleteSelectField';
import ModalForm from '../../../components/ModalForm/ModalForm';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import { useAuth } from '../../../contexts/AuthContext';
import {
  UserResource,
  CustomerResource,
  ConversationResource,
  PatientAccountResource,
  ClinicResource,
} from '../../../services/http';
import validations from './validations';

const propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  isGeneral: bool,
  onConversarionCreated: func,
  data: shape({}),
};

const defaultProps = {
  data: {},
  isGeneral: false,
  onConversarionCreated: () => {},
};

const NewMessageForm = ({
  open,
  data,
  onClose,
  isGeneral,
  onConversarionCreated,
}) => {
  const { showError, showSuccess } = useSnackBar();
  const { isAttorney, isParalegal, isSuperAdmin } = useAuth();
  const initialValues = {
    body: data.body || '',
    subject: data.subject || '',
    customerId: data.customerId || '',
    clinicId: data.clinicId || '',
    recipientId: data.recipientId || '',
    patientAccountId: data.patientAccountId || '',
    haveCustomer: isSuperAdmin && !data.patientAccountId,
    haveRecipient: !(isAttorney || isParalegal) && isGeneral,
    havePatient: !isGeneral,
  };

  const handleSubmit = async (
    {
      patientAccountId,
      haveRecipient,
      haveCustomer,
      havePatient,
      recipientId,
      customerId,
      clinicId,
      ...fields
    },
    { setSubmitting },
  ) => {
    try {
      const conversation = await ConversationResource.save({
        ...fields,
        ...(recipientId && { recipientId: recipientId.value }),
        ...(clinicId && { clinicId: clinicId.value }),
        ...(patientAccountId && { patientAccountId: patientAccountId.value }),
      });

      showSuccess('The message was sent successfully');
      onConversarionCreated(conversation);
      onClose();
    } catch {
      showError('Sorry, an error occurred. Please try again later.');
    } finally {
      setSubmitting(false);
    }
  };

  const fetchPaAccountOptions = async (name, customerId) => {
    try {
      const { results } = await PatientAccountResource.getAll({
        patientName: name,
        customerId,
        limit: 5,
      });
      return results.map(({ id, accountNumber, patient }) => ({
        label: `#${accountNumber} - ${patient.firstName} ${patient.lastName}`,
        value: id,
      }));
    } catch {
      return [];
    }
  };
  const fetchUserOptions = async (name, customerId) => {
    try {
      const { results } = await UserResource.getAll({
        name,
        customerId,
        isActive: true,
        limit: 5,
      });
      return results.map(user => ({
        label: user.name,
        value: user.id,
      }));
    } catch {
      return [];
    }
  };
  const fetchCustomerOptions = async name => {
    try {
      const { results } = await CustomerResource.getAll({ name, limit: 5 });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };

  const fetchClinicsOptions = async name => {
    try {
      const { results } = await ClinicResource.getAll({ name, limit: 5 });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };

  return (
    <ModalForm
      title="New Message"
      submitLabel="Send"
      maxWidth="xs"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validations}
      render={({
        setFieldValue,
        values: { haveCustomer, haveRecipient, customerId },
      }) => (
        <Grid container spacing={2}>
          {haveCustomer && (
            <Grid item xs={12}>
              <AutoCompleteSelectField
                label="Customer"
                name="customerId"
                onChange={() => {
                  setFieldValue('recipientId', '');
                  setFieldValue('patientAccountId', '');
                }}
                loadOptions={fetchCustomerOptions}
              />
            </Grid>
          )}
          {!isGeneral && (
            <Grid item xs={12}>
              <AutoCompleteSelectField
                name="patientAccountId"
                disabled={
                  !!data.patientAccountId || (!customerId && haveCustomer)
                }
                label={isAttorney || isParalegal ? 'Client' : 'Patient Account'}
                loadOptions={n => fetchPaAccountOptions(n, customerId.value)}
              />
            </Grid>
          )}
          {isGeneral && (isAttorney || isParalegal) && (
            <Grid item xs={12}>
              <AutoCompleteSelectField
                label="To"
                name="clinicId"
                loadOptions={n => fetchClinicsOptions(n, customerId.value)}
              />
            </Grid>
          )}
          {haveRecipient && (
            <Grid item xs={12}>
              <AutoCompleteSelectField
                label="To"
                name="recipientId"
                disabled={!customerId && haveCustomer}
                loadOptions={n => fetchUserOptions(n, customerId.value)}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="subject"
              type="text"
              label="Subject"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              rows={3}
              rowsMax={3}
              name="body"
              type="text"
              label="Comment"
              variant="outlined"
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

NewMessageForm.propTypes = propTypes;
NewMessageForm.defaultProps = defaultProps;

export default NewMessageForm;
