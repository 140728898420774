export const ALL = 1;
export const NONE = 2;
export const RELEASE_ONLY = 3;

export const NOTIFICATIONS_LABEL = {
  [ALL]: 'All',
  [NONE]: 'None',
  [RELEASE_ONLY]: 'Release only',
};

export const NOTIFICATIONS_LIST = Object.keys(NOTIFICATIONS_LABEL).map(
  value => ({
    label: NOTIFICATIONS_LABEL[value],
    value,
  }),
);
