import { createMuiTheme } from '@material-ui/core/styles';
import { red, blue, grey } from '@material-ui/core/colors';
import logo from '../assets/images/Logo-sidebar@2x.png';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue[600],
      light: blue[300],
      dark: blue[900],
    },
    secondary: {
      main: '#0D509F',
      dark: '#373D49',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: grey[100],
    },
  },
  drawer: {
    width: 230,
  },
  overrides: {
    MuiSvgIcon: {
      colorPrimary: {
        color: blue[900],
      },
    },
  },
  logo: {
    image: logo,
    alt: 'Accessible Beltway Clinics',
  },
});

export default theme;
