import { object, string, ref } from 'yup';

export default object().shape({
  oldPassword: string()
    .required()
    .label('Old Password'),
  password1: string()
    .test(
      'Incorrect Format',
      'Your password must be at least 8 characters long, contain at least one uppercase letter, at least one lowercase letter, at least one number and at least one special character.',
      value =>
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.,@$!%*#?&])[A-Za-z\d.,@$!%*#?&]{8,}$/.test(
          value,
        ),
    )
    .required()
    .label('New password'),
  password2: string()
    .required()
    .oneOf([ref('password1')], 'Passwords do not match')
    .label('Confirm new password'),
});
