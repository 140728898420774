import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import PatientAccountList from './PatientAccountList/PatientAccountList';
import PatientAccountDetail from './PatientAccountDetail/PatientAccountDetail';

const PatientAccountPage = () => (
  <Switch>
    <PrivateRoute
      exact
      path="/patient-account"
      component={PatientAccountList}
    />
    <PrivateRoute
      exact
      path="/patient-account/:id"
      component={PatientAccountDetail}
    />
    <Redirect to="/patient-account" />
  </Switch>
);

export default PatientAccountPage;
