import { object, string, number, date, array } from 'yup';
import { statusLabel } from '../../../constants/patientAccounts';

export default object().shape({
  customerId: object()
    .when('haveCustomer', {
      is: true,
      then: object().required(),
    })
    .label('Customer'),
  firstName: string()
    .required()
    .label('First Name'),
  lastName: string()
    .required()
    .label('Last Name'),
  accountNumber: string()
    .matches(/^[a-zA-Z0-9#,\s]*$/, {
      message: 'Invalid format',
      excludeEmptyString: true,
    })
    .required()
    .label('Account number'),
  dateOfAccident: date()
    .required()
    .label('Date of accident'),
  clinicId: object()
    .required()
    .label('Clinic'),
  firmId: object()
    .required()
    .label('Firm'),
  status: string()
    .oneOf(
      Object.keys(statusLabel),
      `Allowed values: ${Object.values(statusLabel).join()}`,
    )
    .required()
    .label('Status'),
  totalBilled: number()
    .min(0)
    .required()
    .label('Total billed'),
  attorneys: array()
    .required()
    .label('Attorneys'),
});
