import React from 'react';
import { string, bool, func, shape } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import { CustomerResource } from '../../../services/http';
import TextField from '../../../components/TextField/TextField';
import ModalForm from '../../../components/ModalForm/ModalForm';
import ColorField from '../../../components/ColorField/ColorField';
import UploadField from '../../../components/UploadField/UploadFile';
import validations from './validations';

const propTypes = {
  open: bool.isRequired,
  title: string.isRequired,
  onClose: func.isRequired,
  data: shape({}),
};

const defaultProps = {
  data: {},
};

const CustomerForm = ({ title, open, onClose, data }) => {
  const initialValues = {
    fax: data.fax || '',
    name: data.name || '',
    logo: data.logo || '',
    maxUsersAllowed: data.maxUsersAllowed || '',
    primary: data.theme ? data.theme.primary : '',
    secondary: data.theme ? data.theme.secondary : '',
  };
  const { showSuccess, showError } = useSnackBar();
  const handleSubmit = async (
    { primary, secondary, ...values },
    { setSubmitting },
  ) => {
    try {
      const customer = await CustomerResource.saveMultipart({
        ...values,
        ...(data.id && { id: data.id }),
        theme: JSON.stringify({ primary, secondary }),
      });

      onClose(null, 'submitClick', customer);
      showSuccess(`Successfully ${data.id ? 'updated' : 'added'} Customer`);
    } catch {
      showError('An error occurs, please try again.');
    } finally {
      setSubmitting(false);
    }
  };
  const handleDownload = () => window.open(data.logo, '__blank', 'noopener');

  return (
    <ModalForm
      maxWidth="sm"
      open={open}
      title={title}
      onClose={onClose}
      onSubmit={handleSubmit}
      validationSchema={validations}
      initialValues={initialValues}
      submitLabel={data.id ? 'Save' : 'Create'}
      render={() => (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="name"
                type="text"
                label="Customer Name"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="fax"
                type="text"
                label="Fax"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Typography paragraph variant="h6">
            Customization options
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="maxUsersAllowed"
                type="number"
                label="Maximum Users Allowed"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <UploadField
                name="logo"
                label="Customer Logo"
                accept="image/png,image/jpeg,image/jpg"
                onDownload={handleDownload}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ColorField
                fullWidth
                name="primary"
                label="UI Primary Color"
                placeholder="#ffffff"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ColorField
                fullWidth
                name="secondary"
                label="UI Secondary Color"
                placeholder="#ffffff"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </>
      )}
    />
  );
};

CustomerForm.propTypes = propTypes;
CustomerForm.defaultProps = defaultProps;

export default CustomerForm;
