import React from 'react';
import {
  Grid,
  Typography,
  withStyles,
  CircularProgress,
  Divider as MuiDivider,
  Button,
} from '@material-ui/core';
import Card from '../../../components/Card/Card';
import BasicInfo from './BasicInfo/BasicInfo';
import FileTable from './Common/FileTable';
import Payment from './Payment/Payment';
import PatientAccountDetailProvider, {
  usePADetailContext,
} from './PatientAccountDetailContext';
import useModal from '../../../hooks/modal';
import PatientAccountForm from '../PatientAccountForm/PatientAccountForm';
import { Can, useAuth } from '../../../contexts/AuthContext';

const Divider = withStyles(({ spacing }) => ({
  root: { margin: spacing(2, 0) },
  light: { backgroundColor: 'transparent' },
}))(MuiDivider);

const Loader = () => (
  <Grid container justify="center">
    <CircularProgress />
  </Grid>
);

const Content = () => {
  const {
    reports,
    paAccount,
    handleEditReport,
    billingStatements,
    externalRecords,
    handleCreateReport,
    handleDeleteReport,
    handleDownloadReport,
    handleEditBillingStatement,
    handleCreateBillingStatement,
    handleDeleteBillingStatement,
    handleDownloadBillingStatement,
    handleEditExternalRecord,
    handleCreateExternalRecord,
    handleDeleteExternalRecord,
    handleDownloadExternalRecord,
    setPatientAccount,
  } = usePADetailContext();
  const { isAttorney, isParalegal } = useAuth();
  const { isModalOpen, showModal, hideModal } = useModal();
  const handleClose = (e, eventType, patientAccountEdited) => {
    if (eventType === 'submitClick') {
      setPatientAccount(patientAccountEdited);
    }

    hideModal();
  };

  return (
    <Card
      useBack
      title={`${
        isAttorney || isParalegal ? 'Client' : 'Patient Account'
      } Detail`}
    >
      <Grid container alignItems="center" justify="space-between">
        <Grid item>
          <Typography variant="h6">
            {paAccount.patient.firstName} {paAccount.patient.lastName}
          </Typography>
          <Typography variant="body2">
            Account Number #{paAccount.accountNumber}
          </Typography>
        </Grid>
        <Can I="update" a="PatientAccount">
          <Grid item>
            <Button variant="outlined" color="primary" onClick={showModal}>
              Edit Patient Account
            </Button>
          </Grid>
        </Can>
      </Grid>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <BasicInfo />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Payment />
        </Grid>
      </Grid>
      <Can I="read" a="BillingStatement">
        <Divider light />
        <FileTable
          title="Billing Statements"
          subject="BillingStatement"
          data={billingStatements}
          onCreate={handleCreateBillingStatement}
          onEdit={handleEditBillingStatement}
          onDelete={handleDeleteBillingStatement}
          onDownload={handleDownloadBillingStatement}
        />
      </Can>
      <Can I="read" a="Report">
        <Divider light />
        <FileTable
          title="Reports"
          subject="Report"
          data={reports}
          onCreate={handleCreateReport}
          onEdit={handleEditReport}
          onDelete={handleDeleteReport}
          onDownload={handleDownloadReport}
        />
      </Can>
      <Can I="read" a="ExternalRecords">
        <Divider light />
        <FileTable
          title="External Records"
          subject="ExternalRecords"
          data={externalRecords}
          onCreate={handleCreateExternalRecord}
          onEdit={handleEditExternalRecord}
          onDelete={handleDeleteExternalRecord}
          onDownload={handleDownloadExternalRecord}
        />
      </Can>
      <PatientAccountForm
        data={paAccount}
        title={`Edit patient account: #${paAccount.accountNumber}`}
        open={isModalOpen}
        onClose={handleClose}
      />
    </Card>
  );
};

const PatientAccountDetail = () => (
  <PatientAccountDetailProvider>
    {loading => (loading ? <Loader /> : <Content />)}
  </PatientAccountDetailProvider>
);

export default PatientAccountDetail;
