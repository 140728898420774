import React from 'react';
import { bool, string, func } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import DefaultSearch from './DefaultSearch';

const useStyles = makeStyles(({ palette }) => ({
  header: {
    maxHeight: 50,
    color: palette.primary.contrastText,
    backgroundColor: palette.primary.main,
  },
  headerAction: {
    alignSelf: 'auto',
    marginTop: 'inherit',
  },
}));

const propTypes = {
  title: string.isRequired,
  renderSearch: func,
  search: bool,
};

const defaultProps = {
  renderSearch: null,
  search: true,
};

const Header = ({ title, search, renderSearch, ...props }) => {
  const classes = useStyles();
  const Search = renderSearch || DefaultSearch;

  return (
    <CardHeader
      title={title}
      action={search && <Search {...props} />}
      titleTypographyProps={{ variant: 'body1' }}
      classes={{ root: classes.header, action: classes.headerAction }}
    />
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
