import React from 'react';
import Card from '@material-ui/core/Card';
import Header from './Header';
import Table from './Table';

const DataTable = props => (
  <Card>
    <Header {...props} />
    <Table {...props} />
  </Card>
);

export default DataTable;
