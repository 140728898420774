import React from 'react';
import { func, bool, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  List,
  Hidden,
  ListItem,
  ListItemText,
  Drawer as MuiDrawer,
} from '@material-ui/core';
import Link from '../../../components/Link/Link';
import { usePermissions, useAuth } from '../../../contexts/AuthContext';

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: theme.drawer.width,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: theme.drawer.width,
  },
  active: {
    color: theme.palette.primary.contrastText,
    borderRight: '4px solid',
    borderRightColor: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.light} !important`,
  },
}));

const propTypes = {
  mobileOpen: bool.isRequired,
  location: shape({}).isRequired,
  handleDrawerToggle: func.isRequired,
};

const Drawer = ({ mobileOpen, handleDrawerToggle, location }) => {
  const theme = useTheme();
  const classes = useStyles();
  const permissions = usePermissions();
  const { isAttorney, isParalegal } = useAuth();
  const paAccountText =
    isAttorney || isParalegal ? 'Clients' : 'Patient Accounts';
  const items = [
    {
      text: 'Dashboard',
      path: '/',
      show: true,
    },
    {
      text: 'Users',
      path: '/users',
      show: permissions.can('viewPage', 'User'),
    },
    {
      text: paAccountText,
      path: '/patient-account',
      show: permissions.can('viewPage', 'PatientAccount'),
    },
    {
      text: 'Customers',
      path: '/customers',
      show: permissions.can('viewPage', 'Customer'),
    },
    {
      text: 'Firms',
      path: '/firms',
      show: permissions.can('viewPage', 'Firm'),
    },
    {
      text: 'Clinics',
      path: '/clinics',
      show: permissions.can('viewPage', 'Clinic'),
    },
    {
      text: 'Locations',
      path: '/locations',
      show: permissions.can('viewPage', 'Location'),
    },
    {
      text: 'Messages',
      path: '/messages',
      show: true,
    },
    {
      text: 'Find a Provider',
      path: '/find-a-provider',
      show: true,
    },
    {
      text: 'Activity Log',
      path: '/activity-log',
      show: permissions.can('viewPage', 'ActivityLog'),
    },
  ];

  const renderItems = (mobile = false) =>
    items.map(
      (item, key) =>
        item.show && (
          <Link
            color="textPrimary"
            to={item.path}
            key={String(key)}
            underline="none"
            {...(mobile ? { onClick: handleDrawerToggle } : null)}
          >
            <ListItem
              selected={location.pathname === item.path}
              button
              classes={{
                selected: classes.active,
              }}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          </Link>
        ),
    );

  return (
    <nav className={classes.drawer}>
      <Hidden mdUp implementation="css">
        <MuiDrawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <List>{renderItems(true)}</List>
        </MuiDrawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <MuiDrawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <div className={classes.toolbar} />
          {renderItems()}
        </MuiDrawer>
      </Hidden>
    </nav>
  );
};

Drawer.propTypes = propTypes;

export default withRouter(Drawer);
