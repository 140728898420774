import { object, string, number } from 'yup';
import states from '../../../constants/states';

export default object().shape({
  name: string()
    .required()
    .label('Name'),
  customerId: object()
    .when('haveCustomer', {
      is: true,
      then: object().required(),
    })
    .label('Customer'),
  state: string()
    .oneOf(states.map(i => i.value), 'Select a valid option')
    .required()
    .label('State'),
  street: string()
    .required()
    .label('Street'),
  city: string()
    .test('city', value => /^[a-zA-Z\s]*$/.test(value))
    .required()
    .label('City'),
  zipCode: number()
    .required()
    .test('zipCode', value => /^\d{5}(-\d{4})?(?!-)$/.test(value))
    .label('Zip code'),
});
