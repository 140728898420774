import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import MessageProvider from './MessagesContext';
import EmptyMessages from './EmptyMessages/EmptyMessages';
import MessagesArea from './MessagesArea/MessagesArea';
import Loading from './LoadingArea/LoadingArea';
import Sidebar from './Sidebar/Sidebar';
import Tabs from './Tabs/Tabs';

const useStyles = makeStyles(({ breakpoints }) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 180px)',
    [breakpoints.down('xs')]: {
      height: 'calc(100vh - 200px)',
    },
  },
  container: {
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    height: 'calc(100% - 48px)',
  },
}));

const Messages = () => {
  const classes = useStyles();
  return (
    <MessageProvider>
      {(loadingTab, haveItems) => {
        const Content = haveItems ? (
          <Fragment>
            <Sidebar />
            <MessagesArea />
          </Fragment>
        ) : (
          <EmptyMessages />
        );

        return (
          <div className={classes.wrapper}>
            <Tabs />
            <Paper className={classes.container}>
              {loadingTab ? <Loading /> : Content}
            </Paper>
          </div>
        );
      }}
    </MessageProvider>
  );
};

export default Messages;
