import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useAuth } from '../contexts/AuthContext';
import Link from '../components/Link/Link';
import Image404 from '../assets/images/404.png';

const useStyles = makeStyles(({ typography, palette }) => ({
  image: { width: '100%' },
  container: { height: '100%' },
  text404: {
    fontWeight: 500,
    color: palette.primary.dark,
    fontSize: typography.pxToRem(180),
  },
}));

const NoMatchPage = () => {
  const classes = useStyles();
  const { isLoggedIn } = useAuth();
  const logged = isLoggedIn();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.container}
    >
      <Grid item sm={6} md={5}>
        {logged ? (
          <img src={Image404} alt="404" className={classes.image} />
        ) : (
          <Typography variant="h1" align="center" className={classes.text404}>
            404
          </Typography>
        )}
        <Typography variant="h5" align="center">
          Page Not Found
        </Typography>
        {!logged ? (
          <Typography variant="body1" align="center">
            Back to <Link to="/account/login">Login</Link>
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default NoMatchPage;
