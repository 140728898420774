import React, { Fragment, useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Select,
  Divider,
  MenuItem,
  Typography,
  makeStyles,
  TablePagination,
} from '@material-ui/core';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import Link from '../../components/Link/Link';
import Card from '../../components/Card/Card';
import Fab from '../../components/Fab/Fab';
import useModal from '../../hooks/modal';
import useDataTable from '../../hooks/data-table';
import usePagination from '../../hooks/pagination-table';
import { useSnackBar } from '../../contexts/SnackBarContext';
import { CompanyDirectoryResource } from '../../services/http';
import { Can, useAuth } from '../../contexts/AuthContext';
import CompanyDirectoryBio from './CompanyDirectoryBio/CompanyDirectoryBio';
import CompanyDirectoryForm from './CompanyDirectoryForm/CompanyDirectoryForm';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  login: { marginTop: spacing(4) },
  customSearchFieldsContainer: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  alphabetSelect: {
    marginLeft: spacing(2),
    color: palette.primary.contrastText,
    '& svg': { color: palette.primary.contrastText },
    '&:hover:before, &:before, &:after': {
      borderBottomColor: `${palette.primary.contrastText} !important`,
    },
  },
  loggedOut: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [breakpoints.down('sm')]: { width: '95%' },
  },
}));

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const CompanyDirectoryPage = () => {
  const classes = useStyles();
  const { isLoggedIn } = useAuth();
  const [open, setOpen] = useState(false);
  const [letter, setLetter] = useState('');
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [companies, setCompany, delCompany] = useDataTable();
  const { total, setTotal, limit, setLimit, page, setPage } = usePagination();
  const { isModalOpen, modalTitle, showModal, hideModal } = useModal();
  const { showError, showSuccess } = useSnackBar();
  const isLoggedOut = !isLoggedIn();
  const handleCreate = () => {
    setFormData();
    showModal('Add new company directory');
  };
  const handleEdit = item => {
    setFormData(item);
    showModal(`Edit company directory ${item.firstName} ${item.lastName}`);
  };
  const handleClose = (e, eventType, item) => {
    if (eventType === 'submitClick') {
      setCompany(item);
    }

    hideModal();
  };
  const handleOpenDialog = item => {
    setFormData(item);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setFormData({});
    setOpen(false);
  };
  const handleConfirmDialog = async () => {
    const { id } = formData;

    try {
      await CompanyDirectoryResource.remove(id);
      delCompany(id);
      showSuccess('Successfully deleted company directory');
    } catch {
      showError('There was an error deleting the company directory');
    } finally {
      handleCloseDialog();
    }
  };

  const customSearch = () => (
    <div className={classes.customSearchFieldsContainer}>
      <Typography>Find by: </Typography>
      <Select
        value={letter}
        className={classes.alphabetSelect}
        onChange={n => setLetter(n.target.value)}
      >
        {alphabet.map(q => (
          <MenuItem key={q} value={q}>
            {q}
          </MenuItem>
        ))}
      </Select>
    </div>
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { results, count } = await CompanyDirectoryResource.getAll({
          page: page + 1,
          letter,
          limit,
        });
        setTotal(count);
        setCompany(results);
      } catch {
        showError('An error occurs while loading the data table.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setCompany, showError, setLoading, page, limit, setTotal, letter]);

  return (
    <div className={clsx({ [classes.loggedOut]: isLoggedOut })}>
      <Card
        noPadding
        loading={loading}
        title="Company Directory"
        renderAction={customSearch}
      >
        {companies.map(company => (
          <Fragment key={company.id}>
            <CompanyDirectoryBio
              onEdit={() => handleEdit(company)}
              onDelete={() => handleOpenDialog(company)}
              {...company}
            />
            <Divider />
          </Fragment>
        ))}
        <TablePagination
          page={page}
          count={total}
          component="div"
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
          onChangePage={(e, newPage) => setPage(newPage)}
          onChangeRowsPerPage={e => setLimit(e.target.value)}
        />
      </Card>
      {isLoggedOut && (
        <Typography align="center" className={classes.login}>
          Back to <Link to="/account/login">Login</Link>
        </Typography>
      )}
      <Can I="create" a="CompanyDirectory">
        <Fab color="secondary" onClick={handleCreate} />
      </Can>
      <CompanyDirectoryForm
        title={modalTitle}
        open={isModalOpen}
        onClose={handleClose}
        data={formData}
      />
      <ModalDialog
        fullWidth
        open={open}
        maxWidth="xs"
        submitLabel="Yes, I’m sure"
        title="Confirm Delete"
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
        subtitle="Are you sure you want to delete this company directory?"
      />
    </div>
  );
};

export default CompanyDirectoryPage;
