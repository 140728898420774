import React from 'react';
import { string, bool, func, shape } from 'prop-types';
import { Grid } from '@material-ui/core';
import TextField from '../../../components/TextField/TextField';
import ModalForm from '../../../components/ModalForm/ModalForm';
import AutoCompleteSelectField from '../../../components/AutoCompleteSelectField/AutoCompleteSelectField';
import { LocationResource, CustomerResource } from '../../../services/http';
import { Can, usePermissions } from '../../../contexts/AuthContext';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import validations from './validations';

const propTypes = {
  title: string.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  data: shape({}),
};

const defaultProps = {
  data: {},
};

const LocationsForm = ({ title, open, onClose, data }) => {
  const permissions = usePermissions();
  const initialValues = {
    name: data.name || '',
    customerId: data.customer
      ? { label: data.customer.name, value: data.customer.id }
      : '',
    haveCustomer: permissions.can('create', 'Location', 'customerId'),
  };
  const { showError, showSuccess } = useSnackBar();
  const handleSubmit = async (
    { haveCustomer, customerId, ...values },
    { setSubmitting },
  ) => {
    try {
      const location = await LocationResource.save({
        ...values,
        ...(data.id && { id: data.id }),
        ...(customerId && { customerId: customerId.value }),
      });

      onClose(null, 'submitClick', location);
      showSuccess(`Successfully ${data.id ? 'updated' : 'added'} Location`);
    } catch ({ message }) {
      showError(message || 'An error occurs, please try again.');
    } finally {
      setSubmitting(false);
    }
  };
  const fetchCustomerOptions = async name => {
    try {
      const { results } = await CustomerResource.getAll({ name });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };

  return (
    <ModalForm
      fullWidth
      maxWidth="xs"
      title={title}
      open={open}
      onClose={onClose}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validations}
      submitLabel={data.id ? 'Save' : 'Create'}
      render={() => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Can I="create customerId" a="Location">
              <AutoCompleteSelectField
                label="Customer"
                name="customerId"
                loadOptions={fetchCustomerOptions}
              />
            </Can>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="name"
              type="text"
              label="Location"
              variant="outlined"
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

LocationsForm.propTypes = propTypes;
LocationsForm.defaultProps = defaultProps;

export default LocationsForm;
