import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Grid, Button, IconButton, InputAdornment } from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import { AuthResource } from '../../../services/http';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import TextField from '../../../components/TextField/TextField';
import validations from './validations';

const ChangePasswordForm = () => {
  const initialValues = {
    oldPassword: '',
    password1: '',
    password2: '',
  };
  const { showSuccess, showError } = useSnackBar();
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    password1: false,
    password2: false,
  });
  const handleSubmit = async (fields, { setSubmitting, setFieldValue }) => {
    try {
      await AuthResource.changePassword(fields);

      showSuccess('Password was successfully updated.');
    } catch {
      showError('Sorry, an error occurred. Please try again later.');
    } finally {
      setSubmitting(false);
      setFieldValue('password1', '', false);
      setFieldValue('password2', '', false);
      setFieldValue('oldPassword', '', false);
    }
  };
  const handleMouseDownPassword = event => event.preventDefault();
  const handleClickShowPassword = name => {
    setShowPassword({ ...showPassword, [name]: !showPassword[name] });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validations}
      onSubmit={handleSubmit}
      render={({ isSubmitting }) => (
        <Form>
          <Grid container spacing={2} justify="flex-end">
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                name="oldPassword"
                type={showPassword.oldPassword ? 'text' : 'password'}
                label="Old Password"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleClickShowPassword('oldPassword')}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword.oldPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                name="password1"
                type={showPassword.password1 ? 'text' : 'password'}
                label="New Password"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleClickShowPassword('password1')}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword.password1 ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                name="password2"
                type={showPassword.password2 ? 'text' : 'password'}
                label="Confirm New Password"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleClickShowPassword('password2')}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword.password2 ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid container item justify="flex-end" xs={12} sm={4}>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                Update Password
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    />
  );
};

export default ChangePasswordForm;
