import axios from 'axios';
import rootConfigs from '../../configs';
import events from '../../utils/events';

const createClient = configs => {
  const {
    prefixToken = 'Bearer',
    tokenName = 'token',
    storage = localStorage,
    requestInterceptors = () => [],
    responseInterceptors = () => [],
    ...axiosConfigs
  } = configs;
  const http = axios.create(axiosConfigs);
  const setToken = token => {
    http.defaults.headers.common.Authorization = `${prefixToken} ${token}`;
  };

  http.$getToken = () => storage.getItem(tokenName);

  http.$setToken = token => {
    setToken(token);
    storage.setItem(tokenName, token);
  };

  http.$clearToken = () => {
    delete http.defaults.headers.common.Authorization;
    storage.removeItem(tokenName);
  };

  requestInterceptors(http).forEach(interceptor =>
    http.interceptors.request.use(...interceptor),
  );
  responseInterceptors(http).forEach(interceptor =>
    http.interceptors.response.use(...interceptor),
  );

  if (http.$getToken()) {
    setToken(http.$getToken());
  }

  return http;
};

const client = createClient({
  ...rootConfigs.http,
  responseInterceptors(http) {
    return [
      [
        response => {
          const { data } = response;
          return data;
        },
        error => {
          const { response } = error;

          if (response.status === 401) {
            http.$clearToken();
            events.emit('logout');
          }

          return Promise.reject(response.data);
        },
      ],
    ];
  },
});

export default client;
