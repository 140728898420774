import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import moment from 'moment';
import {
  Grid,
  Button,
  TableRow,
  TableCell,
  Typography,
  ExpansionPanel,
  TablePagination,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { useSnackBar } from '../../contexts/SnackBarContext';
import { ActivityLogResource, UserResource } from '../../services/http';
import AutoCompleteSelectField from '../../components/AutoCompleteSelectField/AutoCompleteSelectField';
import DataTable from '../../components/DataTable/DataTable';
import TextField from '../../components/TextField/TextField';
import usePagination from '../../hooks/pagination-table';
import useDataTable from '../../hooks/data-table';
import validations from './validations';

const useStyles = makeStyles(() => ({
  form: { width: '100%' },
}));

const ActivityLogPage = () => {
  const initialValues = {
    toDate: '',
    fromDate: '',
    loggedUserId: '',
    affectedUserId: '',
  };
  const classes = useStyles();
  const { showError } = useSnackBar();
  const [logs, setLogs] = useDataTable();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [searchParams, setSearchParams] = useState({});
  const { total, setTotal, limit, setLimit, page, setPage } = usePagination();

  const handleReset = () => setSearchParams({});
  const handleExpansion = () => setExpanded(!expanded);
  const handleSubmit = (values, { setSubmitting }) => {
    const paramas = {
      ...(values.toDate && { toDate: values.toDate }),
      ...(values.fromDate && { fromDate: values.fromDate }),
      ...(values.loggedUserId && { loggedUserId: values.loggedUserId.value }),
      ...(values.affectedUserId && {
        affectedUserId: values.affectedUserId.value,
      }),
    };

    setSearchParams(paramas);
    setSubmitting(false);
  };
  const fetchUsers = async name => {
    try {
      const { results } = await UserResource.getAll({ name });
      return results.map(user => ({
        label: user.name,
        value: user.id,
      }));
    } catch {
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { results, count } = await ActivityLogResource.getAll({
          ...searchParams,
          page: page + 1,
          limit,
        });
        setTotal(count);
        setLogs(results);
      } catch {
        showError('An error occurs while loading the data table.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [limit, page, searchParams, setLogs, setTotal, showError]);

  return (
    <>
      <ExpansionPanel expanded={expanded} onChange={handleExpansion}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">Search By</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Formik
            onSubmit={handleSubmit}
            onReset={handleReset}
            initialValues={initialValues}
            validationSchema={validations}
            render={formProps => (
              <Form className={classes.form}>
                <Grid container spacing={2} justify="flex-end">
                  <Grid item sm={4} xs={12}>
                    <AutoCompleteSelectField
                      name="loggedUserId"
                      label="Logged user"
                      loadOptions={fetchUsers}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Date From"
                      type="date"
                      name="fromDate"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Date To"
                      type="date"
                      name="toDate"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      fullWidth
                      type="reset"
                      onClick={() => formProps.resetForm(initialValues)}
                    >
                      Reset
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      fullWidth
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={formProps.isSubmitting}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <DataTable
        search={false}
        loading={loading}
        title="Activity Log"
        className={classes.table}
        data={logs}
        renderColums={() => (
          <TableRow>
            <TableCell>Logged User</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>IP Address</TableCell>
          </TableRow>
        )}
        renderRows={item => (
          <TableRow key={item.id}>
            <TableCell>
              {item.loggedUserId ? item.loggedUser.name : '-'}
            </TableCell>
            <TableCell>{item.action}</TableCell>
            <TableCell>
              {moment(item.createdAt).format('MMM DD, YYYY hh:mm:ss a')}
            </TableCell>
            <TableCell>{item.ipAddress}</TableCell>
          </TableRow>
        )}
        renderPagination={() => (
          <TablePagination
            page={page}
            component="div"
            count={total}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
            onChangePage={(e, newPage) => setPage(newPage)}
            onChangeRowsPerPage={e => setLimit(e.target.value)}
          />
        )}
      />
    </>
  );
};

export default ActivityLogPage;
