import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Slide,
  Tooltip,
  Toolbar,
  TextField,
  Typography,
  IconButton,
} from '@material-ui/core';
import {
  Send as SendIcon,
  KeyboardArrowLeft as BackIcon,
} from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { ConversationTypes } from '../../../constants/messages';
import ButtonLink from '../../../components/Link/Button';
import { MessageResource } from '../../../services/http';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import { useMessage } from '../MessagesContext';
import Box from './Box/Box';

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  container: {
    flex: '1 1 0',
    display: 'flex',
    background: '#fff',
    flexDirection: 'column',
    [breakpoints.down('xs')]: {
      top: 0,
      left: 0,
      bottom: 0,
      width: '100%',
      position: 'absolute',
    },
  },
  list: {
    flex: '1 1 0',
    height: '100%',
    overflowY: 'auto',
    padding: spacing(2),
  },
  writeArea: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2),
    backgroundColor: grey[100],
  },
  textField: {
    backgroundColor: '#fff',
  },
  title: { flex: '0 0 auto' },
  spacer: { flex: '1 1 100%' },
  actions: { display: 'flex' },
  toolbar: {
    borderBottom: `1px solid ${palette.divider}`,
    minHeight: 55,
  },
}));

const MessagesArea = () => {
  const classes = useStyles();
  const { showError } = useSnackBar();
  const {
    type,
    isMobile,
    messages,
    addMessage,
    conversationSelected: conversation,
  } = useMessage();
  const [message, setMessage] = useState('');
  const [show, setShow] = useState(false);
  const msgBoxElement = useRef(null);
  const moveToLast = () => {
    // msgBoxElement.current.scrollTop = msgBoxElement.current.scrollHeight;
  };
  const handleSend = async () => {
    try {
      const newMessage = await MessageResource.save({
        conversationId: conversation.id,
        body: message,
      });

      addMessage(newMessage);
      setMessage('');
      moveToLast();
    } catch {
      showError('An error occurred. Please, try again.');
    }
  };

  useEffect(() => {
    setMessage('');
    moveToLast();
    setShow(!!conversation);
  }, [conversation]);

  return (
    <Slide
      mountOnEnter
      unmountOnExit
      direction="left"
      in={show}
      timeout={{ enter: 500, exit: 350 }}
    >
      <div className={classes.container}>
        {conversation && (
          <Toolbar
            variant="dense"
            disableGutters={isMobile}
            className={classes.toolbar}
          >
            {isMobile && (
              <Tooltip title="Back to list">
                <IconButton onClick={() => setShow(!show)}>
                  <BackIcon />
                </IconButton>
              </Tooltip>
            )}
            <div className={classes.title}>
              <Typography variant="subtitle1">{conversation.name}</Typography>
              <Typography variant="caption">{conversation.subject}</Typography>
            </div>
            <div className={classes.spacer} />
            {type === ConversationTypes.PATIENT &&
              conversation.forPatientAccount && (
                <div className={classes.title}>
                  <ButtonLink
                    variant="text"
                    color="primary"
                    to={`/patient-account/${conversation.forPatientAccount}`}
                  >
                    Patient Account Detail
                  </ButtonLink>
                </div>
              )}
          </Toolbar>
        )}
        <div className={classes.list} ref={msgBoxElement}>
          {messages.map(item => (
            <Box
              key={item.id}
              sender={item.sender}
              message={{
                schedule: item.schedule,
                content: item.body,
              }}
            />
          ))}
        </div>
        <div className={classes.writeArea}>
          <TextField
            rows={3}
            rowsMax={3}
            variant="outlined"
            placeholder="Type your message"
            value={message}
            className={classes.textField}
            onChange={e => setMessage(e.target.value)}
            fullWidth
            multiline
          />
          <IconButton onClick={handleSend} disabled={!message}>
            <SendIcon />
          </IconButton>
        </div>
      </div>
    </Slide>
  );
};

export default MessagesArea;
