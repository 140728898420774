import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
} from 'react';
import { withRouter } from 'react-router-dom';
import { func, shape } from 'prop-types';
import {
  BillingStatementResource,
  PatientAccountResource,
  PaymentResource,
  ReportResource,
  ExternalRecordsResource,
} from '../../../services/http';
import useModal from '../../../hooks/modal';
import useDataTable from '../../../hooks/data-table';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import AllPaymentsModal from './Payment/AllPaymentsModal/AllPaymentsModal';
import PaymentForm from './Payment/PaymentForm/PaymentForm';
import FileForm from './Common/FileForm';

export const PatientAccountDetailContext = createContext({
  reports: [],
  payments: [],
  formData: {},
  paAccount: null,
  paymentFormTitle: '',
  billingStatements: [],
  externalRecords: [],
  isPaymentFormOpen: false,
  isAllPaymentsModalOpen: false,
  hidePaymentForm: () => {},
  setPatientAccount: () => {},
  handleEditReport: () => {},
  handleEditPayment: () => {},
  handleCreateReport: () => {},
  handleDeleteReport: () => {},
  handleDownloadReport: () => {},
  handleCreatePayment: () => {},
  handleSubmitPayment: () => {},
  showAllPaymentsModal: () => {},
  hideAllPaymentsModal: () => {},
  handleEditBillingStatement: () => {},
  handleCreateBillingStatement: () => {},
  handleSubmitBillingStatement: () => {},
  handleDeleteBillingStatement: () => {},
  handleDownloadBillingStatement: () => {},
  handleEditExternalRecord: () => {},
  handleCreateExternalRecord: () => {},
  handleSubmitExternalRecord: () => {},
  handleDeleteExternalRecord: () => {},
  handleDownloadExternalRecord: () => {},
});

export const usePADetailContext = () => {
  return useContext(PatientAccountDetailContext);
};

const propTypes = {
  match: shape().isRequired,
  history: shape().isRequired,
  children: func.isRequired,
};

const RiskAssessmentProvider = ({
  children,
  history: { push },
  match: { params },
}) => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [paAccount, setPaAccount] = useState({});
  const [reports, setReports, delReport] = useDataTable();
  const [payments, setPayments, delPayment] = useDataTable();
  const [billingStatements, setBillingStatements, delBilling] = useDataTable();
  const [externalRecords, setExternalRecords, delRecords] = useDataTable();
  const { showError, showSuccess } = useSnackBar();
  const {
    showModal: showPaymentForm,
    hideModal: hidePaymentForm,
    isModalOpen: isPaymentFormOpen,
    modalTitle: paymentFormTitle,
  } = useModal();
  const {
    showModal: showAllPaymentsModal,
    hideModal: hideAllPaymentsModal,
    isModalOpen: isAllPaymentsModalOpen,
  } = useModal();
  const {
    showModal: showBillingStatementForm,
    hideModal: hideBillingStatementForm,
    isModalOpen: isBillingStatementFormOpen,
    modalTitle: billingStatementFormTitle,
  } = useModal();
  const {
    showModal: showReportForm,
    hideModal: hideReportForm,
    isModalOpen: isReportFormOpen,
    modalTitle: reportFormTitle,
  } = useModal();
  const {
    showModal: showExternalRecordsForm,
    hideModal: hideExternalRecordsForm,
    isModalOpen: isExternalRecordsFormOpen,
    modalTitle: externalRecordsFormTitle,
  } = useModal();

  // REPORT
  const handleCreateReport = () => {
    setFormData({});
    showReportForm('Add new report');
  };
  const handleEditReport = report => {
    setFormData(report);
    showReportForm(`Edit report: ${report.title}`);
  };
  const handleDeleteReport = async ({ id }) => {
    try {
      await ReportResource.remove(id);

      delReport(id);
      showSuccess('The operation was completed successfully.');
    } catch {
      showError('An error occurred, please try again.');
    }
  };
  const handleSubmitReport = async ({ type, ...values }, { setSubmitting }) => {
    try {
      const data = await ReportResource.saveMultipart({
        ...values,
        ...(formData.id && { id: formData.id }),
        patientAccountId: paAccount.id,
      });

      setReports(data);
      hideReportForm();
      showSuccess('The operation was completed successfully.');
    } catch {
      showError('An error occurred, please try again.');
    } finally {
      setSubmitting(false);
    }
  };
  const handleDownloadReport = ({ id }) =>
    window.open(ReportResource.download(id), '__blank', 'noopener');

  // BILLING STATEMENT
  const handleCreateBillingStatement = () => {
    setFormData({});
    showBillingStatementForm('Add new billing statement');
  };
  const handleEditBillingStatement = billing => {
    setFormData(billing);
    showBillingStatementForm(`Edit billing statement: ${billing.title}`);
  };
  const handleDeleteBillingStatement = async ({ id }) => {
    try {
      await BillingStatementResource.remove(id);

      delBilling(id);
      showSuccess('The operation was completed successfully.');
    } catch {
      showError('An error occurred, please try again.');
    }
  };
  const handleDownloadBillingStatement = ({ id }) =>
    window.open(BillingStatementResource.download(id), '__blank', 'noopener');

  const handleSubmitBillingStatement = async (
    { type, ...values },
    { setSubmitting },
  ) => {
    try {
      const data = await BillingStatementResource.saveMultipart({
        ...values,
        ...(formData.id && { id: formData.id }),
        patientAccountId: paAccount.id,
      });

      setBillingStatements(data);
      hideBillingStatementForm();
      showSuccess('The operation was completed successfully.');
    } catch {
      showError('An error occurred, please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  // PAYMENT
  const handleCreatePayment = () => {
    setFormData({});
    showPaymentForm('Add new payment');
  };
  const handleEditPayment = payment => {
    setFormData(payment);
    showPaymentForm(`Edit payment from: ${payment.from}`);
  };
  const handleSubmitPayment = async (values, { setSubmitting }) => {
    try {
      const data = await PaymentResource.save({
        ...values,
        ...(formData.id && { id: formData.id }),
        patientAccountId: paAccount.id,
      });

      setPayments(data);
      hidePaymentForm();
      showSuccess('The operation was completed successfully.');
    } catch {
      showError('An error occurred, please try again.');
    } finally {
      setSubmitting(false);
    }
  };
  const handleDeletePayment = async ({ id }) => {
    try {
      await PaymentResource.remove(id);

      delPayment(id);
      showSuccess('The operation was completed successfully.');
    } catch {
      showError('An error occurred, please try again.');
    }
  };

  // EXTERNAL RECORDS
  const handleCreateExternalRecord = () => {
    setFormData({});
    showExternalRecordsForm('Add new external record');
  };
  const handleEditExternalRecord = externalRecord => {
    setFormData(externalRecord);
    showExternalRecordsForm(`Edit external record: ${externalRecord.title}`);
  };
  const handleDeleteExternalRecord = async ({ id }) => {
    try {
      await ExternalRecordsResource.remove(id);

      delRecords(id);
      showSuccess('The operation was completed successfully.');
    } catch {
      showError('An error occurred, please try again.');
    }
  };
  const handleDownloadExternalRecord = ({ id }) =>
    window.open(ExternalRecordsResource.download(id), '__blank', 'noopener');

  const handleSubmitExternalRecord = async (
    { type, ...values },
    { setSubmitting },
  ) => {
    try {
      const data = await ExternalRecordsResource.saveMultipart({
        ...values,
        ...(formData.id && { id: formData.id }),
        patientAccountId: paAccount.id,
      });

      setExternalRecords(data);
      hideExternalRecordsForm();
      showSuccess('The operation was completed successfully.');
    } catch {
      showError('An error occurred, please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  const setPatientAccount = useCallback(
    paAccountEdited => {
      const {
        reports: paAccountReports,
        payments: paAccountPayments,
        billingStatements: paBillingStatements,
        externalRecords: paExternalRecords,
        ...rest
      } = paAccountEdited;

      setPaAccount(rest);
      setReports(paAccountReports.reverse());
      setPayments(paAccountPayments.reverse());
      setBillingStatements(paBillingStatements.reverse());
      setExternalRecords(paExternalRecords.reverse());
    },
    [setBillingStatements, setPayments, setReports, setExternalRecords],
  );

  useEffect(() => {
    const total = payments.reduce((acc, curr) => acc + Number(curr.amount), 0);
    setPaAccount(prev => ({
      ...prev,
      totalPayment: total,
      currentBalance: prev.totalBilled - total,
    }));
  }, [payments]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = Number(params.id);

        if (Number.isNaN(id)) {
          throw new Error();
        }

        const data = await PatientAccountResource.get(id);
        setPatientAccount(data);
      } catch {
        push('/patient-account');
        showError('An error occurs, please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [params, push, showError, setPatientAccount]);

  const context = {
    reports,
    payments,
    paAccount,
    formData,
    billingStatements,
    externalRecords,
    paymentFormTitle,
    isPaymentFormOpen,
    hidePaymentForm,
    setPatientAccount,
    handleEditPayment,
    handleCreatePayment,
    handleSubmitPayment,
    handleDeletePayment,
    showAllPaymentsModal,
    hideAllPaymentsModal,
    isAllPaymentsModalOpen,
    handleEditBillingStatement,
    handleCreateBillingStatement,
    handleDeleteBillingStatement,
    handleSubmitBillingStatement,
    handleDownloadBillingStatement,
    handleDownloadReport,
    handleCreateReport,
    handleDeleteReport,
    handleEditReport,
    handleEditExternalRecord,
    handleCreateExternalRecord,
    handleDeleteExternalRecord,
    handleSubmitExternalRecord,
    handleDownloadExternalRecord,
  };

  return (
    <PatientAccountDetailContext.Provider value={context}>
      {children(loading)}
      <PaymentForm />
      <AllPaymentsModal />
      <FileForm
        data={formData}
        title={billingStatementFormTitle}
        open={isBillingStatementFormOpen}
        onClose={hideBillingStatementForm}
        onSubmit={handleSubmitBillingStatement}
        onDownload={handleDownloadBillingStatement}
        type="Billing Statement"
      />
      <FileForm
        data={formData}
        title={reportFormTitle}
        open={isReportFormOpen}
        onClose={hideReportForm}
        onSubmit={handleSubmitReport}
        onDownload={handleDownloadReport}
        type="Report"
      />
      <FileForm
        data={formData}
        title={externalRecordsFormTitle}
        open={isExternalRecordsFormOpen}
        onClose={hideExternalRecordsForm}
        onSubmit={handleSubmitExternalRecord}
        onDownload={handleDownloadExternalRecord}
        type="External Record"
      />
    </PatientAccountDetailContext.Provider>
  );
};

RiskAssessmentProvider.propTypes = propTypes;

export default withRouter(RiskAssessmentProvider);
