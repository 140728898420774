import { useState } from 'react';

export default () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const showModal = (title = '') => {
    setModalTitle(title);
    setModalOpen(true);
  };
  const hideModal = () => setModalOpen(false);

  return {
    isModalOpen,
    showModal,
    hideModal,
    modalTitle,
  };
};
