import React, { useState } from 'react';
import { bool, number } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Switch, FormControlLabel } from '@material-ui/core';
import ModalDialog from '../../../components/ModalDialog/ModalDialog';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import { CustomerResource } from '../../../services/http';

const propTypes = {
  id: number.isRequired,
  enable: bool.isRequired,
};

const useControlLabelStyles = makeStyles(({ typography }) => ({
  label: typography.caption,
}));

const StatusSwitch = ({ enable, id }) => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(enable);
  const { showSuccess, showError } = useSnackBar();
  const controlLabelClasses = useControlLabelStyles();

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleAccept = async () => {
    try {
      await CustomerResource.save({ id, isActive: !checked });
      setChecked(!checked);
      showSuccess(
        `Successfully ${!checked ? 'enabled' : 'disabled'} the customer`,
      );
    } catch ({ message }) {
      showError(message || 'An error occurs, please try again.');
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <FormControlLabel
        size="small"
        checked={checked}
        control={<Switch />}
        onChange={handleOpen}
        classes={controlLabelClasses}
        label={checked ? 'Enable' : 'Disable'}
      />
      <ModalDialog
        fullWidth
        open={open}
        maxWidth="xs"
        submitLabel="Yes"
        onClose={handleClose}
        onConfirm={handleAccept}
        title="Change status for customer?"
      />
    </>
  );
};

StatusSwitch.propTypes = propTypes;

export default StatusSwitch;
