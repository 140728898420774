import React, { useState } from 'react';
import { string, func } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Typography, IconButton, Tooltip } from '@material-ui/core';
import { LocationOn, Phone, Email, Delete, Create } from '@material-ui/icons';
import AvatarDefault from '../../../assets/images/default-provider-icon.png';
import { Can } from '../../../contexts/AuthContext';

const useStyles = makeStyles(({ spacing, typography }) => ({
  bioContainer: {
    width: '100%',
    display: 'flex',
    padding: spacing(2),
    alignItems: 'center',
  },
  icon: {
    top: spacing(0.5),
    position: 'relative',
    width: typography.body1.fontSize,
    height: typography.body1.fontSize,
  },
  data: { paddingLeft: spacing(2) },
  avatarImg: { width: 80, height: 80 },
  actions: { marginLeft: 'auto' },
}));

const propTypes = {
  firstName: string.isRequired,
  lastName: string.isRequired,
  email: string.isRequired,
  phone: string.isRequired,
  fax: string.isRequired,
  location: string.isRequired,
  picture: string.isRequired,
  onDelete: func.isRequired,
  onEdit: func.isRequired,
};

const CompanyDirectoryBio = ({
  firstName,
  lastName,
  email,
  phone,
  fax,
  location,
  picture,
  onDelete,
  onEdit,
}) => {
  const classes = useStyles();
  const [avatar, setAvatar] = useState(picture);

  const handleError = () => {
    setAvatar(AvatarDefault);
  };

  return (
    <div className={classes.bioContainer}>
      <Avatar
        src={avatar}
        alt={`${firstName} ${lastName}`}
        className={classes.avatarImg}
        imgProps={{ onError: handleError }}
      />
      <div className={classes.data}>
        <Typography gutterBottom variant="body1">
          <strong>{`${lastName} ${firstName}`}</strong>
        </Typography>
        <Typography variant="body2">
          <LocationOn color="primary" className={classes.icon} /> {location}
        </Typography>
        <Typography variant="body2">
          <Email color="primary" className={classes.icon} /> {email}
        </Typography>
        <Typography variant="body2">
          <Phone color="primary" className={classes.icon} />{' '}
          {`Phone: ${phone} - Fax: ${fax}`}
        </Typography>
      </div>
      <div className={classes.actions}>
        <Can I="update" a="CompanyDirectory">
          <IconButton size="small" color="primary" onClick={onEdit}>
            <Tooltip title="Edit">
              <Create />
            </Tooltip>
          </IconButton>
        </Can>
        <Can I="delete" a="CompanyDirectory">
          <IconButton size="small" color="primary" onClick={onDelete}>
            <Tooltip title="Delete">
              <Delete />
            </Tooltip>
          </IconButton>
        </Can>
      </div>
    </div>
  );
};

CompanyDirectoryBio.propTypes = propTypes;

export default CompanyDirectoryBio;
