import { object, string } from 'yup';

export default object().shape({
  username: string()
    .email()
    .required()
    .label('Email'),
  password: string()
    .required()
    .label('Password'),
});
