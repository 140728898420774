import React from 'react';
import { oneOf } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import ButtonLink from '../../../components/Link/Button';

const useStyles = makeStyles(theme => ({
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    width: 1,
    height: 12,
    backgroundColor: '#000',
    margin: '0 2px',
    display: 'inline-block',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  center: {
    alignItems: 'center',
    textAlign: 'center',
  },
  left: {
    alignItems: 'flex-start',
    textAlign: 'left',
  },
  right: {
    alignItems: 'flex-end',
    textAlign: 'right',
  },
}));

const propTypes = {
  align: oneOf(['center', 'left', 'right']).isRequired,
};

const Footer = ({ align }) => {
  const classes = useStyles();
  let footerClasses = classes.footer;

  switch (align) {
    case 'left':
      footerClasses += ` ${classes.left}`;
      break;
    case 'right':
      footerClasses += ` ${classes.right}`;
      break;
    case 'center':
    default:
      footerClasses += ` ${classes.center}`;
  }

  return (
    <footer className={footerClasses}>
      <div className={classes.btnContainer}>
        <Button size="small" href="https://www.abcclinics.com/" target="_blank">
          WebSite
        </Button>
        <span className={classes.divider} />
        <Button
          size="small"
          href="https://www.abcclinics.com/contact-us/"
          target="_blank"
        >
          Faq
        </Button>
        <span className={classes.divider} />
        <ButtonLink size="small" to="/company-directory">
          Company Directory
        </ButtonLink>
      </div>
      <Typography variant="caption">
        Accessible Beltway Clinics Headquarters. 30 West Guide Dr., #375.
        Rockville, MD 20850 Copyright &copy;{new Date().getFullYear()} - All
        Rights Reserved.
      </Typography>
    </footer>
  );
};

Footer.propTypes = propTypes;

export default Footer;
