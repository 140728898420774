import React, { useState, useEffect } from 'react';
import {
  Table,
  Hidden,
  Tooltip,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  IconButton,
  TableFooter,
} from '@material-ui/core';
import {
  Create as EditIcon,
  AddCircleOutline as AddIcon,
} from '@material-ui/icons';
import useModal from '../../../hooks/modal';
import useDataTable from '../../../hooks/data-table';
import Card from '../../../components/Card/Card';
import UserForm from '../../User/UserForm/UserForm';
import { UserResource } from '../../../services/http';
import ButtonLink from '../../../components/Link/Button';
import StatusIcon from '../../../components/StatusIcon/StatusIcon';
import { Can, usePermissions } from '../../../contexts/AuthContext';
import { ROLE_LABEL } from '../../../constants/roles';

const UsersTable = () => {
  const permissions = usePermissions();
  const [users, setUsers] = useDataTable([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const { isModalOpen, modalTitle, showModal, hideModal } = useModal();
  const handleCreate = () => {
    setFormData();
    showModal('Add new user');
  };
  const handleEdit = user => {
    setFormData(user);
    showModal(`Edit user ${user.name}`);
  };
  const handleClose = (e, eventType, user) => {
    if (eventType === 'submitClick') {
      setUsers(user);
    }

    hideModal();
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { results } = await UserResource.getAll({ limit: 5 });
        setUsers(results);
      } catch {
        setUsers([]);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [setUsers]);

  return (
    <Card
      noPadding
      title="Users"
      loading={loading}
      {...(permissions.can('create', 'User') && {
        actionLabel: 'Add new user',
        actionIcon: <AddIcon />,
        onAction: handleCreate,
      })}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <Hidden xsDown>
              <TableCell>Email</TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell>Role</TableCell>
            </Hidden>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            (users.length > 0 ? (
              users.map(user => (
                <TableRow key={user.id}>
                  <TableCell>{user.name}</TableCell>
                  <Hidden xsDown>
                    <TableCell>{user.email}</TableCell>
                  </Hidden>
                  <Hidden smDown>
                    <TableCell>{ROLE_LABEL[user.role]}</TableCell>
                  </Hidden>
                  <TableCell>
                    <StatusIcon
                      label={user.isActive ? 'Active' : 'Disabled'}
                      color={user.isActive ? 'green' : 'red'}
                    />
                  </TableCell>
                  <TableCell>
                    <Can I="update" a="User">
                      <Tooltip title="Edit">
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => handleEdit(user)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Can>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>No users</TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={5} align="center">
              <ButtonLink to="/users" variant="text" color="primary">
                View all users
              </ButtonLink>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <UserForm
        title={modalTitle}
        open={isModalOpen}
        onClose={handleClose}
        data={formData}
      />
    </Card>
  );
};

export default UsersTable;
