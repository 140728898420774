import React from 'react';
import { shape } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tabs as MuiTabs, Tab } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';
import { useMessage } from '../MessagesContext';

const propTypes = {
  tabProps: shape({}),
};

const defaultProps = {
  tabProps: {},
};

const useTabsStyles = makeStyles(() => ({
  indicator: { display: 'none' },
}));

const useTabStyles = makeStyles(({ breakpoints, palette, shadows }) => ({
  root: {
    transition: '0.2s',
    backgroundColor: grey[300],
    [breakpoints.up('md')]: { minWidth: 120 },
    '&:before': { transition: '0.2s' },
    '&:hover': {
      '&:not($selected)': { backgroundColor: palette.background.paper },
    },
  },
  wrapper: { flexDirection: 'row-reverse' },
  labelIcon: {
    paddingTop: 6,
    minHeight: 48,
  },
  selected: {
    boxShadow: shadows[1],
    backgroundColor: palette.background.paper,
  },
}));

const useClassStyles = makeStyles(({ typography }) => ({
  counterIcon: {
    margin: 0,
    width: 21,
    height: 21,
    color: '#fff',
    marginLeft: 3,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: red.A400,
    fontSize: typography.pxToRem(11),
  },
}));

const Tabs = ({ tabProps, ...props }) => {
  const tabsClasses = useTabsStyles();
  const tabClasses = useTabStyles();
  const classes = useClassStyles();

  const { tab, setTab, unreads } = useMessage();

  const tabs = [
    { label: 'General', notRead: unreads[0] },
    { label: 'About Patients', notRead: unreads[1] },
  ];

  return (
    <MuiTabs
      value={tab}
      classes={tabsClasses}
      onChange={(e, i) => setTab(i)}
      {...props}
    >
      {tabs.map((iTab, index) => {
        const { notRead, ...restTabProps } = iTab;

        return (
          <Tab
            icon={
              iTab.notRead && iTab.notRead > 0 ? (
                <span className={classes.counterIcon}>{iTab.notRead}</span>
              ) : null
            }
            key={index.toString()}
            {...tabProps}
            {...restTabProps}
            classes={tabClasses}
          />
        );
      })}
    </MuiTabs>
  );
};

Tabs.propTypes = propTypes;
Tabs.defaultProps = defaultProps;

export default Tabs;
