import React, { useState } from 'react';
import { string } from 'prop-types';
import { ChromePicker } from 'react-color';
import { Field, ErrorMessage } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { Colorize as ColorizeIcon } from '@material-ui/icons';
import {
  InputAdornment,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core';

const useStyles = makeStyles(({ zIndex }) => ({
  picker: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  popOverlay: {
    zIndex: zIndex.tooltip + 1,
    position: 'absolute',
    cursor: 'auto',
  },
  cover: {
    position: 'fixed',
    cursor: 'auto',
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
  },
}));

const propTypes = {
  name: string.isRequired,
};

const ColorField = ({ name, ...props }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen(!open);

  return (
    <Field
      name={name}
      render={({ field, form: { touched, errors, setFieldValue } }) => {
        const handleChangeColor = ({ hex }) => setFieldValue(field.name, hex);

        return (
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Choose a Color">
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={handleToggle}
                    >
                      <ColorizeIcon />
                    </IconButton>
                  </Tooltip>
                  {open ? (
                    <div className={classes.popOverlay}>
                      <div
                        role="presentation"
                        onClick={handleToggle}
                        className={classes.cover}
                      />
                      <div className={classes.picker}>
                        <ChromePicker
                          disableAlpha
                          color={field.value}
                          onChange={handleChangeColor}
                        />
                      </div>
                    </div>
                  ) : null}
                </InputAdornment>
              ),
            }}
            error={Boolean(touched[field.name] && errors[field.name])}
            helperText={<ErrorMessage name={name} />}
            {...field}
            {...props}
          />
        );
      }}
    />
  );
};

ColorField.propTypes = propTypes;

export default ColorField;
