import React from 'react';
import { Grid } from '@material-ui/core';
import Messages from './Messages/Messages';
import UsersTable from './UsersTable/UsersTable';
import PatientAccount from './PatientAccount/PatientAccount';
import { Can } from '../../contexts/AuthContext';

const DashBoardPage = () => (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={6} md={8}>
      <PatientAccount />
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <Messages />
    </Grid>
    <Can I="read" a="User">
      <Grid item xs={12}>
        <UsersTable />
      </Grid>
    </Can>
  </Grid>
);

export default DashBoardPage;
