import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { node } from 'prop-types';
import Footer from '../Footer/Footer';
import Logo from '../../../assets/images/Logo-login.png';
import LogoX2 from '../../../assets/images/Logo-login@2x.png';
import Background from '../../../assets/images/abc-background-img.png';

const useStyles = makeStyles(theme => ({
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fff',
  },
  header: {
    padding: 20,
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footer: {
    flex: '0 0 auto',
  },
  content: {
    flex: '1 1 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3),
    },
  },
  slogan: {
    color: '#fff',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const propTypes = {
  children: node.isRequired,
};

const LoggedOutLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <picture>
          <source media="(max-width: 768px)" srcSet={Logo} />
          <img src={LogoX2} alt="Abc logo" />
        </picture>
        <Typography variant="h5" className={classes.slogan}>
          Attorney Portal
        </Typography>
      </div>

      <div className={classes.content}>{children}</div>

      <div className={classes.footer}>
        <Footer align="center" />
      </div>
    </div>
  );
};

LoggedOutLayout.propTypes = propTypes;

export default LoggedOutLayout;
