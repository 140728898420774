import { object, string, number, mixed } from 'yup';

const validateHexDecimal = hex =>
  /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(hex);

export default object().shape({
  name: string()
    .required()
    .test('name', value => /^[a-zA-Z\s]*$/.test(value))
    .label('Customer name'),
  fax: string()
    .test('fax', value => (value ? /^\+?[0-9]{6,16}$/.test(value) : true))
    .label('Fax'),
  maxUsersAllowed: number()
    .required()
    .label('Max users allowed'),
  primary: string()
    .test('Incorrect Format', value => validateHexDecimal(value))
    .required()
    .label('Primary color'),
  secondary: string()
    .test('Incorrect Format', value => validateHexDecimal(value))
    .required()
    .label('Secondary color'),
  logo: mixed()
    .required()
    .label('Logo'),
});
