import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Link as MuiLink } from '@material-ui/core';
import { useAuth } from '../../contexts/AuthContext';
import LoginForm from './LoginForm/LoginForm';
import ResetPasswordForm from './ResetPasswordForm/ResetPasswordForm';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(5),
    padding: theme.spacing(4),
    maxWidth: 500,
  },
}));

const AccountPage = () => {
  const { isLoggedIn } = useAuth();
  const classes = useStyles();

  if (isLoggedIn()) {
    return <Redirect to="/" />;
  }

  return (
    <Grid container direction="column" alignContent="center">
      <Grid item md={4}>
        <Paper className={classes.paper}>
          <Switch>
            <Route path="/account/login" component={LoginForm} exact />
            <Route
              path="/account/forgot-password"
              component={ForgotPasswordForm}
              exact
            />
            <Route
              path="/account/reset-password/:uid/:token"
              component={ResetPasswordForm}
              exact
            />
            <Redirect to="/account/login" />
          </Switch>
        </Paper>
        <Typography align="center">
          Don&apos;t have account?{' '}
          <MuiLink
            href="https://www.abcclinics.com/contact-us/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Sign up Here
          </MuiLink>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AccountPage;
