import React, { useState } from 'react';
import clsx from 'clsx';
import { string, bool, number } from 'prop-types';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Typography } from '@material-ui/core';
import { LocationOn, CameraAlt } from '@material-ui/icons';
import { ProvidersResource } from '../../../services/http';
import AvatarDefault from '../../../assets/images/default-provider-icon.png';

const useStyles = makeStyles(({ spacing, typography }) => ({
  bioContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2),
  },
  avatarImg: {
    width: 80,
    height: 80,
  },
  icon: {
    position: 'relative',
    top: spacing(0.5),
    width: typography.body1.fontSize,
    height: typography.body1.fontSize,
  },
  useBg: { backgroundColor: grey[100] },
  providerData: { paddingLeft: spacing(2) },
}));

const propTypes = {
  useBg: bool,
  city: string,
  state: string,
  address: string,
  specialty: string,
  title: string.isRequired,
  id: number.isRequired,
};

const defaultProps = {
  city: null,
  state: null,
  useBg: false,
  address: null,
  specialty: null,
};

const ProviderBio = ({ id, title, specialty, address, city, state, useBg }) => {
  const classes = useStyles();
  const [avatar, setAvatar] = useState(ProvidersResource.getImage(id));

  const handleError = () => {
    setAvatar(AvatarDefault);
  };

  return (
    <div className={clsx(classes.bioContainer, { [classes.useBg]: useBg })}>
      <Avatar
        src={avatar}
        alt={title}
        className={classes.avatarImg}
        imgProps={{ onError: handleError }}
      />
      <div className={classes.providerData}>
        <Typography gutterBottom variant="body1">
          <strong>{title}</strong>
        </Typography>
        <Typography variant="body2">
          <CameraAlt color="primary" className={classes.icon} />
          {specialty}
        </Typography>
        <Typography variant="body2">
          <LocationOn color="primary" className={classes.icon} />
          {`${address}, ${city}, ${state}`}
        </Typography>
      </div>
    </div>
  );
};

ProviderBio.propTypes = propTypes;
ProviderBio.defaultProps = defaultProps;

export default ProviderBio;
