import React, { useState, useEffect } from 'react';
import {
  TablePagination,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import { LocationResource } from '../../services/http';
import { useSnackBar } from '../../contexts/SnackBarContext';
import { Can } from '../../contexts/AuthContext';
import DataTable from '../../components/DataTable/DataTable';
import Fab from '../../components/Fab/Fab';
import useModal from '../../hooks/modal';
import useDataTable from '../../hooks/data-table';
import usePagination from '../../hooks/pagination-table';
import LocationsForm from './LocationsForm/LocationsForm';
import ModalDialog from '../../components/ModalDialog/ModalDialog';

const LocationsPage = () => {
  const [formData, setFormData] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState();
  const [locations, setLocations, delLocations] = useDataTable();
  const { total, setTotal, limit, setLimit, page, setPage } = usePagination();
  const { isModalOpen, modalTitle, showModal, hideModal } = useModal();
  const { showError, showSuccess } = useSnackBar();

  const handleCreate = () => {
    setFormData();
    showModal('Add new location');
  };

  const handleEdit = location => {
    setFormData(location);
    showModal(`Edit location ${location.name}`);
  };

  const handleOpenDialog = item => {
    setFormData(item);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setFormData({});
    setOpen(false);
  };

  const handleConfirmDialog = async () => {
    const { id } = formData;

    try {
      await LocationResource.remove(id);
      delLocations(id);
      showSuccess('Successfully deleted location');
    } catch {
      showError('There was an error deleting the location');
    } finally {
      handleCloseDialog();
    }
  };

  const handleClose = (e, eventType, location) => {
    if (eventType === 'submitClick') {
      setLocations(location);
    }

    hideModal();
  };

  const handleSearch = e => {
    setSearchParam(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { results, count } = await LocationResource.getAll({
          name: searchParam,
          page: page + 1,
          limit,
        });

        setTotal(count);
        setLocations(results);
      } catch {
        showError('An error occurs while loading the data table.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setLocations, showError, searchParam, setLoading, page, limit, setTotal]);

  return (
    <>
      <DataTable
        title="Locations"
        searchLabel="Search by name"
        loading={loading}
        data={locations}
        onSearch={handleSearch}
        renderColums={() => (
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell />
          </TableRow>
        )}
        renderRows={item => (
          <TableRow key={item.id}>
            <TableCell>{item.name}</TableCell>
            <TableCell align="right">
              <Can I="update" a="Location">
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => handleEdit(item)}
                >
                  <Tooltip title="Edit">
                    <EditIcon />
                  </Tooltip>
                </IconButton>
              </Can>
              <Can I="delete" a="Location">
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => handleOpenDialog(item)}
                >
                  <Tooltip title="Delete">
                    <DeleteIcon />
                  </Tooltip>
                </IconButton>
              </Can>
            </TableCell>
          </TableRow>
        )}
        renderPagination={() => (
          <TablePagination
            page={page}
            component="div"
            count={total}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
            onChangePage={(e, newPage) => setPage(newPage)}
            onChangeRowsPerPage={e => setLimit(e.target.value)}
          />
        )}
      />
      <Can I="create" a="Location">
        <Fab color="secondary" onClick={handleCreate} />
      </Can>
      <LocationsForm
        title={modalTitle}
        open={isModalOpen}
        onClose={handleClose}
        data={formData}
      />
      <ModalDialog
        fullWidth
        open={open}
        maxWidth="xs"
        submitLabel="Yes, I’m sure"
        title="Confirm Delete"
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
        subtitle="Are you sure you want to delete this location?"
      />
    </>
  );
};

export default LocationsPage;
