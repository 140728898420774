import React from 'react';
import moment from 'moment';
import { string, bool, func, shape } from 'prop-types';
import { Grid, InputAdornment, Typography } from '@material-ui/core';
import TextField from '../../../components/TextField/TextField';
import ModalForm from '../../../components/ModalForm/ModalForm';
import SelectField from '../../../components/SelectField/SelectField';
import AutoCompleteSelectField from '../../../components/AutoCompleteSelectField/AutoCompleteSelectField';
import { statusList, STATUS_NEW } from '../../../constants/patientAccounts';
import { Can, usePermissions } from '../../../contexts/AuthContext';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import { ATTORNEY } from '../../../constants/roles';
import validations from './validations';
import {
  UserResource,
  FirmResource,
  ClinicResource,
  CustomerResource,
  PatientAccountResource,
} from '../../../services/http';

const propTypes = {
  title: string.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  data: shape({}),
};

const defaultProps = {
  data: {},
};

const PatientAccountForm = ({ title, open, onClose, data }) => {
  const permissions = usePermissions();
  const { showSuccess, showError } = useSnackBar();
  const initialValues = {
    status: data.status || STATUS_NEW,
    totalBilled:
      data.totalBilled || data.totalBilled === 0 ? data.totalBilled : '',
    accountNumber: data.accountNumber || '',
    lastName: data.patient ? data.patient.lastName : '',
    firstName: data.patient ? data.patient.firstName : '',
    dateOfAccident: data.dateOfAccident
      ? moment.utc(data.dateOfAccident).format('YYYY-MM-DD')
      : '',
    customerId: data.customer
      ? { label: data.customer.name, value: data.customer.id }
      : '',
    clinicId: data.clinic
      ? { label: data.clinic.name, value: data.clinic.id }
      : '',
    attorneys: data.attorneys
      ? data.attorneys.map(a => ({ label: a.name, value: a.id }))
      : [],
    firmId: data.firm ? { label: data.firm.name, value: data.firm.id } : '',
    haveCustomer: permissions.can('create', 'PatientAccount', 'customerId'),
  };
  const handleSubmit = async (
    {
      patientFields,
      haveCustomer,
      customerId,
      firstName,
      patientId,
      attorneys,
      lastName,
      clinicId,
      firmId,
      ...values
    },
    { setSubmitting },
  ) => {
    try {
      const patientAccount = await PatientAccountResource.save({
        ...values,
        ...(data.id && { id: data.id }),
        ...(customerId && { customerId: customerId.value }),
        attorneys: attorneys.map(a => ({ id: a.value })),
        clinicId: clinicId.value,
        firmId: firmId.value,
        newPatient: {
          lastName,
          firstName,
          ...(data.patient && { id: data.patient.id }),
        },
      });

      onClose(null, 'submitClick', patientAccount);
      showSuccess(`Successfully ${data.id ? 'updated' : 'added'} Account`);
    } catch ({ message }) {
      showError(message || 'An error occurs, please try again.');
    } finally {
      setSubmitting(false);
    }
  };
  const fetchCustomerOptions = async name => {
    try {
      const { results } = await CustomerResource.getAll({ name, limit: 5 });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };
  const fetchClinicOptions = async (name, customerId) => {
    try {
      const { results } = await ClinicResource.getAll({
        name,
        customerId,
        limit: 5,
      });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };
  const fetchFirmOptions = async (name, customerId) => {
    try {
      const { results } = await FirmResource.getAll({
        name,
        customerId,
        limit: 5,
      });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };
  const fetchAttorneysOptions = async (name, customerId) => {
    try {
      const { results } = await UserResource.getAll({
        name,
        customerId,
        isActive: true,
        role: ATTORNEY,
        limit: 5,
      });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };

  return (
    <ModalForm
      fullWidth
      open={open}
      maxWidth="sm"
      title={title}
      onClose={onClose}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validations}
      submitLabel={data.id ? 'Save' : 'Create'}
      render={({ setFieldValue, values: { customerId, haveCustomer } }) => (
        <Grid container spacing={2}>
          <Can I="create customerId" a="PatientAccount">
            <Grid item xs={12}>
              <AutoCompleteSelectField
                name="customerId"
                label="Customer"
                loadOptions={fetchCustomerOptions}
                onChange={() => {
                  setFieldValue('firmId', '');
                  setFieldValue('clinicId', '');
                  setFieldValue('attorneys', []);
                }}
              />
            </Grid>
          </Can>
          <Grid item xs={12}>
            <Typography variant="h6">Patient Information</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="firstName"
              type="text"
              label="First Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="lastName"
              type="text"
              label="Last Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Patient Account Information</Typography>
          </Grid>
          <Grid item xs={12}>
            <AutoCompleteSelectField
              multi
              name="attorneys"
              label="Attorneys"
              disabled={!customerId && haveCustomer}
              loadOptions={n => fetchAttorneysOptions(n, customerId.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoCompleteSelectField
              name="clinicId"
              label="Clinic"
              disabled={!customerId && haveCustomer}
              loadOptions={n => fetchClinicOptions(n, customerId.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoCompleteSelectField
              name="firmId"
              label="Firm"
              disabled={!customerId && haveCustomer}
              loadOptions={n => fetchFirmOptions(n, customerId.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="text"
              variant="outlined"
              name="accountNumber"
              label="Account number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">#</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Date of accident"
              name="dateOfAccident"
              variant="outlined"
              type="date"
              InputLabelProps={{ shrink: true }}
              inputProps={{ max: '2099-12-30' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField
              fullWidth
              name="status"
              label="Status"
              variant="outlined"
              options={statusList}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Total billed"
              name="totalBilled"
              variant="outlined"
              type="number"
              InputProps={{
                inputProps: { min: 1, step: '0.01' },
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

PatientAccountForm.propTypes = propTypes;
PatientAccountForm.defaultProps = defaultProps;

export default PatientAccountForm;
