import React, { useState, useEffect } from 'react';
import { shape } from 'prop-types';
import moment from 'moment';
import {
  Grid,
  Button,
  Hidden,
  TableRow,
  TableCell,
  Typography,
  TablePagination,
  Link as MuiLink,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  InputAdornment,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { Can, useAuth } from '../../../contexts/AuthContext';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import {
  PatientAccountResource,
  ClinicResource,
  LocationResource,
} from '../../../services/http';
import PatientAccountForm from '../PatientAccountForm/PatientAccountForm';
import ModalDialog from '../../../components/ModalDialog/ModalDialog';
import AutoCompleteSelectField from '../../../components/AutoCompleteSelectField/AutoCompleteSelectField';
import DataTable from '../../../components/DataTable/DataTable';
import TextField from '../../../components/TextField/TextField';
import Link from '../../../components/Link/Link';
import Fab from '../../../components/Fab/Fab';
import useModal from '../../../hooks/modal';
import useDataTable from '../../../hooks/data-table';
import usePagination from '../../../hooks/pagination-table';
import validations from './validations';

const propTypes = {
  location: shape({}).isRequired,
};

const useStyles = makeStyles(() => ({
  form: { width: '100%' },
}));

const PatientAccountList = ({ location }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [searchParams, setSearchParams] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [patients, setPatients, delPatient] = useDataTable();
  const { isModalOpen, modalTitle, showModal, hideModal } = useModal();
  const { total, setTotal, limit, setLimit, page, setPage } = usePagination();
  const { showError, showSuccess } = useSnackBar();
  const { isAttorney, isParalegal } = useAuth();
  const initialValues = {
    name: '',
    location: '',
    clinic: '',
    accountNumber: '',
  };

  const handleExpansion = () => setExpanded(!expanded);

  const handleReset = () => setSearchParams({});

  const handleSubmit = (values, { setSubmitting }) => {
    const params = {
      ...(values.name && { name: values.name }),
      ...(values.clinic && { clinic: values.clinic }),
      ...(values.location && { location: values.location.value }),
      ...(values.accountNumber && {
        accountNumber: values.accountNumber,
      }),
    };

    setSearchParams(params);
    setSubmitting(false);
  };

  const fetchClinicsOptions = async name => {
    try {
      const { results } = await ClinicResource.getAll({ name });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };
  const fetchLocationsOptions = async name => {
    try {
      const { results } = await LocationResource.getAll({ name });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };

  const handleOpenDialog = item => {
    setFormData(item);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setFormData({});
    setOpenDialog(false);
  };
  const handleConfirmDialog = async () => {
    const { id } = formData;
    try {
      await PatientAccountResource.remove(id);

      delPatient(id);
      showSuccess('The operation was completed successfully.');
    } catch {
      showError('An error occurred, please try again.');
    } finally {
      handleCloseDialog();
    }
  };
  const handleCreate = () => {
    setFormData();
    showModal('Add new patient account');
  };
  const handleEdit = paAccount => {
    setFormData(paAccount);
    showModal(`Edit patient account: #${paAccount.accountNumber}`);
  };
  const handleClose = (e, eventType, patient) => {
    if (eventType === 'submitClick') {
      setPatients(patient);
    }

    hideModal();
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const query = new URLSearchParams(location.search);
        const { results, count } = await PatientAccountResource.getAll({
          patientName:
            query.get('patient') || (searchParams && searchParams.name),
          locationId:
            query.get('location') || (searchParams && searchParams.location),
          clinicId:
            query.get('clinic') ||
            (searchParams && searchParams.clinic && searchParams.clinic.value),
          accountNumber:
            query.get('accountNumber') ||
            (searchParams &&
              searchParams.accountNumber &&
              searchParams.accountNumber),
          page: page + 1,
          limit,
        });

        setTotal(count);
        setPatients(results);
      } catch {
        showError('An error occurs while loading the data table.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    limit,
    page,
    searchParams,
    setTotal,
    setPatients,
    showError,
    location.search,
  ]);

  return (
    <>
      <ExpansionPanel expanded={expanded} onChange={handleExpansion}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">Search By</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Formik
            onSubmit={handleSubmit}
            onReset={handleReset}
            initialValues={initialValues}
            validationSchema={validations}
            render={formProps => (
              <Form className={classes.form}>
                <Grid container spacing={2} justify="flex-end">
                  <Grid item sm={3} xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Patient"
                      type="text"
                      name="name"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <AutoCompleteSelectField
                      label="Location"
                      name="location"
                      loadOptions={fetchLocationsOptions}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <AutoCompleteSelectField
                      label="Clinic"
                      name="clinic"
                      loadOptions={fetchClinicsOptions}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Patient Account Number"
                      type="text"
                      name="accountNumber"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">#</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      fullWidth
                      type="reset"
                      onClick={() => formProps.resetForm(initialValues)}
                    >
                      Reset
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      fullWidth
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={formProps.isSubmitting}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <DataTable
        title={isAttorney || isParalegal ? 'Clients' : 'Patient Accounts'}
        search={false}
        loading={loading}
        data={patients}
        renderColums={() => (
          <TableRow>
            <TableCell>Account #</TableCell>
            <TableCell>Patient</TableCell>
            <Hidden xsDown>
              <TableCell>Date of Accident</TableCell>
            </Hidden>
            <Hidden xsDown>
              <TableCell>Clinic</TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell>Location</TableCell>
            </Hidden>
            <Hidden mdDown>
              <TableCell>Firm</TableCell>
            </Hidden>
            <TableCell />
          </TableRow>
        )}
        renderRows={item => (
          <TableRow key={item.id}>
            <TableCell>#{item.accountNumber}</TableCell>
            <TableCell>{`${item.patient.firstName} ${item.patient.lastName}`}</TableCell>
            <Hidden xsDown>
              <TableCell>
                {moment.utc(item.dateOfAccident).format('MMM DD, YYYY')}
              </TableCell>
            </Hidden>
            <Hidden xsDown>
              <TableCell>{item.clinic.name}</TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell>
                {item.clinic.location ? item.clinic.location.name : '-'}
              </TableCell>
            </Hidden>
            <Hidden mdDown>
              <TableCell>{item.firm.name}</TableCell>
            </Hidden>
            <TableCell align="right">
              <Typography variant="body2">
                <Link to={`/patient-account/${item.id}`}>View</Link>
                <Can I="update" a="PatientAccount">
                  {' '}
                  /{' '}
                  <MuiLink
                    component="button"
                    variant="body2"
                    onClick={() => handleEdit(item)}
                  >
                    Edit
                  </MuiLink>
                </Can>
                <Can I="delete" a="PatientAccount">
                  {' '}
                  /{' '}
                  <MuiLink
                    component="button"
                    variant="body2"
                    onClick={() => handleOpenDialog(item)}
                  >
                    Delete
                  </MuiLink>
                </Can>
              </Typography>
            </TableCell>
          </TableRow>
        )}
        renderPagination={() => (
          <TablePagination
            page={page}
            component="div"
            count={total}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
            onChangePage={(e, newPage) => setPage(newPage)}
            onChangeRowsPerPage={e => setLimit(e.target.value)}
          />
        )}
      />
      <Can I="create" a="PatientAccount">
        <Fab color="secondary" onClick={handleCreate} />
      </Can>
      <PatientAccountForm
        data={formData}
        title={modalTitle}
        open={isModalOpen}
        onClose={handleClose}
      />
      <ModalDialog
        fullWidth
        maxWidth="xs"
        open={openDialog}
        submitLabel="Delete"
        title="Confirm Delete"
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
        subtitle="Are you sure you want to delete this patient account?"
      />
    </>
  );
};

PatientAccountList.propTypes = propTypes;

export default PatientAccountList;
