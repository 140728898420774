import React from 'react';
import { withRouter } from 'react-router-dom';
import { func, node, bool, string, shape } from 'prop-types';
import {
  Paper,
  CardActions,
  CardContent,
  IconButton,
  Typography,
  Button,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  card: {
    overflow: 'hidden',
  },
  cardOverflow: {
    overflow: 'visible',
  },
  cardHeader: {
    height: 50,
    backgroundColor: theme.palette.primary.main,
    '& > *': {
      color: theme.palette.primary.contrastText,
    },
  },
  actionLabel: {
    marginLeft: 5,
    fontSize: '0.9rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  button: { marginLeft: 'auto' },
  noPadding: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
}));

const propTypes = {
  loading: bool,
  useBack: bool,
  onAction: func,
  noPadding: bool,
  actionIcon: node,
  renderAction: func,
  actionLabel: string,
  title: string.isRequired,
  history: shape({}).isRequired,
  children: node.isRequired,
  showOverflow: bool,
};

const defaultProps = {
  loading: false,
  useBack: false,
  actionIcon: null,
  noPadding: false,
  actionLabel: null,
  onAction: null,
  renderAction: null,
  showOverflow: false,
};

const Card = ({
  title,
  history,
  loading,
  useBack,
  onAction,
  children,
  noPadding,
  actionIcon,
  actionLabel,
  renderAction,
  showOverflow,
}) => {
  const classes = useStyles();
  const onBack = () => {
    history.goBack();
  };

  let ActionBar = null;

  if (renderAction) {
    ActionBar = renderAction();
  } else if (actionLabel && onAction) {
    ActionBar = (
      <Button size="small" onClick={onAction} className={classes.button}>
        {actionIcon}
        <Typography className={classes.actionLabel}>{actionLabel}</Typography>
      </Button>
    );
  }

  return (
    <Paper className={showOverflow ? classes.cardOverflow : classes.card}>
      <CardActions className={classes.cardHeader}>
        {useBack && (
          <IconButton size="small" onClick={onBack}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography>{title}</Typography>
        {ActionBar}
      </CardActions>
      {loading ? <LinearProgress color="secondary" /> : null}
      <CardContent className={noPadding ? classes.noPadding : ''}>
        {children}
      </CardContent>
    </Paper>
  );
};

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export default withRouter(Card);
