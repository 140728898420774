import React from 'react';
import { string, shape, bool } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const propTypes = {
  message: shape({
    schedule: string.isRequired,
    content: string.isRequired,
  }).isRequired,
  sender: bool.isRequired,
};

const useStyles = makeStyles(theme => ({
  box: {
    maxWidth: '85%',
    margin: 'auto',
    position: 'relative',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    '&:after': {
      content: '""',
      top: 0,
      borderTop: 0,
      position: 'absolute',
      border: `${theme.spacing(2)}px solid transparent`,
    },
  },
  primary: {
    background: grey[200],
    borderTopLeftRadius: 0,
    marginLeft: theme.spacing(2),
    '&:after': {
      left: 0,
      borderLeft: 0,
      borderRightColor: grey[200],
      marginLeft: -theme.spacing(2),
    },
  },
  secondary: {
    background: theme.palette.primary.light,
    borderTopRightRadius: 0,
    marginRight: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    '&:after': {
      right: 0,
      borderRight: 0,
      marginRight: -theme.spacing(2),
      borderLeftColor: theme.palette.primary.light,
    },
  },
}));

const Box = ({ sender, message }) => {
  const classes = useStyles();
  const variant = sender ? 'primary' : 'secondary';

  return (
    <div className={`${classes.box} ${classes[variant]}`}>
      <Typography variant="caption">{message.schedule}</Typography>
      <Typography variant="body2">{message.content}</Typography>
    </div>
  );
};

Box.propTypes = propTypes;

export default Box;
