import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { useMessage } from '../MessagesContext';
import EmptyMessagesImage from '../../../assets/images/empty-mess.png';
import { ConversationTypes } from '../../../constants/messages';
import MessageModal from '../MessageModal/MessageModal';
import useModal from '../../../hooks/modal';

const useStyles = makeStyles(({ spacing }) => ({
  container: { padding: spacing(2) },
  img: { marginBottom: spacing(2) },
}));

const EmptyMessages = () => {
  const classes = useStyles();
  const { isModalOpen, showModal, hideModal } = useModal();
  const { addConversations, type } = useMessage();
  const handleConversationCreated = conversations => {
    addConversations(conversations);
  };

  return (
    <Grid container justify="center" className={classes.container}>
      <Grid
        item
        xs={12}
        sm={6}
        container
        justify="center"
        direction="column"
        alignItems="center"
      >
        <Grid
          item
          container
          justify="center"
          direction="column"
          alignItems="center"
        >
          <div className={classes.img}>
            <img src={EmptyMessagesImage} alt="Empty messages" />
          </div>
          <Typography paragraph variant="h5" color="secondary" align="center">
            <strong>You don&apos;t have any messages</strong>
          </Typography>
          <Button color="secondary" variant="contained" onClick={showModal}>
            Create New Message
          </Button>
        </Grid>
      </Grid>
      <MessageModal
        open={isModalOpen}
        onClose={hideModal}
        isGeneral={type === ConversationTypes.GENERAL}
        onConversarionCreated={handleConversationCreated}
      />
    </Grid>
  );
};

export default EmptyMessages;
