import React from 'react';
import { string, arrayOf, shape } from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import { MenuItem, TextField as MuiTextField } from '@material-ui/core';

const propTypes = {
  name: string.isRequired,
  options: arrayOf(
    shape({
      label: string,
      value: string,
    }),
  ).isRequired,
};

const SelectField = ({ name, options, ...props }) => (
  <Field
    name={name}
    render={({ field, form: { touched, errors } }) => (
      <MuiTextField
        select
        error={Boolean(touched[field.name] && errors[field.name])}
        helperText={<ErrorMessage name={name} />}
        {...field}
        {...props}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiTextField>
    )}
  />
);

SelectField.propTypes = propTypes;

export default SelectField;
