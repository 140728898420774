import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import PaymentsTable from '../PaymentsTable/PaymentsTable';
import { usePADetailContext } from '../../PatientAccountDetailContext';

const AllPaymentsModal = () => {
  const {
    payments,
    isAllPaymentsModalOpen,
    hideAllPaymentsModal,
  } = usePADetailContext();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isAllPaymentsModalOpen}
      onClose={hideAllPaymentsModal}
    >
      <DialogTitle>All Payments Received</DialogTitle>
      <DialogContent>
        <PaymentsTable data={payments} />
      </DialogContent>
      <DialogActions>
        <Button onClick={hideAllPaymentsModal} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AllPaymentsModal;
