import React, { useState, useContext, createContext, useEffect } from 'react';
import { func } from 'prop-types';
import moment from 'moment';
import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  ConversationTypes,
  ConversationStatus,
} from '../../constants/messages';
import { ConversationResource, MessageResource } from '../../services/http';
import { useAuth } from '../../contexts/AuthContext';
import { useSnackBar } from '../../contexts/SnackBarContext';

export const MessageContext = createContext({
  tab: 0,
  unreads: [0, 0],
  conversationSelected: null,
  conversationsMarked: [],
  conversations: [],
  messages: [],
  isMobile: false,
  loadingMessages: false,
  loadingTab: false,
  type: ConversationTypes.GENERAL,
  setTab: () => {},
  selectConversation: () => {},
  addMessage: () => {},
  addConversations: () => {},
  markAllConversations: () => {},
  markOneConversation: () => {},
  deleteMarked: () => {},
  readMarked: () => {},
});

export const useMessage = () => {
  return useContext(MessageContext);
};

const propTypes = {
  children: func.isRequired,
};

const parseMsg = (message, userId) => ({
  id: message.id,
  sender: message.creatorId !== userId,
  body: message.body,
  schedule: moment(message.createdAt).format('H:mm A'),
});

const parseConversation = (item, user) => {
  const { creator, recipient, patientAccountId } = item.conversation;

  return {
    id: item.conversation.id,
    name: creator.id !== user.id ? creator.name : recipient.name,
    subject: item.conversation.subject,
    schedule: moment(item.updatedAt).fromNow(),
    read: item.status === ConversationStatus.READ,
    forPatientAccount: patientAccountId,
  };
};

const MessageProvider = ({ children }) => {
  const theme = useTheme();
  const { user } = useAuth();
  const { showError } = useSnackBar();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [tab, setTab] = useState(0);
  const [unreads, setUnreads] = useState([0, 0]);
  const [calculate, setCalculate] = useState(false);
  const [conversationSelected, setConversationSelected] = useState(null);
  const [conversationsMarked, setConversationsMarked] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [loadingTab, setLoadingTab] = useState(false);
  const type =
    tab === 0 ? ConversationTypes.GENERAL : ConversationTypes.PATIENT;

  const updateConversations = async (ids, status) => {
    await ConversationResource.bulkUpdate(
      ids.map(conversationId => ({ conversationId, status })),
    );

    if (status === ConversationStatus.DELETED) {
      setConversations(prev => prev.filter(c => ids.indexOf(c.id) === -1));
    } else {
      const read = status === ConversationStatus.READ;

      setConversations(prev =>
        prev.map(c => (ids.indexOf(c.id) === -1 ? c : { ...c, read })),
      );
    }

    setCalculate(prev => !prev);
  };

  const addMessage = message => {
    setMessages(prev => prev.concat(parseMsg(message, user.id)));
  };

  const addConversations = newconversations => {
    const parsedConversations = newconversations.map(conversation =>
      parseConversation(conversation, user),
    );
    setConversations(prev => [...parsedConversations, ...prev]);
  };

  const selectConversation = async selected => {
    const conversationId = selected.identifier;
    setLoadingMessages(true);
    try {
      const conversation = conversations.find(c => c.id === conversationId);
      const { results } = await MessageResource.getAll({
        conversationId,
        limit: 0,
      });

      setMessages(results.map(i => parseMsg(i, user.id)));
      setConversationSelected({
        id: selected.identifier,
        ...selected,
      });

      if (!conversation.read) {
        updateConversations([conversationId], ConversationStatus.READ).catch(
          () => {},
        );
      }
    } catch {
      showError('An error occurred loading the messages. Please, try again.');
    }
    setLoadingMessages(false);
  };

  const markAllConversations = e => {
    if (e.target.checked) {
      setConversationsMarked(conversations.map(i => i.id));
      return;
    }

    setConversationsMarked([]);
  };

  const markOneConversation = id => {
    const markedIndex = conversationsMarked.indexOf(id);
    let newMarked = [];

    if (markedIndex === -1) {
      newMarked = newMarked.concat(conversationsMarked, id);
    } else if (markedIndex === 0) {
      newMarked = newMarked.concat(conversationsMarked.slice(1));
    } else if (markedIndex === conversationsMarked.length - 1) {
      newMarked = newMarked.concat(conversationsMarked.slice(0, -1));
    } else if (markedIndex > 0) {
      newMarked = newMarked.concat(
        conversationsMarked.slice(0, markedIndex),
        conversationsMarked.slice(markedIndex + 1),
      );
    }

    setConversationsMarked(newMarked);
  };

  const readMarked = async () => {
    try {
      await updateConversations(conversationsMarked, ConversationStatus.READ);
    } catch {
      showError('An error occurred. Please, try again.');
    }
  };

  const deleteMarked = async () => {
    try {
      await updateConversations(
        conversationsMarked,
        ConversationStatus.DELETED,
      );

      if (conversationsMarked.indexOf(conversationSelected.id) !== 1) {
        setConversationSelected(null);
        setMessages([]);
      }

      setConversationsMarked([]);
    } catch {
      showError('An error occurred. Please, try again.');
    }
  };

  const context = {
    tab,
    unreads,
    conversationSelected,
    conversationsMarked,
    conversations,
    messages,
    isMobile,
    loadingMessages,
    loadingTab,
    type,
    setTab,
    selectConversation,
    addMessage,
    addConversations,
    markAllConversations,
    markOneConversation,
    deleteMarked,
    readMarked,
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadingTab(true);
      try {
        const { results } = await ConversationResource.getAll({
          type,
          limit: 0,
        });

        setConversations(results.map(item => parseConversation(item, user)));
      } catch {
        showError('An error occurred loading the messages. Please, try again.');
      }
      setLoadingTab(false);
    };

    fetchData();
    setConversationSelected(null);
    setConversationsMarked([]);
    setMessages([]);
  }, [tab, user.id, showError, user, type]);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const counts = await ConversationResource.getUnreadsCount();
        setUnreads(counts);
        // eslint-disable-next-line no-empty
      } catch {}
    };

    fetchCounts();
  }, [calculate]);

  return (
    <MessageContext.Provider value={context}>
      {children(loadingTab, conversations.length > 0)}
    </MessageContext.Provider>
  );
};

MessageProvider.propTypes = propTypes;

export default MessageProvider;
