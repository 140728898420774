import { useState, useCallback } from 'react';

export default (initial = []) => {
  const [state, setState] = useState(initial);

  const delData = useCallback(id => {
    if (!Number.isNaN(id)) {
      setState(prev => {
        const prevData = [...prev];
        const idx = prevData.findIndex(i => i.id === id);

        if (idx !== -1) {
          prevData.splice(idx, 1);
        }

        return prevData;
      });
    }
  }, []);
  const setData = useCallback(data => {
    if (Array.isArray(data)) {
      setState(data);
      return;
    }

    setState(prev => {
      const idx = prev.findIndex(i => i.id === data.id);

      if (idx !== -1) {
        return prev.map(i => (i.id === data.id ? data : i));
      }

      return [data, ...prev];
    });
  }, []);

  return [state, setData, delData];
};
