import React from 'react';
import { string, bool, func, shape } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import states from '../../../constants/states';
import TextField from '../../../components/TextField/TextField';
import ModalForm from '../../../components/ModalForm/ModalForm';
import SelectField from '../../../components/SelectField/SelectField';
import AutoCompleteSelectField from '../../../components/AutoCompleteSelectField/AutoCompleteSelectField';
import { FirmResource, CustomerResource } from '../../../services/http';
import { Can, usePermissions } from '../../../contexts/AuthContext';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import validations from './validations';

const propTypes = {
  title: string.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  data: shape({}),
};

const defaultProps = {
  data: {},
};

const FirmsForm = ({ title, open, onClose, data }) => {
  const permissions = usePermissions();
  const initialValues = {
    name: data.name || '',
    city: data.city || '',
    street: data.street || '',
    zipCode: data.zipCode || '',
    state: data.state || '',
    customerId: data.customer
      ? { label: data.customer.name, value: data.customer.id }
      : '',
    haveCustomer: permissions.can('create', 'Firm', 'customerId'),
  };
  const { showSuccess, showError } = useSnackBar();
  const handleSubmit = async (
    { haveCustomer, customerId, ...values },
    { setSubmitting },
  ) => {
    try {
      const firm = await FirmResource.save({
        ...values,
        ...(data.id && { id: data.id }),
        ...(customerId && { customerId: customerId.value }),
      });

      onClose(null, 'submitClick', firm);
      showSuccess(`Successfully ${data.id ? 'updated' : 'added'} Firm`);
    } catch ({ message }) {
      showError(message || 'An error occurred, please try again.');
    } finally {
      setSubmitting(false);
    }
  };
  const fetchCustomerOptions = async name => {
    try {
      const { results } = await CustomerResource.getAll({ name });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };

  return (
    <ModalForm
      open={open}
      title={title}
      onClose={onClose}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validations}
      submitLabel={data.id ? 'Save' : 'Create'}
      render={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="name"
              type="text"
              label="Name"
              variant="outlined"
            />
          </Grid>
          <Can I="create customerId" a="Firm">
            <Grid item xs={12} sm={6}>
              <AutoCompleteSelectField
                name="customerId"
                label="Customer"
                loadOptions={fetchCustomerOptions}
              />
            </Grid>
          </Can>
          <Grid item xs={12} sm={6}>
            <SelectField
              fullWidth
              name="state"
              label="State"
              variant="outlined"
              options={states}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="street"
              type="text"
              label="Street"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="city"
              type="text"
              label="City"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="zipCode"
              type="number"
              label="ZIP Code"
              variant="outlined"
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

FirmsForm.propTypes = propTypes;
FirmsForm.defaultProps = defaultProps;

export default FirmsForm;
