import React from 'react';
import { oneOf } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Fab as BaseFab } from '@material-ui/core';
import { Add as AddIcon, Create as EditIcon } from '@material-ui/icons';

const propTypes = {
  icon: oneOf(['add', 'edit']),
};

const defaultProps = {
  icon: 'add',
};

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const Fab = ({ icon, ...props }) => {
  const classes = useStyles();
  let Icon = null;

  if (icon === 'add') {
    Icon = <AddIcon />;
  } else if (icon === 'edit') {
    Icon = <EditIcon />;
  }

  return (
    <BaseFab {...props} className={classes.fab}>
      {Icon}
    </BaseFab>
  );
};

Fab.propTypes = propTypes;
Fab.defaultProps = defaultProps;

export default Fab;
