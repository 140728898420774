import React from 'react';
import { bool, string, oneOf } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  square: {
    color: '#fff',
    borderRadius: 5,
    padding: '5px 8px',
    fontSize: theme.typography.caption.fontSize,
    textTransform: 'uppercase',
  },
  notSquare: {
    fontSize: theme.typography.caption.fontSize,
    '& > i': {
      width: 10,
      height: 10,
      marginRight: 7,
      borderRadius: '100%',
      display: 'inline-block',
    },
  },
  red: {
    backgroundColor: 'rgb(255, 71, 87)',
    boxShadow: '0 0 10px rgba(255, 71, 87, 0.8)',
  },
  blue: {
    backgroundColor: 'rgb(0, 137, 255)',
    boxShadow: '0 0 10px rgba(0, 137, 255, 0.8)',
  },
  green: {
    backgroundColor: 'rgb(70, 201, 58)',
    boxShadow: '0 0 10px rgba(70, 201, 58, 0.8)',
  },
  yellow: {
    backgroundColor: 'rgb(255, 186, 0)',
    boxShadow: '0 0 10px rgba(255, 186, 0, 0.8)',
  },
}));

const propTypes = {
  color: oneOf(['green', 'blue', 'red', 'yellow']).isRequired,
  label: string,
  square: bool,
};

const defaultProps = {
  label: null,
  square: false,
};

const StatusIcon = ({ color, label, square }) => {
  const classes = useStyles();
  const Square = (
    <span className={`${classes.square} ${classes[color]}`}>{label}</span>
  );
  const NotSquare = (
    <span className={classes.notSquare}>
      <i className={classes[color]} />
      {label}
    </span>
  );

  return square ? Square : NotSquare;
};

StatusIcon.propTypes = propTypes;
StatusIcon.defaultProps = defaultProps;

export default StatusIcon;
