import React, { Fragment } from 'react';
import { number, func } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Delete as DeleteIcon,
  Drafts as DraftsIcon,
  Create as CreateIcon,
} from '@material-ui/icons';
import {
  Tooltip,
  Checkbox,
  Typography,
  IconButton,
  Toolbar as MuiToolbar,
} from '@material-ui/core';

const propTypes = {
  rowCount: number.isRequired,
  numMarked: number.isRequired,
  onMarked: func.isRequired,
  onRead: func.isRequired,
  onDelete: func.isRequired,
  onComposeMessage: func.isRequired,
};

const useStyles = makeStyles(theme => ({
  title: { flex: '0 0 auto' },
  spacer: { flex: '1 1 100%' },
  actions: { display: 'flex' },
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    minHeight: 55,
  },
}));

const Toolbar = ({
  onMarked,
  numMarked,
  rowCount,
  onRead,
  onDelete,
  onComposeMessage,
}) => {
  const classes = useStyles();

  return rowCount > 0 ? (
    <MuiToolbar disableGutters variant="dense" className={classes.toolbar}>
      <Checkbox
        color="primary"
        onChange={onMarked}
        checked={numMarked === rowCount}
        indeterminate={numMarked > 0 && numMarked < rowCount}
      />
      <div className={classes.title}>
        {numMarked > 0 ? (
          <Typography variant="subtitle1">{numMarked} selected</Typography>
        ) : (
          <Typography variant="subtitle1">Select all</Typography>
        )}
      </div>
      <div className={classes.spacer} />
      {numMarked > 0 ? (
        <Fragment>
          <div className={classes.actions}>
            <Tooltip title="Mark as read">
              <IconButton color="primary" onClick={onRead}>
                <DraftsIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={onDelete}>
                <DeleteIcon color="error" />
              </IconButton>
            </Tooltip>
          </div>
        </Fragment>
      ) : (
        <div className={classes.actions}>
          <Tooltip title="Compose message">
            <IconButton color="primary" onClick={onComposeMessage}>
              <CreateIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </MuiToolbar>
  ) : null;
};

Toolbar.propTypes = propTypes;

export default Toolbar;
