import React, { useState, useEffect } from 'react';
import { shape } from 'prop-types';
import {
  List,
  Grid,
  Button,
  Divider,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import FindAProviderForm from '../FindAProviderForm/FindAProviderForm';
import ProviderBio from '../ProviderBio/ProviderBio';
import ModalDialog from '../../../components/ModalDialog/ModalDialog';
import Card from '../../../components/Card/Card';
import Fab from '../../../components/Fab/Fab';
import useModal from '../../../hooks/modal';
import { Can } from '../../../contexts/AuthContext';
import { ProvidersResource } from '../../../services/http';
import { useSnackBar } from '../../../contexts/SnackBarContext';

const useDividerStyles = makeStyles(({ spacing }) => ({
  root: { margin: spacing(2.5, 0) },
}));

const useStyles = makeStyles(({ spacing }) => ({
  contentWrapper: {
    padding: spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  content: { width: '90%' },
  listIcon: { minWidth: 'auto' },
}));

const propTypes = {
  history: shape().isRequired,
  match: shape().isRequired,
};

const FindAProviderPage = ({ history: { push }, match: { params } }) => {
  const classes = useStyles();
  const dividerClasses = useDividerStyles();
  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState(null);
  const [isModalDialogOpen, setModalDialogOpen] = useState(false);
  const { isModalOpen, modalTitle, showModal, hideModal } = useModal();
  const { showError, showSuccess } = useSnackBar();

  const handleEdit = () => {
    showModal('Edit provider');
  };
  const handleClose = (e, eventType, item) => {
    if (eventType === 'submitClick') {
      setProvider(item);
    }

    hideModal();
  };
  const handleOpenDialog = () => {
    setModalDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setModalDialogOpen(false);
  };
  const handleConfirmDialog = async () => {
    try {
      await ProvidersResource.remove(provider.id);

      push('/find-a-provider');
      showSuccess('The operation was completed successfully.');
    } catch {
      showError('An error occurred, please try again.');
    } finally {
      handleCloseDialog();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = Number(params.id);

        if (Number.isNaN(id)) {
          throw new Error();
        }

        const data = await ProvidersResource.get(id);

        setProvider(data);
      } catch {
        push('/find-a-provider');
        showError('An error occurs, please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [params, push, showError]);

  const Loader = () => (
    <Grid container justify="center">
      <CircularProgress />
    </Grid>
  );

  const Content = () => (
    <>
      <Card noPadding useBack title="Find a Provider" loading={false}>
        <>
          <ProviderBio
            useBg
            {...provider}
            title={`${provider.firstName} ${provider.lastName}`}
          />
          <div className={classes.contentWrapper}>
            <div className={classes.content}>
              {provider.medicalSchool && (
                <>
                  <Typography gutterBottom variant="body2">
                    <strong>Medical School</strong>
                  </Typography>
                  <Typography variant="body2">
                    {provider.medicalSchool}
                  </Typography>
                  <Divider classes={dividerClasses} />
                </>
              )}
              {provider.residency && (
                <>
                  <Typography gutterBottom variant="body2">
                    <strong>Residency</strong>
                  </Typography>
                  <Typography variant="body2">{provider.residency}</Typography>
                  <Divider classes={dividerClasses} />
                </>
              )}
              {provider.clinic && (
                <>
                  <Typography gutterBottom variant="body2">
                    <strong>Clinic</strong>
                  </Typography>
                  <Typography variant="body2">{provider.clinic}</Typography>
                  <Divider classes={dividerClasses} />
                </>
              )}
              {provider.boardCertification && (
                <>
                  <Typography gutterBottom variant="body2">
                    <strong>Board Certification</strong>
                  </Typography>
                  <List dense>
                    {provider.boardCertification.split(',').map((item, key) => (
                      <ListItem disableGutters key={key.toString()}>
                        <ListItemIcon className={classes.listIcon}>
                          <KeyboardArrowRightIcon color="secondary" />
                        </ListItemIcon>
                        <ListItemText primary={item.trim()} />
                      </ListItem>
                    ))}
                  </List>
                  <Divider classes={dividerClasses} />
                </>
              )}
              {provider.hospitalAffiliation && (
                <>
                  <Typography gutterBottom variant="body2">
                    <strong>Hospital Affiliation</strong>
                  </Typography>
                  <List dense>
                    {provider.hospitalAffiliation
                      .split(',')
                      .map((item, key) => (
                        <ListItem disableGutters key={key.toString()}>
                          <ListItemIcon className={classes.listIcon}>
                            <KeyboardArrowRightIcon color="secondary" />
                          </ListItemIcon>
                          <ListItemText primary={item.trim()} />
                        </ListItem>
                      ))}
                  </List>
                  <Divider classes={dividerClasses} />
                </>
              )}
              {provider.professionalOrganizations && (
                <>
                  <Typography gutterBottom variant="body2">
                    <strong>Professional Organizations</strong>
                  </Typography>
                  <List dense>
                    {provider.professionalOrganizations
                      .split(',')
                      .map((item, key) => (
                        <ListItem disableGutters key={key.toString()}>
                          <ListItemIcon className={classes.listIcon}>
                            <KeyboardArrowRightIcon color="secondary" />
                          </ListItemIcon>
                          <ListItemText primary={item.trim()} />
                        </ListItem>
                      ))}
                  </List>
                </>
              )}
            </div>
          </div>
          <Can I="delete" a="Provider">
            <div className={classes.contentWrapper}>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleOpenDialog}
              >
                Delete this provider
              </Button>
            </div>
          </Can>
        </>
      </Card>
      <Can I="update" a="Provider">
        <Fab color="secondary" icon="edit" onClick={handleEdit} />
      </Can>
      <FindAProviderForm
        title={modalTitle}
        open={isModalOpen}
        onClose={handleClose}
        data={provider}
      />
      <ModalDialog
        fullWidth
        maxWidth="xs"
        submitLabel="Delete"
        title="Confirm Delete"
        open={isModalDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
        subtitle="Are you sure you want to delete this provider?"
      />
    </>
  );

  return loading ? <Loader /> : <Content />;
};

FindAProviderPage.propTypes = propTypes;

export default FindAProviderPage;
