import React, { useState, useEffect } from 'react';
import { Form, Formik, Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Create as EditIcon, SearchOutlined } from '@material-ui/icons';
import {
  Hidden,
  Tooltip,
  TableRow,
  TableCell,
  TextField,
  IconButton,
  TablePagination,
} from '@material-ui/core';
import useModal from '../../hooks/modal';
import useDataTable from '../../hooks/data-table';
import usePagination from '../../hooks/pagination-table';
import { ROLE_LABEL } from '../../constants/roles';
import { UserResource } from '../../services/http';
import { Can } from '../../contexts/AuthContext';
import { useSnackBar } from '../../contexts/SnackBarContext';
import DataTable from '../../components/DataTable/DataTable';
import Fab from '../../components/Fab/Fab';
import StatusSwitch from './StatusSwitch/StatusSwitch';
import UserForm from './UserForm/UserForm';

const useStyles = makeStyles(({ palette, spacing }) => ({
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  search: {
    color: palette.primary.contrastText,
    '&:hover:before, &:before, &:after': {
      borderBottomColor: `${palette.primary.contrastText} !important`,
    },
  },
  textField: { marginLeft: spacing(1) },
  customSearchFieldsContainer: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const UserPage = () => {
  const initialValues = {
    name: '',
    email: '',
  };
  const classes = useStyles();
  const [users, setUsers] = useDataTable();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useState({});
  const { isModalOpen, modalTitle, showModal, hideModal } = useModal();
  const { total, setTotal, limit, setLimit, page, setPage } = usePagination();
  const { showError } = useSnackBar();

  const handleCreate = () => {
    setFormData();
    showModal('Add new user');
  };
  const handleEdit = user => {
    setFormData(user);
    showModal(`Edit user ${user.name}`);
  };
  const handleClose = (e, eventType, user) => {
    if (eventType === 'submitClick') {
      setUsers(user);
    }

    hideModal();
  };
  const handleSearch = fields => setSearchParams(fields);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { results, count } = await UserResource.getAll({
          ...searchParams,
          page: page + 1,
          limit,
        });
        setTotal(count);
        setUsers(results);
      } catch {
        showError('An error occurs while loading the data table.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [limit, page, searchParams, setTotal, setUsers, showError]);

  const CustomField = fieldProps => {
    const { name, placeholder } = fieldProps;

    return (
      <Field
        name={name}
        render={({ field }) => (
          <TextField
            placeholder={placeholder}
            className={classes.textField}
            InputProps={{
              classes: { root: classes.search },
            }}
            {...field}
          />
        )}
      />
    );
  };

  const customSearch = () => (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSearch}
      render={() => (
        <Form className={classes.customSearchFieldsContainer}>
          <CustomField name="name" placeholder="Search by name" />
          <CustomField name="email" placeholder="Search by email" />
          <IconButton type="submit" className={classes.search}>
            <SearchOutlined />
          </IconButton>
        </Form>
      )}
    />
  );

  return (
    <>
      <DataTable
        title="Users"
        data={users}
        loading={loading}
        renderSearch={customSearch}
        renderColums={() => (
          <TableRow>
            <TableCell>Name</TableCell>
            <Hidden xsDown>
              <TableCell>Email</TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell>Role</TableCell>
            </Hidden>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableRow>
        )}
        renderRows={item => (
          <TableRow key={item.id}>
            <TableCell>{item.name}</TableCell>
            <Hidden xsDown>
              <TableCell>{item.email}</TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell>{ROLE_LABEL[item.role]}</TableCell>
            </Hidden>
            <TableCell>
              <StatusSwitch enable={item.isActive} id={item.id} />
            </TableCell>
            <TableCell>
              <Can I="update" a="User">
                <Tooltip title="Edit">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => handleEdit(item)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Can>
            </TableCell>
          </TableRow>
        )}
        renderPagination={() => (
          <TablePagination
            page={page}
            component="div"
            count={total}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
            onChangePage={(e, newPage) => setPage(newPage)}
            onChangeRowsPerPage={e => setLimit(e.target.value)}
          />
        )}
      />
      <Can I="create" a="User">
        <Fab color="secondary" onClick={handleCreate} />
      </Can>
      <UserForm
        title={modalTitle}
        open={isModalOpen}
        onClose={handleClose}
        data={formData}
      />
    </>
  );
};

export default UserPage;
