import { object, string } from 'yup';

export default object().shape({
  firstName: string()
    .required()
    .label('First Name'),
  lastName: string()
    .required()
    .label('Last Name'),
  email: string()
    .email()
    .required()
    .label('Email'),
  phone: string()
    .test('phone', value =>
      value
        ? /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/.test(
            value,
          )
        : true,
    )
    .required()
    .label('Phone number'),
  fax: string()
    .test('fax', value => (value ? /^\+?[0-9]{6,16}$/.test(value) : true))
    .required()
    .label('Fax'),
  location: string()
    .test('location', value => /[a-zA-Z]+/.test(value))
    .required()
    .label('Location'),
  picture: string()
    .required()
    .label('Avatar'),
});
