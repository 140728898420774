import React, { useState, useContext, createContext, useCallback } from 'react';
import { node } from 'prop-types';
import Snackbar from '../components/SnackBar/SnackBar';

export const SnackBarContext = createContext({
  showInfo: () => {},
  showSuccess: () => {},
  showWarning: () => {},
  showError: () => {},
});

export const useSnackBar = () => {
  return useContext(SnackBarContext);
};

const propTypes = {
  children: node.isRequired,
};

const SnackBarProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    message: '',
    variant: 'info',
    duration: 5000,
  });

  const show = (message, variant, duration) => {
    setState({
      message,
      variant,
      duration,
    });
    setOpen(true);
  };

  const showInfo = useCallback((message, duration = 5000) => {
    show(message, 'info', duration);
  }, []);
  const showSuccess = useCallback((message, duration = 5000) => {
    show(message, 'success', duration);
  }, []);
  const showWarning = useCallback((message, duration = 5000) => {
    show(message, 'warning', duration);
  }, []);
  const showError = useCallback((message, duration = 5000) => {
    show(message, 'danger', duration);
  }, []);

  const hide = () => {
    setOpen(false);
  };

  const context = { showInfo, showSuccess, showWarning, showError };

  return (
    <SnackBarContext.Provider value={context}>
      {children}
      <Snackbar
        open={open}
        variant={state.variant}
        autoHideDuration={state.duration}
        message={state.message}
        onClose={hide}
      />
    </SnackBarContext.Provider>
  );
};

SnackBarProvider.propTypes = propTypes;

export default SnackBarProvider;
