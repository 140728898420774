import { object, string, array, number } from 'yup';
import { SUPER_ADMIN, ATTORNEY, CLINIC_ADMIN } from '../../../constants/roles';

export default object().shape({
  firstName: string()
    .required()
    .label('First name'),
  lastName: string()
    .required()
    .label('Last name'),
  city: string()
    .test('city', value => /^[a-zA-Z\s]*$/.test(value))
    .label('City'),
  email: string()
    .email()
    .required()
    .label('Email'),
  role: string()
    .required()
    .label('Role'),
  phone: string()
    .test('phone', value =>
      value ? /^[+\d]?(?:[\d-.\s()]*)$/.test(value) : true,
    )
    .label('Phone number'),
  customerId: object()
    .when(['role', 'haveCustomer'], (role, haveCustomer, schema) =>
      role && haveCustomer && role !== String(SUPER_ADMIN)
        ? schema.required()
        : schema,
    )
    .label('Customer'),
  firmId: object()
    .when('role', (role, schema) =>
      role === String(ATTORNEY) ? schema.required() : schema,
    )
    .label('Firm'),
  attorneys: array().label('Attorneys'),
  zipCode: number()
    .test('zipCode', value =>
      value ? /^\d{5}(-\d{4})?(?!-)$/.test(value) : true,
    )
    .label('Zip code'),
  clinics: array()
    .when('role', (role, schema) =>
      role === String(CLINIC_ADMIN) ? schema.required() : schema,
    )
    .label('Clinics'),
});
