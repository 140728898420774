import React from 'react';
import Grid from '@material-ui/core/Grid';
import { string, bool, func, shape } from 'prop-types';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import TextField from '../../../components/TextField/TextField';
import ModalForm from '../../../components/ModalForm/ModalForm';
import SelectField from '../../../components/SelectField/SelectField';
import AutoCompleteSelectField from '../../../components/AutoCompleteSelectField/AutoCompleteSelectField';
import { Can, useAuth, usePermissions } from '../../../contexts/AuthContext';
import validations from './validations';
import {
  UserResource,
  FirmResource,
  ClinicResource,
  CustomerResource,
} from '../../../services/http';
import {
  ATTORNEY,
  PARALEGAL,
  ROLE_LIST,
  SUPER_ADMIN,
  CLINIC_ADMIN,
} from '../../../constants/roles';
import {
  ALL,
  NOTIFICATIONS_LIST,
} from '../../../constants/email-notifications';
import states from '../../../constants/states';

const propTypes = {
  title: string.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  data: shape({}),
};

const defaultProps = {
  data: {},
};

const UserForm = ({ title, open, onClose, data }) => {
  const permissions = usePermissions();
  const { user: loggedUser } = useAuth();
  const { showSuccess, showError } = useSnackBar();
  const initialValues = {
    city: data.city || '',
    email: data.email || '',
    state: data.state || '',
    phone: data.phone || '',
    street: data.street || '',
    zipCode: data.zipCode || '',
    lastName: data.lastName || '',
    firstName: data.firstName || '',
    notifications: data.notifications || ALL,
    role: data.role ? String(data.role) : '',
    customerId: data.customer
      ? { label: data.customer.name, value: data.customer.id }
      : '',
    attorneys: data.attorneys
      ? data.attorneys.map(a => ({ label: a.name, value: a.id }))
      : [],
    firmId: data.firm ? { label: data.firm.name, value: data.firm.id } : '',
    clinics: data.clinics
      ? data.clinics.map(a => ({ label: a.name, value: a.id }))
      : [],
    haveCustomer: permissions.can('create', 'User', 'customerId'),
  };
  const roleList = ROLE_LIST.filter(v =>
    loggedUser.role === SUPER_ADMIN
      ? true
      : ![CLINIC_ADMIN, SUPER_ADMIN].includes(Number(v.value)),
  );
  const handleSubmit = async (
    { haveCustomer, customerId, firmId, attorneys, clinics, ...values },
    { setSubmitting },
  ) => {
    try {
      const user = await UserResource.save({
        ...values,
        ...(data.id && { id: data.id }),
        ...(firmId && { firmId: firmId.value }),
        ...(customerId && { customerId: customerId.value }),
        ...(attorneys && { attorneys: attorneys.map(a => ({ id: a.value })) }),
        ...(clinics && { clinics: clinics.map(a => ({ id: a.value })) }),
        state: values.state || null,
      });

      onClose(null, 'submitClick', user);
      showSuccess(`Successfully ${data.id ? 'updated' : 'added'} User`);
    } catch ({ message }) {
      showError(message || 'An error occurs, please try again.');
    } finally {
      setSubmitting(false);
    }
  };
  const fetchCustomerOptions = async name => {
    try {
      const { results } = await CustomerResource.getAll({ name, limit: 5 });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };
  const fetchFirmOptions = async (name, customerId) => {
    try {
      const { results } = await FirmResource.getAll({
        name,
        customerId,
        limit: 5,
      });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };
  const fetchAttorneysOptions = async (name, customerId) => {
    try {
      const { results } = await UserResource.getAll({
        name,
        customerId,
        limit: 5,
        role: ATTORNEY,
      });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };

  const fetchClinicsOptions = async (name, customerId) => {
    try {
      const { results } = await ClinicResource.getAll({
        name,
        customerId,
      });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };

  return (
    <ModalForm
      fullWidth
      open={open}
      title={title}
      onClose={onClose}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validations}
      submitLabel={data.id ? 'Save' : 'Create'}
      render={({
        setFieldValue,
        values: { role, haveCustomer, customerId },
      }) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="firstName"
              type="text"
              label="First name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="lastName"
              type="text"
              label="Last name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="email"
              type="mail"
              label="Email Address"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField
              fullWidth
              name="role"
              label="Role"
              variant="outlined"
              options={roleList}
            />
          </Grid>
          <Can I="create customerId" a="User">
            {role && role !== String(SUPER_ADMIN) && (
              <Grid item xs={12}>
                <AutoCompleteSelectField
                  label="Customer"
                  name="customerId"
                  loadOptions={fetchCustomerOptions}
                  onChange={() => {
                    setFieldValue('firmId', '');
                    setFieldValue('attorneys', []);
                  }}
                />
              </Grid>
            )}
          </Can>
          {role === String(CLINIC_ADMIN) && (
            <Grid item xs={12}>
              <AutoCompleteSelectField
                multi
                label="Clinics"
                name="clinics"
                disabled={!customerId && haveCustomer}
                loadOptions={n => fetchClinicsOptions(n, customerId.value)}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <SelectField
              fullWidth
              name="notifications"
              label="Email Notifications"
              variant="outlined"
              options={NOTIFICATIONS_LIST}
            />
          </Grid>
          {(role === String(ATTORNEY) || role === String(PARALEGAL)) && (
            <Grid item xs={12} sm={6}>
              <AutoCompleteSelectField
                label="Firm"
                name="firmId"
                disabled={!customerId && haveCustomer}
                loadOptions={n => fetchFirmOptions(n, customerId.value)}
              />
            </Grid>
          )}
          {role === String(PARALEGAL) && (
            <Grid item xs={12}>
              <AutoCompleteSelectField
                multi
                label="Attorneys"
                name="attorneys"
                disabled={!customerId && haveCustomer}
                loadOptions={n => fetchAttorneysOptions(n, customerId.value)}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="phone"
              type="tel"
              label="Phone Number"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField
              fullWidth
              name="state"
              label="State"
              variant="outlined"
              options={states}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="street"
              type="text"
              label="Street"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="city"
              type="text"
              label="City"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="zipCode"
              type="number"
              label="ZIP Code"
              variant="outlined"
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

UserForm.propTypes = propTypes;
UserForm.defaultProps = defaultProps;

export default UserForm;
