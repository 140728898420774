export const ConversationTypes = {
  GENERAL: 1,
  PATIENT: 2,
};

export const ConversationStatus = {
  READ: 1,
  UNREAD: 2,
  DELETED: 3,
};
