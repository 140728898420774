import React from 'react';
import { node, bool, func, shape, string } from 'prop-types';
import { Formik, Form } from 'formik';
import {
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';

const propTypes = {
  title: node.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  render: func.isRequired,
  initialValues: shape({}).isRequired,
  onSubmit: func.isRequired,
  validationSchema: shape({}),
  submitLabel: string,
};

const defaultProps = {
  validationSchema: null,
  submitLabel: 'Submit',
};

const useTitleStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    padding: spacing(1.2, 3),
    marginBottom: spacing(1),
    backgroundColor: palette.secondary.dark,
  },
}));

const useTypographyStyles = makeStyles(({ palette }) => ({
  root: {
    color: palette.secondary.contrastText,
  },
}));

const ModalForm = ({
  title,
  open,
  onClose,
  render,
  initialValues,
  onSubmit,
  validationSchema,
  submitLabel,
  ...props
}) => {
  const theme = useTheme();
  const titleClasses = useTitleStyles();
  const typographyClasses = useTypographyStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen} {...props}>
      <DialogTitle disableTypography classes={titleClasses}>
        <Typography variant="button" classes={typographyClasses}>
          {title}
        </Typography>
      </DialogTitle>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={formProps => (
          <Form>
            <DialogContent>{render(formProps)}</DialogContent>
            <DialogActions>
              <Button
                onClick={e => onClose(e, 'cancelClick')}
                color="secondary"
              >
                Cancel
              </Button>
              {formProps.isValid && formProps.dirty && (
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={formProps.isSubmitting}
                >
                  {submitLabel}
                </Button>
              )}
            </DialogActions>
          </Form>
        )}
      />
    </Dialog>
  );
};

ModalForm.propTypes = propTypes;
ModalForm.defaultProps = defaultProps;

export default ModalForm;
