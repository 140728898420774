import { object, string, number } from 'yup';
import states from '../../../constants/states';

export default object().shape({
  customerId: object()
    .when('haveCustomer', {
      is: true,
      then: object().required(),
    })
    .label('Customer'),
  locationId: object()
    .required()
    .label('Location'),
  name: string()
    .trim()
    .required()
    .label('Clinic name'),
  street: string()
    .trim()
    .required()
    .label('Street'),
  city: string()
    .trim()
    .required()
    .label('City'),
  state: string()
    .oneOf(states.map(i => i.value), 'Select a valid option')
    .required()
    .label('State'),
  zipCode: number()
    .test('zipCode', value => /^\d{5}(-\d{4})?(?!-)$/.test(value))
    .required()
    .label('ZIP Code'),
});
