import React from 'react';
import { node, bool, object, oneOfType } from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';

const propTypes = {
  children: node.isRequired,
  innerProps: oneOfType([object]).isRequired,
  isFocused: bool.isRequired,
  isSelected: bool.isRequired,
};

const Option = ({ isFocused, isSelected, innerProps, children }) => (
  <MenuItem
    component="div"
    selected={isFocused}
    style={{ fontWeight: isSelected ? 500 : 400 }}
    {...innerProps}
  >
    {children}
  </MenuItem>
);

Option.propTypes = propTypes;

export default Option;
