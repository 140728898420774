import React from 'react';
import { arrayOf, func, bool } from 'prop-types';
import {
  TableBody,
  makeStyles,
  TableHead,
  LinearProgress,
  Table as MuiTable,
} from '@material-ui/core';
import NoResults from './NoResults';

const propTypes = {
  loading: bool.isRequired,
  renderRows: func.isRequired,
  renderColums: func.isRequired,
  data: arrayOf({}).isRequired,
  renderPagination: func,
};

const defaultProps = {
  renderPagination: () => {},
};

const useStyles = makeStyles(() => ({
  tableWrapper: { overflowX: 'auto' },
  mainTable: {
    '& > *': { whiteSpace: 'nowrap' },
  },
}));

const Table = ({
  data,
  loading,
  renderRows,
  renderColums,
  renderPagination,
}) => {
  const classes = useStyles();

  return (
    <>
      {loading ? <LinearProgress color="secondary" /> : null}
      {!loading &&
        (data.length > 0 ? (
          <>
            <div className={classes.tableWrapper}>
              <MuiTable className={classes.mainTable}>
                <TableHead>{renderColums()}</TableHead>
                <TableBody>{data.map(item => renderRows(item))}</TableBody>
              </MuiTable>
            </div>
            {renderPagination()}
          </>
        ) : (
          <NoResults />
        ))}
    </>
  );
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export default Table;
