import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { oneOfType, string, func } from 'prop-types';
import { useAuth } from '../../contexts/AuthContext';

const propTypes = {
  component: oneOfType([string, func]).isRequired,
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn()) {
    return <Redirect to="/account/login" />;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
};

PrivateRoute.propTypes = propTypes;

export default PrivateRoute;
