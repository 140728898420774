export const STATUS_NEW = 1;
export const STATUS_IN_PROGRESS = 2;
export const STATUS_RELEASED = 3;

export const statusLabel = {
  [STATUS_NEW]: 'New',
  [STATUS_IN_PROGRESS]: 'In Progress',
  [STATUS_RELEASED]: 'Released',
};

export const statusColor = {
  [STATUS_NEW]: 'green',
  [STATUS_IN_PROGRESS]: 'yellow',
  [STATUS_RELEASED]: 'blue',
};

export const statusList = Object.keys(statusLabel).map(value => ({
  label: statusLabel[value],
  value,
}));
