import React from 'react';
import { node } from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import AuthProvider from '../../contexts/AuthContext';
import SnackBarProvider from '../../contexts/SnackBarContext';
import defaultTheme from '../../theme/theme';

const propTypes = {
  children: node.isRequired,
};

const GlobalState = ({ children }) => (
  <ThemeProvider theme={defaultTheme}>
    <SnackBarProvider>
      <AuthProvider>{children}</AuthProvider>
    </SnackBarProvider>
  </ThemeProvider>
);

GlobalState.propTypes = propTypes;

export default GlobalState;
