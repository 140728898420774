export const SUPER_ADMIN = 1;
export const CLINIC_ADMIN = 2;
export const ATTORNEY = 3;
export const PARALEGAL = 4;

export const ROLE_LABEL = {
  [SUPER_ADMIN]: 'Super Administrator',
  [CLINIC_ADMIN]: 'Clinic Administrator',
  [ATTORNEY]: 'Attorney',
  [PARALEGAL]: 'Paralegal',
};

export const ROLE_LIST = Object.keys(ROLE_LABEL).map(value => ({
  label: ROLE_LABEL[value],
  value,
}));
