import React, { useState } from 'react';
import { shape, arrayOf } from 'prop-types';
import {
  Table,
  Tooltip,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ModalDialog from '../../../../../components/ModalDialog/ModalDialog';
import { usePADetailContext } from '../../PatientAccountDetailContext';
import { Can } from '../../../../../contexts/AuthContext';

const propTypes = {
  data: arrayOf(shape({})).isRequired,
};

const Payment = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [paymen, setPayment] = useState({});
  const { handleEditPayment, handleDeletePayment } = usePADetailContext();
  const handleOpenDialog = item => {
    setPayment(item);
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setPayment({});
    setOpen(false);
  };
  const handleConfirmDialog = () => {
    handleDeletePayment(paymen);
    handleCloseDialog();
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <strong>Total</strong>
          </TableCell>
          <TableCell>
            <strong>From</strong>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {data.length > 0 ? (
          data.map(item => (
            <TableRow key={item.id}>
              <TableCell size="small">
                ${parseFloat(Number(item.amount).toFixed(2)).toLocaleString()}
              </TableCell>
              <TableCell size="small">{item.from}</TableCell>
              <TableCell size="small" align="right">
                <Can I="update" a="Payment">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => handleEditPayment(item)}
                  >
                    <Tooltip title="Edit">
                      <EditIcon />
                    </Tooltip>
                  </IconButton>
                </Can>
                <Can I="delete" a="Payment">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleOpenDialog(item)}
                  >
                    <Tooltip title="Delete">
                      <DeleteIcon />
                    </Tooltip>
                  </IconButton>
                </Can>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={3}>No payments</TableCell>
          </TableRow>
        )}
      </TableBody>
      <ModalDialog
        fullWidth
        open={open}
        maxWidth="xs"
        submitLabel="Delete"
        title="Confirm Delete"
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
        subtitle="Are you sure you want to delete this payment?"
      />
    </Table>
  );
};

Payment.propTypes = propTypes;

export default Payment;
