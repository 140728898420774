import React, { Fragment } from 'react';
import clsx from 'clsx';
import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useMessage } from '../MessagesContext';
import Toolbar from './ToolBar/ToolBar';
import Item from './Item/Item';
import MessageModal from '../MessageModal/MessageModal';
import useModal from '../../../hooks/modal';
import { ConversationTypes } from '../../../constants/messages';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  container: {
    width: 300,
    height: '100%',
    transition: 'width 500ms',
    borderRight: `1px solid ${palette.divider}`,
    [breakpoints.down('xs')]: { width: '100%' },
  },
  fullWidth: { width: '100%' },
  list: {
    width: '100%',
    overflowY: 'auto',
    height: 'calc(100% - 55px)',
  },
}));

const Sidebar = () => {
  const classes = useStyles();
  const {
    type,
    isMobile,
    conversations,
    conversationSelected,
    selectConversation,
    conversationsMarked,
    markOneConversation,
    markAllConversations,
    readMarked,
    deleteMarked,
    addConversations,
  } = useMessage();
  const { isModalOpen, showModal, hideModal } = useModal();
  const handleConversationCreated = newConversations => {
    addConversations(newConversations);
  };

  return (
    <Fragment>
      <List
        disablePadding
        className={clsx(classes.container, {
          [classes.fullWidth]: !conversationSelected,
        })}
        subheader={
          <Toolbar
            rowCount={conversations.length}
            numMarked={conversationsMarked.length}
            onMarked={markAllConversations}
            onRead={readMarked}
            onDelete={deleteMarked}
            onComposeMessage={showModal}
          />
        }
      >
        <div className={classes.list}>
          {conversations.map(c => (
            <Item
              key={c.id}
              onSelect={selectConversation}
              onMarked={markOneConversation}
              data={{ ...c, identifier: c.id }}
              selected={
                conversationSelected &&
                c.id === conversationSelected.id &&
                !isMobile
              }
              marked={conversationsMarked.indexOf(c.id) !== -1}
            />
          ))}
        </div>
      </List>
      <MessageModal
        open={isModalOpen}
        onClose={hideModal}
        isGeneral={type === ConversationTypes.GENERAL}
        onConversarionCreated={handleConversationCreated}
      />
    </Fragment>
  );
};

export default Sidebar;
