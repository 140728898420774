import React from 'react';
import Grid from '@material-ui/core/Grid';
import { string, bool, func, shape } from 'prop-types';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import TextField from '../../../components/TextField/TextField';
import ModalForm from '../../../components/ModalForm/ModalForm';
import UploadField from '../../../components/UploadField/UploadFile';
import { CompanyDirectoryResource } from '../../../services/http';
import validations from './validations';

const propTypes = {
  title: string.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  data: shape({}),
};

const defaultProps = {
  data: {},
};

const CompanyDirectoryForm = ({ title, open, onClose, data }) => {
  const { showSuccess, showError } = useSnackBar();
  const initialValues = {
    firstName: data.firstName || '',
    lastName: data.lastName || '',
    email: data.email || '',
    phone: data.phone || '',
    fax: data.fax || '',
    location: data.location || '',
    picture: data.picture || '',
  };
  const handleSubmit = async ({ picture, ...values }, { setSubmitting }) => {
    try {
      const provider = await CompanyDirectoryResource.saveMultipart({
        ...values,
        ...(data.id && { id: data.id }),
        picture,
      });

      onClose(null, 'submitClick', provider);
      showSuccess('The operation was completed successfully.');
    } catch ({ message }) {
      showError(message || 'An error occurs, please try again.');
    } finally {
      setSubmitting(false);
    }
  };
  const handleDownload = () => window.open(data.picture, '__blank', 'noopener');

  return (
    <ModalForm
      fullWidth
      open={open}
      title={title}
      onClose={onClose}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validations}
      submitLabel={data.id ? 'Save' : 'Create'}
      render={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="text"
              name="firstName"
              label="First Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="text"
              name="lastName"
              label="Last Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="email"
              name="email"
              label="Email"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="tel"
              name="phone"
              label="Phone"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="tel"
              name="fax"
              label="Fax"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="text"
              name="location"
              label="Location"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UploadField
              name="picture"
              label="Avatar"
              accept="image/png,image/jpeg,image/jpg"
              onDownload={handleDownload}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

CompanyDirectoryForm.propTypes = propTypes;
CompanyDirectoryForm.defaultProps = defaultProps;

export default CompanyDirectoryForm;
