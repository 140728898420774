import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ spacing }) => ({
  container: { padding: spacing(15, 2) },
}));

const NoResults = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography gutterBottom variant="h5" align="center" color="primary">
        <strong>No Results Found</strong>
      </Typography>
      <Typography variant="body1" align="center">
        <strong>
          There are no results to show or we could not find what you are looking
          for. Please try a different search.
        </strong>
      </Typography>
    </div>
  );
};

export default NoResults;
