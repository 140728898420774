import React, { useState, useEffect } from 'react';
import {
  Hidden,
  Tooltip,
  TableRow,
  TableCell,
  IconButton,
  TablePagination,
} from '@material-ui/core';
import {
  Create as EditIcon,
  Delete as DeleteIcon,
  AddCircleOutline as AddIcon,
} from '@material-ui/icons';
import { ATTORNEY } from '../../constants/roles';
import { Can } from '../../contexts/AuthContext';
import { FirmResource } from '../../services/http';
import { useSnackBar } from '../../contexts/SnackBarContext';
import usePagination from '../../hooks/pagination-table';
import useDataTable from '../../hooks/data-table';
import useModal from '../../hooks/modal';
import Fab from '../../components/Fab/Fab';
import UserForm from '../User/UserForm/UserForm';
import DataTable from '../../components/DataTable/DataTable';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import states from '../../constants/states';
import FirmsForm from './FirmsForm/FirmsForm';

const FirmsPage = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState();
  const [firms, setFirms, delFirm] = useDataTable();
  const { total, setTotal, limit, setLimit, page, setPage } = usePagination();
  const { showError, showSuccess } = useSnackBar();
  const {
    isModalOpen: isAttorneyModalOpen,
    showModal: showAttorneyModal,
    hideModal: hideAttorneyModal,
  } = useModal();
  const {
    isModalOpen: isFirmModalOpen,
    modalTitle: firmModalTitle,
    showModal: showFirmModal,
    hideModal: hideFirmModal,
  } = useModal();

  const handleOpenDialog = item => {
    setFormData(item);
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setFormData({});
    setOpen(false);
  };
  const handleConfirmDialog = async () => {
    const { id } = formData;

    try {
      await FirmResource.remove(id);

      delFirm(id);
      showSuccess('Successfully deleted firm');
    } catch {
      showError('There was an error deleting the firm');
    } finally {
      handleCloseDialog();
    }
  };

  const handleCreateFirm = () => {
    setFormData({});
    showFirmModal('Add new firm');
  };
  const handleEditFirm = firm => {
    setFormData(firm);
    showFirmModal(`Edit firm: ${firm.name}`);
  };
  const handleSearch = e => {
    setSearchParam(e.target.value);
  };
  const handleCloseFirmModal = (e, eventType, firm) => {
    if (eventType === 'submitClick') {
      setFirms(firm);
    }

    hideFirmModal();
  };
  const handleCreateAttorney = ({ id, name, customer }) => {
    setFormData({ customer, firm: { id, name }, role: ATTORNEY });
    showAttorneyModal();
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { results, count } = await FirmResource.getAll({
          name: searchParam,
          page: page + 1,
          limit,
        });

        setTotal(count);
        setFirms(results);
      } catch {
        showError('An error occurs while loading the data table.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [limit, page, searchParam, setTotal, setFirms, showError]);

  return (
    <>
      <DataTable
        title="Firms"
        data={firms}
        searchLabel="Search by name"
        loading={loading}
        onSearch={handleSearch}
        renderColums={() => (
          <TableRow>
            <TableCell>Name</TableCell>
            <Hidden xsDown>
              <TableCell>Street</TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell>City</TableCell>
            </Hidden>
            <Hidden mdDown>
              <TableCell>State</TableCell>
              <TableCell>ZIP Code</TableCell>
            </Hidden>
            <TableCell />
          </TableRow>
        )}
        renderRows={item => (
          <TableRow key={item.id}>
            <TableCell>{item.name}</TableCell>
            <Hidden xsDown>
              <TableCell>{item.street}</TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell>{item.city}</TableCell>
            </Hidden>
            <Hidden mdDown>
              <TableCell>
                {states.find(v => v.value === item.state).label}
              </TableCell>
              <TableCell>{item.zipCode}</TableCell>
            </Hidden>
            <TableCell align="right">
              <Can I="create" a="User">
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => handleCreateAttorney(item)}
                >
                  <Tooltip title="Add new attorney">
                    <AddIcon />
                  </Tooltip>
                </IconButton>
              </Can>
              <Can I="update" a="Firm">
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => handleEditFirm(item)}
                >
                  <Tooltip title="Edit">
                    <EditIcon />
                  </Tooltip>
                </IconButton>
              </Can>
              <Can I="delete" a="Firm">
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => handleOpenDialog(item)}
                >
                  <Tooltip title="Delete">
                    <DeleteIcon />
                  </Tooltip>
                </IconButton>
              </Can>
            </TableCell>
          </TableRow>
        )}
        renderPagination={() => (
          <TablePagination
            page={page}
            component="div"
            count={total}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
            onChangePage={(e, newPage) => setPage(newPage)}
            onChangeRowsPerPage={e => setLimit(e.target.value)}
          />
        )}
      />
      <Can I="create" a="Firm">
        <Fab color="secondary" onClick={handleCreateFirm} />
      </Can>
      <UserForm
        title="Add new attorney to firm"
        open={isAttorneyModalOpen}
        onClose={hideAttorneyModal}
        data={formData}
      />
      <FirmsForm
        title={firmModalTitle}
        open={isFirmModalOpen}
        onClose={handleCloseFirmModal}
        data={formData}
      />
      <ModalDialog
        fullWidth
        open={open}
        maxWidth="xs"
        submitLabel="Yes, I’m sure"
        title="Confirm Delete"
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
        subtitle="Are you sure you want to delete this firm?"
      />
    </>
  );
};

export default FirmsPage;
