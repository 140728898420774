import React, { useState, useContext, createContext, useEffect } from 'react';
import { node } from 'prop-types';
import { Ability } from '@casl/ability';
import { unpackRules } from '@casl/ability/extra';
import { createContextualCan } from '@casl/react';
import { AuthResource } from '../services/http';
import events from '../utils/events';
import {
  SUPER_ADMIN,
  CLINIC_ADMIN,
  ATTORNEY,
  PARALEGAL,
} from '../constants/roles';

const ability = new Ability([]);
export const AuthContext = createContext({
  user: null,
  login: () => {},
  logout: () => {},
  setUser: () => {},
  isLoggedIn: () => {},
  isSuperAdmin: () => {},
  isClinicAdmin: () => {},
  isAttorney: () => {},
  isParalegal: () => {},
});

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const usePermissions = () => {
  return useContext(AbilityContext);
};

const propTypes = {
  children: node.isRequired,
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [render, setRender] = useState(false);
  const isSuperAdmin = user ? user.role === SUPER_ADMIN : null;
  const isClinicAdmin = user ? user.role === CLINIC_ADMIN : null;
  const isAttorney = user ? user.role === ATTORNEY : null;
  const isParalegal = user ? user.role === PARALEGAL : null;

  const login = async credentials => {
    const { user: loggedUser, permissions } = await AuthResource.login(
      credentials,
    );
    ability.update(unpackRules(permissions));
    setUser(loggedUser);

    return loggedUser;
  };
  const logout = () => {
    AuthResource.logout();
    ability.update([]);
    setUser(null);
  };
  const isLoggedIn = () => user !== null;

  useEffect(() => {
    events.addListener('logout', logout);

    AuthResource.me()
      .then(({ permissions, user: loggedUser }) => {
        ability.update(unpackRules(permissions));
        setUser(loggedUser);
      })
      .catch(() => {})
      .finally(() => setRender(true));

    return () => events.removeListener('logout', logout);
  }, []);

  const context = {
    user,
    setUser,
    login,
    logout,
    isLoggedIn,
    isSuperAdmin,
    isClinicAdmin,
    isAttorney,
    isParalegal,
  };

  return (
    <AuthContext.Provider value={context}>
      <AbilityContext.Provider value={ability}>
        {render ? children : null}
      </AbilityContext.Provider>
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = propTypes;

export default AuthProvider;
