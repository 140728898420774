import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, InputAdornment } from '@material-ui/core';
import { MailOutline, LockOutlined } from '@material-ui/icons';
import { Formik, Form } from 'formik';
import { useAuth } from '../../../contexts/AuthContext';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import TextField from '../../../components/TextField/TextField';
import Link from '../../../components/Link/Link';
import validations from './validations';

const useStyles = makeStyles(theme => ({
  label: { fontSize: '1.2rem' },
  forgot: { marginTop: theme.spacing(4) },
}));

const LoginForm = () => {
  const classes = useStyles();
  const { login } = useAuth();
  const { showError } = useSnackBar();

  const initialValues = {
    username: '',
    password: '',
  };

  const handleSubmit = async (
    { username, password },
    { setFieldValue, setSubmitting },
  ) => {
    try {
      await login({
        username,
        password,
      });
    } catch {
      showError(
        'The email address or password is incorrect. Please, try again.',
      );
      setFieldValue('password', '', false);
      setSubmitting(false);
    }
  };

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validations}
        render={({ isSubmitting }) => (
          <Form>
            <TextField
              name="username"
              type="email"
              label="Email"
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutline />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
              fullWidth
            />
            <TextField
              name="password"
              type="password"
              label="Password"
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
              fullWidth
            />
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disabled={isSubmitting}
              fullWidth
            >
              Login
            </Button>
          </Form>
        )}
      />
      <Typography align="center" className={classes.forgot}>
        Forgot Password? <Link to="/account/forgot-password">Recover Here</Link>
      </Typography>
    </Fragment>
  );
};

export default LoginForm;
