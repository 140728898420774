import React, { useState } from 'react';
import { string, func } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { SearchOutlined, CloseOutlined } from '@material-ui/icons';
import {
  Slide,
  TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  searchContainer: {
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
  },
  search: {
    color: palette.primary.contrastText,
    '&:hover:before, &:before, &:after': {
      borderBottomColor: `${palette.primary.contrastText} !important`,
    },
  },
  svgColor: { color: palette.primary.contrastText },
}));

const propTypes = {
  searchLabel: string.isRequired,
  onSearch: func.isRequired,
};

const DefaultSearch = ({ onSearch, searchLabel }) => {
  const classes = useStyles();
  const [searchField, setSearchField] = useState('');
  const [open, setOpen] = useState(false);
  const handleKeyPress = event => {
    if (event.which === 13) onSearch(event);
  };
  const handleChangeSearchField = event => setSearchField(event.target.value);

  return (
    <div className={classes.searchContainer}>
      <Slide direction="left" in={open} mountOnEnter unmountOnExit>
        <TextField
          autoFocus
          name="search"
          value={searchField}
          onChange={handleChangeSearchField}
          placeholder={searchLabel}
          onKeyPress={handleKeyPress}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined className={classes.svgColor} />
              </InputAdornment>
            ),
            classes: { root: classes.search },
          }}
        />
      </Slide>
      <IconButton onClick={() => setOpen(!open)}>
        {open ? (
          <CloseOutlined className={classes.svgColor} />
        ) : (
          <SearchOutlined className={classes.svgColor} />
        )}
      </IconButton>
    </div>
  );
};

DefaultSearch.propTypes = propTypes;

export default DefaultSearch;
