import Resource from './base';

const baseUrl = '/auth';

const actions = client => ({
  async login(credentials) {
    const { token, user, permissions } = await client.post(
      `${baseUrl}/login`,
      credentials,
    );
    client.$setToken(token);
    return { user, permissions };
  },
  logout() {
    client.$clearToken();
  },
  async forgotPassword(username) {
    return client.post(`${baseUrl}/forgot-password`, { username });
  },
  async resetPassword({ password1, password2 }, { uid, token }) {
    return client.post(`${baseUrl}/reset-password`, {
      uid,
      token,
      password1,
      password2,
    });
  },
  async changePassword(passwords) {
    return client.post(`${baseUrl}/change-password`, passwords);
  },
  async me() {
    if (!client.$getToken()) {
      throw new Error();
    }

    return client.get(`${baseUrl}/me`);
  },
});

export default Resource(baseUrl, actions);
