import React from 'react';
import { node, object, oneOfType } from 'prop-types';

const propTypes = {
  children: node.isRequired,
  selectProps: oneOfType([object]).isRequired,
};

const ValueContainer = ({ selectProps, children }) => (
  <div className={selectProps.classes.valueContainer}>{children}</div>
);

ValueContainer.propTypes = propTypes;

export default ValueContainer;
