import React from 'react';
import { node } from 'prop-types';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useAuth } from '../../contexts/AuthContext';
import LoggedInLayout from './LoggedInLayout/LoggedInLayout';
import LoggedOutLayout from './LoggedOutLayout/LoggedOutLayout';

const propTypes = {
  children: node.isRequired,
};

const Layout = ({ children }) => {
  const { isLoggedIn, user } = useAuth();
  const getUserTheme = theme => {
    if (
      !isLoggedIn() ||
      !user.customer ||
      !user.customer.theme ||
      !user.customer.logo
    ) {
      return theme;
    }

    try {
      return createMuiTheme({
        ...theme,
        palette: {
          ...theme.palette,
          primary: {
            main: user.customer.theme.primary,
          },
          secondary: {
            main: user.customer.theme.secondary,
          },
        },
        logo: {
          image: user.customer.logo,
          alt: user.customer.name,
        },
      });
    } catch {
      return theme;
    }
  };
  const Component = isLoggedIn() ? LoggedInLayout : LoggedOutLayout;

  return (
    <ThemeProvider theme={getUserTheme}>
      <Component>{children}</Component>
    </ThemeProvider>
  );
};

Layout.propTypes = propTypes;

export default Layout;
