import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AdapterLink from './Adapter';

const useStyles = makeStyles(() => ({
  root: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const ButtonLink = ({ ...props }) => {
  const classes = useStyles();

  return <Button component={AdapterLink} classes={classes} {...props} />;
};

export default ButtonLink;
