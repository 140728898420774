import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import AsyncSelect from 'react-select/async';
import red from '@material-ui/core/colors/red';
import useTheme from '@material-ui/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import components from './components';

const useStyles = makeStyles(({ spacing, zIndex }) => ({
  input: {
    padding: 0,
    height: 'auto',
    display: 'flex',
  },
  valueContainer: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    alignItems: 'center',
    padding: spacing(1.8, 0, 1.8, 1.5),
  },
  menuOverlay: {
    zIndex: zIndex.tooltip + 1,
    position: 'absolute',
    cursor: 'auto',
    right: 0,
    left: 0,
  },
  menuCover: {
    position: 'fixed',
    cursor: 'auto',
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
  },
}));

const propTypes = {
  multi: bool,
  label: string,
  disabled: bool,
  onChange: func,
  placeholder: string,
  name: string.isRequired,
  loadOptions: func.isRequired,
};

const defaultProps = {
  label: '',
  multi: false,
  disabled: false,
  placeholder: '',
  onChange: () => {},
};

const IntegrationReactSelect = ({
  name,
  multi,
  label,
  disabled,
  onChange,
  placeholder,
  loadOptions,
}) => {
  const classes = useStyles();
  const { zIndex } = useTheme();
  const [firstWriting, setFirstWriting] = useState(true);
  const message = () => (firstWriting ? 'Start typing…' : 'No Results');

  const customStyles = {
    indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: () => ({ display: 'none' }),
    menuPortal: base => ({ ...base, zIndex: zIndex.tooltip }),
    clearIndicator: (base, { isFocused }) => ({
      ...base,
      color: isFocused ? red[600] : red[200],
      cursor: 'pointer',
    }),
  };

  return (
    <Field
      name={name}
      render={({ field, form: { setFieldValue, touched, errors } }) => {
        const textFieldProps = {
          label,
          disabled,
          variant: 'outlined',
          InputLabelProps: { shrink: true },
          helperText: <ErrorMessage name={name} />,
          error: Boolean(touched[field.name] && errors[field.name]),
        };
        const defaultValue =
          field.value && field.value !== '' ? field.value : null;

        const handleChange = option => {
          onChange(option);
          setFieldValue(field.name, option || '');
        };

        return (
          <AsyncSelect
            isClearable
            isMulti={multi}
            classes={classes}
            value={field.value}
            styles={customStyles}
            isDisabled={disabled}
            onChange={handleChange}
            components={components}
            placeholder={placeholder}
            loadOptions={loadOptions}
            noOptionsMessage={message}
            defaultValue={defaultValue}
            TextFieldProps={textFieldProps}
            menuPortalTarget={document.body}
            onMenuClose={() => setFirstWriting(true)}
            onInputChange={() => setFirstWriting(false)}
          />
        );
      }}
    />
  );
};

IntegrationReactSelect.propTypes = propTypes;
IntegrationReactSelect.defaultProps = defaultProps;

export default IntegrationReactSelect;
