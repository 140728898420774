import { object, string, number } from 'yup';

export default object().shape({
  role: string().label('Role'),
  firstName: string()
    .required()
    .label('First name'),
  lastName: string()
    .required()
    .label('Last name'),
  phone: string()
    .test('phone', value =>
      value ? /^[+\d]?(?:[\d-.\s()]*)$/.test(value) : true,
    )
    .label('Phone number'),
  email: string()
    .email()
    .required()
    .label('Email address'),
  notifications: string()
    .required()
    .label('Email notifications'),
  city: string()
    .test('city', value => /^[a-zA-Z\s]*$/.test(value))
    .label('City'),
  zipCode: number()
    .test('zipCode', value =>
      value ? /^\d{5}(-\d{4})?(?!-)$/.test(value) : true,
    )
    .label('Zip code'),
});
