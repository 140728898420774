import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Divider, Paper } from '@material-ui/core';
import states from '../../constants/states';
import Card from '../../components/Card/Card';
import { useAuth } from '../../contexts/AuthContext';
import ProfileForm from './ProfileForm/ProfileForm';
import ChangePasswordForm from './ChangePasswordForm/ChangePasswordForm';

const useDividerStyles = makeStyles(({ spacing }) => ({
  root: { margin: spacing(3, 0) },
}));

const useMuiCardStyles = makeStyles(({ spacing }) => ({
  root: {
    borderWidth: 1,
    padding: spacing(3),
    borderStyle: 'solid',
    borderColor: '#F0F0F0',
    marginBottom: spacing(2.5),
    backgroundColor: '#F9F9F9',
  },
}));

const ProfilePage = () => {
  const dividerClasses = useDividerStyles();
  const muiCardClasses = useMuiCardStyles();
  const {
    isAttorney,
    user: { firm },
  } = useAuth();

  return (
    <Card title="Profile" useBack>
      {isAttorney && (
        <>
          <Typography variant="h6" paragraph>
            Firm Information
          </Typography>
          <Paper elevation={0} classes={muiCardClasses}>
            <Typography variant="h6">{firm.name}</Typography>
            <Typography>
              {firm.street}, {firm.city},{' '}
              {states.find(v => v.value === firm.state).label}, {firm.zipCode}
            </Typography>
          </Paper>
        </>
      )}
      <Typography variant="h6" paragraph>
        Personal Information
      </Typography>
      <ProfileForm />
      <Divider classes={dividerClasses} />
      <Typography variant="h6" paragraph>
        Change Password
      </Typography>
      <ChangePasswordForm />
    </Card>
  );
};

export default ProfilePage;
