import React, { useState, useEffect } from 'react';
import { Create as EditIcon } from '@material-ui/icons';
import {
  TablePagination,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Hidden,
  makeStyles,
} from '@material-ui/core';
import { CustomerResource } from '../../services/http';
import { useSnackBar } from '../../contexts/SnackBarContext';
import CustomerForm from './CustomerForm/CustomerForm';
import StatusSwitch from './StatusSwitch/StatusSwitch';
import DataTable from '../../components/DataTable/DataTable';
import Fab from '../../components/Fab/Fab';
import useModal from '../../hooks/modal';
import useDataTable from '../../hooks/data-table';
import usePagination from '../../hooks/pagination-table';

const useTableCellStyles = makeStyles({
  root: { width: '50%' },
});

const CustomerPage = () => {
  const { showError } = useSnackBar();
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useDataTable();
  const [searchParam, setSearchParam] = useState();
  const { isModalOpen, modalTitle, showModal, hideModal } = useModal();
  const { total, setTotal, limit, setLimit, page, setPage } = usePagination();
  const tableCellClasses = useTableCellStyles();

  const handleCreate = () => {
    setFormData();
    showModal('Add new customer');
  };

  const handleEdit = customer => {
    setFormData(customer);
    showModal(`Edit customer: ${customer.name}`);
  };

  const handleClose = (e, eventType, customer) => {
    if (eventType === 'submitClick') {
      setCustomers(customer);
    }

    hideModal();
  };

  const handleSearch = e => setSearchParam(e.target.value);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { results, count } = await CustomerResource.getAll({
          name: searchParam,
          page: page + 1,
          limit,
        });
        setTotal(count);
        setCustomers(results);
      } catch {
        showError('An error occurs while loading the data table.');
      }
      setLoading(false);
    };

    fetchData();
  }, [searchParam, setCustomers, showError, setLoading, page, limit, setTotal]);

  return (
    <>
      <DataTable
        title="Customers"
        searchLabel="Search by name"
        data={customers}
        loading={loading}
        onSearch={handleSearch}
        renderColums={() => (
          <TableRow>
            <TableCell classes={tableCellClasses}>Name</TableCell>
            <Hidden mdDown>
              <TableCell>Fax</TableCell>
            </Hidden>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableRow>
        )}
        renderRows={item => (
          <TableRow key={item.id}>
            <TableCell classes={tableCellClasses}>{item.name}</TableCell>
            <Hidden mdDown>
              <TableCell>{item.fax}</TableCell>
            </Hidden>
            <TableCell>
              <StatusSwitch enable={item.isActive} id={item.id} />
            </TableCell>
            <TableCell align="right">
              <Tooltip title="Edit">
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => handleEdit(item)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        )}
        renderPagination={() => (
          <TablePagination
            page={page}
            component="div"
            count={total}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
            onChangePage={(e, newPage) => setPage(newPage)}
            onChangeRowsPerPage={e => setLimit(e.target.value)}
          />
        )}
      />
      <Fab color="secondary" onClick={handleCreate} />
      <CustomerForm
        title={modalTitle}
        open={isModalOpen}
        onClose={handleClose}
        data={formData}
      />
    </>
  );
};

export default CustomerPage;
