import React from 'react';
import { element, object, oneOfType } from 'prop-types';
import Paper from '@material-ui/core/Paper';

const propTypes = {
  children: element.isRequired,
  innerProps: oneOfType([object]).isRequired,
  selectProps: oneOfType([object]).isRequired,
};

const Menu = ({ children, innerProps, selectProps }) => {
  return (
    <div className={selectProps.classes.menuOverlay}>
      <div role="presentation" className={selectProps.classes.menuCover} />
      <Paper square {...innerProps}>
        {children}
      </Paper>
    </div>
  );
};

Menu.propTypes = propTypes;

export default Menu;
