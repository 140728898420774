import React, { Fragment } from 'react';
import { shape } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { AuthResource } from '../../../services/http';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import TextField from '../../../components/TextField/TextField';
import Link from '../../../components/Link/Link';
import validations from './validations';

const useStyles = makeStyles(theme => ({
  login: { marginTop: theme.spacing(4) },
}));

const propTypes = {
  history: shape({}).isRequired,
  match: shape({}).isRequired,
};

const ForgotPasswordForm = ({ history, match }) => {
  const classes = useStyles();
  const { showSuccess, showError } = useSnackBar();

  const { uid, token } = match.params;

  const initialValues = {
    password1: '',
    password2: '',
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await AuthResource.resetPassword(values, { uid, token });
      showSuccess('Password was successfully updated.');
      history.push('/account/login');
    } catch {
      showError('Sorry, an error occurred. Please try again later.');
      setSubmitting(false);
    }
  };

  return (
    <Fragment>
      <Typography paragraph align="center">
        Enter your new password below. Once changed, your new password will be
        in effect the next time you log in.
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validations}
        render={({ isSubmitting }) => (
          <Form>
            <TextField
              name="password1"
              type="password"
              label="Password"
              margin="normal"
              variant="outlined"
              fullWidth
            />
            <TextField
              name="password2"
              type="password"
              label="Confirm Password"
              margin="normal"
              variant="outlined"
              fullWidth
            />
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disabled={isSubmitting}
              fullWidth
            >
              Change Password
            </Button>
          </Form>
        )}
      />
      <Typography align="center" className={classes.login}>
        Back to <Link to="/account/login">Login</Link>
      </Typography>
    </Fragment>
  );
};

ForgotPasswordForm.propTypes = propTypes;

export default ForgotPasswordForm;
