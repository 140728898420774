import Resource from './base';

const baseUrl = '/providers';

const actions = client => ({
  getImage(id) {
    return `${
      client.defaults.baseURL
    }${baseUrl}/${id}/image/?access_token=${client.$getToken()}`;
  },
});

export default Resource(baseUrl, actions);
