import { object, string } from 'yup';

export default object().shape({
  customerId: object()
    .when('haveCustomer', {
      is: true,
      then: object().required(),
    })
    .label('Customer'),
  firstName: string()
    .required()
    .label('First Name'),
  lastName: string()
    .required()
    .label('Last Name'),
  avatar: string()
    .required()
    .label('Avatar'),
  address: string()
    .trim()
    .required()
    .label('Address'),
  city: string()
    .test('city', value => /^[a-zA-Z\s]*$/.test(value))
    .label('City'),
  state: string()
    .required()
    .label('State'),
  residency: string().label('Residency'),
  medicalSchool: string().label('Medical School'),
  specialty: string()
    .matches(/^[a-zA-Z0-9#,\s]*$/, {
      message: 'Invalid format',
      excludeEmptyString: true,
    })
    .required()
    .label('Specialty'),
  boardCertification: string()
    .matches(/^[a-zA-Z0-9#,\s]*$/, {
      message: 'Invalid format',
      excludeEmptyString: true,
    })
    .label('Board Certification'),
  hospitalAffiliation: string()
    .matches(/^[a-zA-Z0-9#,\s]*$/, {
      message: 'Invalid format',
      excludeEmptyString: true,
    })
    .label('Hospital Affiliation'),
  professionalOrganizations: string()
    .matches(/^[a-zA-Z0-9#,\s]*$/, {
      message: 'Invalid format',
      excludeEmptyString: true,
    })
    .label('Professional Organizations'),
});
