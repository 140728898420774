import React from 'react';
import moment from 'moment';
import grey from '@material-ui/core/colors/grey';
import { Grid, Paper, Button, Typography, makeStyles } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import StatusIcon from '../../../../components/StatusIcon/StatusIcon';
import { Can } from '../../../../contexts/AuthContext';
import {
  statusLabel,
  statusColor,
} from '../../../../constants/patientAccounts';
import PaymentsTable from './PaymentsTable/PaymentsTable';
import { usePADetailContext } from '../PatientAccountDetailContext';

const useStyles = makeStyles(({ spacing }) => ({
  billedContainer: {
    backgroundColor: grey[200],
    padding: spacing(2),
  },
  billedBox: {
    padding: spacing(2, 1),
    marginBottom: spacing(1.5),
    marginTop: spacing(1.5),
  },
  billedBoxChild: {
    padding: spacing(0, 1.5, 0, 1.5),
  },
  paymentTable: { margin: spacing(1.5, 0) },
  icon: {
    marginRight: '6px',
  },
}));

const Payment = () => {
  const classes = useStyles();
  const {
    payments,
    handleCreatePayment,
    showAllPaymentsModal,
    paAccount: { status, totalBilled, totalPayment, currentBalance },
  } = usePADetailContext();
  const asOfPayment = payments[0] ? payments[0].createdAt : null;

  return (
    <Paper elevation={0} className={classes.billedContainer}>
      <Grid container alignItems="center" justify="space-between">
        <Typography variant="h6">Total Billed</Typography>
        <Can I="create" a="Payment">
          <Button size="small" color="primary" onClick={handleCreatePayment}>
            <AddCircleOutlineIcon fontSize="small" className={classes.icon} />
            Add new payment
          </Button>
        </Can>
      </Grid>

      <Paper elevation={0} className={classes.billedBox}>
        <Grid
          container
          alignItems="center"
          justify="space-between"
          className={classes.billedBoxChild}
        >
          <Typography>
            ${parseFloat(Number(totalBilled).toFixed(2)).toLocaleString()}
          </Typography>
          <StatusIcon
            square
            label={statusLabel[status]}
            color={statusColor[status]}
          />
        </Grid>
      </Paper>

      <Grid container alignItems="center" justify="space-between">
        <Typography variant="h6">Payment Received</Typography>
        <Button size="small" color="primary" onClick={showAllPaymentsModal}>
          View all payments
        </Button>
      </Grid>

      <Paper elevation={0} className={classes.paymentTable}>
        <PaymentsTable data={payments.slice(0, 3)} />
      </Paper>
      <Grid container justify="space-around" direction="column">
        <Typography variant="body2" gutterBottom>
          <strong>Total Billed: </strong>$
          {parseFloat(Number(totalBilled).toFixed(2)).toLocaleString()}
        </Typography>
        <Typography variant="body2" gutterBottom>
          <strong>Total Payments: </strong>$
          {parseFloat(Number(totalPayment).toFixed(2)).toLocaleString()}
        </Typography>
        {asOfPayment && (
          <Typography variant="body2">
            <strong>Current Balance: </strong>$
            {parseFloat(Number(currentBalance).toFixed(2)).toLocaleString()} as
            of {moment(asOfPayment).format('MMM DD, YYYY')}
          </Typography>
        )}
      </Grid>
    </Paper>
  );
};

export default Payment;
