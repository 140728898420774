import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, InputAdornment } from '@material-ui/core';
import { MailOutline } from '@material-ui/icons';
import { Formik, Form } from 'formik';
import { AuthResource } from '../../../services/http';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import TextField from '../../../components/TextField/TextField';
import Link from '../../../components/Link/Link';
import validations from './validations';

const useStyles = makeStyles(theme => ({
  label: { fontSize: '1.2rem' },
  login: { marginTop: theme.spacing(4) },
}));

const ForgotPasswordForm = () => {
  const classes = useStyles();
  const { showSuccess, showError } = useSnackBar();

  const initialValues = { username: '' };

  const handleSubmit = async (
    { username },
    { setFieldValue, setSubmitting },
  ) => {
    try {
      await AuthResource.forgotPassword(username);
      showSuccess(
        "If the email exists in our system, we've sent a password reset link to it.",
      );
      setFieldValue('username', '', false);
    } catch {
      showError('Sorry, an error occurred. Please try again later.');
    }

    setSubmitting(false);
  };

  return (
    <Fragment>
      <Typography paragraph align="center">
        Please enter your user email, and we&apos;ll send you an email with
        instructions on creating a new password.
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validations}
        render={({ isSubmitting }) => (
          <Form>
            <TextField
              name="username"
              type="email"
              label="Email"
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutline />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
              fullWidth
            />
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disabled={isSubmitting}
              fullWidth
            >
              Reset Password
            </Button>
          </Form>
        )}
      />
      <Typography align="center" className={classes.login}>
        Back to <Link to="/account/login">Login</Link>
      </Typography>
    </Fragment>
  );
};

export default ForgotPasswordForm;
