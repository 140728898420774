import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import {
  Divider,
  TextField,
  IconButton,
  TablePagination,
} from '@material-ui/core';
import ProviderBio from './ProviderBio/ProviderBio';
import FindAProviderForm from './FindAProviderForm/FindAProviderForm';
import Card from '../../components/Card/Card';
import NoResults from '../../components/DataTable/NoResults';
import Fab from '../../components/Fab/Fab';
import useModal from '../../hooks/modal';
import useDataTable from '../../hooks/data-table';
import usePagination from '../../hooks/pagination-table';
import { useSnackBar } from '../../contexts/SnackBarContext';
import { ProvidersResource } from '../../services/http';
import { Can } from '../../contexts/AuthContext';

const useStyles = makeStyles(({ palette, spacing }) => ({
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  search: {
    color: palette.primary.contrastText,
    '&:hover:before, &:before, &:after': {
      borderBottomColor: `${palette.primary.contrastText} !important`,
    },
  },
  textField: { marginLeft: spacing(1) },
  customSearchFieldsContainer: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const FindAProviderPage = () => {
  const initialValues = {
    name: '',
    clinic: '',
    specialty: '',
  };
  const classes = useStyles();
  const { showError } = useSnackBar();
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useDataTable();
  const [searchParams, setSearchParams] = useState({});
  const { isModalOpen, modalTitle, showModal, hideModal } = useModal();
  const { total, setTotal, limit, setLimit, page, setPage } = usePagination();
  const handleSearch = fields => setSearchParams(fields);
  const handleCreate = () => {
    setFormData();
    showModal('Add new provider');
  };
  const handleClose = (e, eventType, location) => {
    if (eventType === 'submitClick') {
      setProviders(location);
    }

    hideModal();
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { results, count } = await ProvidersResource.getAll({
          ...searchParams,
          page: page + 1,
          limit,
        });
        setTotal(count);
        setProviders(results);
      } catch {
        showError('An error occurs while loading the data table.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    searchParams,
    setProviders,
    showError,
    setLoading,
    page,
    limit,
    setTotal,
  ]);

  const CustomField = fieldProps => {
    const { name, placeholder } = fieldProps;

    return (
      <Field
        name={name}
        render={({ field }) => (
          <TextField
            placeholder={placeholder}
            className={classes.textField}
            InputProps={{
              classes: { root: classes.search },
            }}
            {...field}
          />
        )}
      />
    );
  };

  const customSearch = () => (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSearch}
      render={() => (
        <Form className={classes.customSearchFieldsContainer}>
          <CustomField name="name" placeholder="Search by name" />
          <CustomField name="specialty" placeholder="Search by specialty" />
          <CustomField name="clinic" placeholder="Search by clinic" />
          <IconButton type="submit" className={classes.search}>
            <SearchOutlined />
          </IconButton>
        </Form>
      )}
    />
  );

  return (
    <>
      <Card
        noPadding
        loading={loading}
        title="Find a Provider"
        renderAction={customSearch}
      >
        {!loading &&
          (providers.length > 0 ? (
            <>
              {providers.map(provider => (
                <Link
                  key={provider.id}
                  className={classes.link}
                  to={`/find-a-provider/${provider.id}`}
                >
                  <ProviderBio
                    {...provider}
                    title={`${provider.firstName} ${provider.lastName}`}
                  />
                  <Divider />
                </Link>
              ))}
              <TablePagination
                page={page}
                count={total}
                component="div"
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
                onChangePage={(e, newPage) => setPage(newPage)}
                onChangeRowsPerPage={e => setLimit(e.target.value)}
              />
            </>
          ) : (
            <NoResults />
          ))}
      </Card>
      <Can I="create" a="Provider">
        <Fab color="secondary" onClick={handleCreate} />
      </Can>
      <FindAProviderForm
        title={modalTitle}
        open={isModalOpen}
        onClose={handleClose}
        data={formData}
      />
    </>
  );
};

export default FindAProviderPage;
