import React, { useState } from 'react';
import { shape, func, string, arrayOf } from 'prop-types';
import moment from 'moment';
import {
  Grid,
  Table,
  Button,
  Hidden,
  Tooltip,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import {
  Create as EditIcon,
  Delete as DeleteIcon,
  GetApp as DownloadIcon,
  AddCircleOutline as AddCircleOutlineIcon,
} from '@material-ui/icons';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import { Can } from '../../../../contexts/AuthContext';

const propTypes = {
  title: string.isRequired,
  subject: string.isRequired,
  data: arrayOf(shape({})).isRequired,
  onCreate: func.isRequired,
  onEdit: func.isRequired,
  onDelete: func.isRequired,
  onDownload: func.isRequired,
};

const useStyles = makeStyles(() => ({
  icon: {
    marginRight: '6px',
  },
  button: {
    marginLeft: '12px',
  },
}));

const BillingStatement = ({
  title,
  subject,
  data,
  onCreate,
  onEdit,
  onDelete,
  onDownload,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [element, setElement] = useState({});
  const handleOpenDialog = item => {
    setElement(item);
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setElement({});
    setOpen(false);
  };
  const handleConfirmDialog = () => {
    onDelete(element);
    handleCloseDialog();
  };
  return (
    <>
      <Grid container alignItems="center" justify="flex-start">
        <Typography variant="h6">
          {title}({data.length})
        </Typography>
        <Can I="create" a={subject}>
          <Button
            size="small"
            color="primary"
            onClick={onCreate}
            className={classes.button}
          >
            <AddCircleOutlineIcon fontSize="small" className={classes.icon} />
            Add new
          </Button>
        </Can>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <Hidden xsDown>
              <TableCell>
                <strong>Date</strong>
              </TableCell>
            </Hidden>
            <TableCell>
              <strong>Title</strong>
            </TableCell>
            <TableCell>
              <strong>User</strong>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            data.map(item => (
              <TableRow key={item.id}>
                <Hidden xsDown>
                  <TableCell>
                    {moment(item.createdAt).format('MMM DD, YYYY')}
                  </TableCell>
                </Hidden>
                <TableCell>{item.title}</TableCell>
                <TableCell>{item.creator ? item.creator.name : '-'}</TableCell>
                <TableCell size="small" align="right">
                  <Can I="update" a={subject}>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => onEdit(item)}
                    >
                      <Tooltip title="Edit">
                        <EditIcon />
                      </Tooltip>
                    </IconButton>
                  </Can>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => onDownload(item)}
                  >
                    <Tooltip title="Download">
                      <DownloadIcon />
                    </Tooltip>
                  </IconButton>
                  <Can I="delete" a={subject}>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => handleOpenDialog(item)}
                    >
                      <Tooltip title="Delete">
                        <DeleteIcon />
                      </Tooltip>
                    </IconButton>
                  </Can>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>No data</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <ModalDialog
        fullWidth
        open={open}
        maxWidth="xs"
        submitLabel="Delete"
        title="Confirm Delete"
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
        subtitle="Are you sure you want to delete this item?"
      />
    </>
  );
};

BillingStatement.propTypes = propTypes;

export default BillingStatement;
