import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import CssBaseline from '@material-ui/core/CssBaseline';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import UserPage from '../../pages/User/User';
import FirmsPage from '../../pages/Firms/Firms';
import LocationsPage from '../../pages/Locations/Locations';
import MessagesPage from '../../pages/Messages/Messages';
import AccountPage from '../../pages/Account/Account';
import CustomerPage from '../../pages/Customers/Customers';
import ProfilePage from '../../pages/Profile/Profile';
import PatientAccountPage from '../../pages/PatientAccount/PatientAccount';
import DashBoardPage from '../../pages/DashBoard/DashBoard';
import ActivityLogPage from '../../pages/ActivityLog/ActivityLog';
import ClinicsPage from '../../pages/Clinics/Clinics';
import CompanyDirectoryPage from '../../pages/CompanyDirectory/CompanyDirectory';
import NoMatchPage from '../../pages/404';
import GlobalState from '../GlobalState/GlobalState';
import Layout from '../Layout/Layout';
import FindAProviderPage from '../../pages/FindAProvider/FindAProvider';
import ProviderDetailPage from '../../pages/FindAProvider/ProviderDetailPage/ProviderDetailPage';

const App = () => (
  <GlobalState>
    <CssBaseline />
    <Router>
      <Layout>
        <Switch>
          <PrivateRoute exact path="/" component={DashBoardPage} />
          <PrivateRoute exact path="/users" component={UserPage} />
          <PrivateRoute exact path="/profile" component={ProfilePage} />
          <PrivateRoute exact path="/firms" component={FirmsPage} />
          <PrivateRoute exact path="/customers" component={CustomerPage} />
          <PrivateRoute exact path="/clinics" component={ClinicsPage} />
          <PrivateRoute exact path="/locations" component={LocationsPage} />
          <PrivateRoute exact path="/messages" component={MessagesPage} />
          <PrivateRoute
            exact
            path="/find-a-provider"
            component={FindAProviderPage}
          />
          <PrivateRoute
            exact
            path="/find-a-provider/:id"
            component={ProviderDetailPage}
          />
          <PrivateRoute
            exact
            path="/activity-log"
            component={ActivityLogPage}
          />
          <PrivateRoute
            path="/patient-account"
            component={PatientAccountPage}
          />
          <Route
            exact
            path="/company-directory"
            component={CompanyDirectoryPage}
          />
          <Route path="/account" component={AccountPage} />
          <Route component={NoMatchPage} />
        </Switch>
      </Layout>
    </Router>
  </GlobalState>
);

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
