import React, { useState } from 'react';
import { func, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Menu,
  Toolbar,
  MenuItem,
  Typography,
  IconButton,
  AppBar as MuiAppBar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import {
  Menu as MenuIcon,
  AccountCircle,
  KeyboardArrowDown,
} from '@material-ui/icons';
import { useAuth } from '../../../contexts/AuthContext';

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(1)}px`,
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(2)}px`,
    },
  },
  appBarMenu: {
    display: 'flex',
    alignItems: 'center',
  },
  accountName: {
    marginLeft: 7,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  menuButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  brand: {
    width: 250,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 180,
    },
  },
  appBarLogo: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'fill',
    display: 'block',
  },
}));

const propTypes = {
  toggleHandler: func.isRequired,
  history: shape({}).isRequired,
};

const AppBar = ({ toggleHandler, history }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { user, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    history.push('/profile');
    handleClose();
  };

  const handleLogout = () => {
    history.push('/account/login');
    handleClose();
    logout();
  };

  return (
    <MuiAppBar color="default" position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.appBarMenu}>
          <IconButton
            color="inherit"
            edge="start"
            aria-label="Open drawer"
            onClick={toggleHandler}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.brand}>
            <img
              alt={theme.logo.alt}
              src={theme.logo.image}
              className={classes.appBarLogo}
            />
          </div>
        </div>

        <div className={classes.appBarMenu}>
          <AccountCircle fontSize="large" color="primary" />
          <Typography noWrap variant="body1" className={classes.accountName}>
            {user.name}
          </Typography>
          <IconButton
            aria-label="Account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <KeyboardArrowDown />
          </IconButton>
        </div>

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleProfile}>Profile</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </MuiAppBar>
  );
};

AppBar.propTypes = propTypes;

export default withRouter(AppBar);
