import React from 'react';
import Grid from '@material-ui/core/Grid';
import { func, shape, bool, string } from 'prop-types';
import TextField from '../../../components/TextField/TextField';
import ModalForm from '../../../components/ModalForm/ModalForm';
import SelectField from '../../../components/SelectField/SelectField';
import AutoCompleteSelectField from '../../../components/AutoCompleteSelectField/AutoCompleteSelectField';
import { Can, usePermissions } from '../../../contexts/AuthContext';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import states from '../../../constants/states';
import validations from './validations';
import {
  ClinicResource,
  CustomerResource,
  LocationResource,
} from '../../../services/http';

const propTypes = {
  title: string.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  data: shape({}),
};

const defaultProps = {
  data: {},
};

const ClinicsForm = ({ title, open, onClose, data }) => {
  const permissions = usePermissions();
  const initialValues = {
    name: data.name || '',
    city: data.city || '',
    state: data.state || '',
    street: data.street || '',
    zipCode: data.zipCode || '',
    locationId: data.location
      ? { label: data.location.name, value: data.location.id }
      : '',
    customerId: data.customer
      ? { label: data.customer.name, value: data.customer.id }
      : '',
    haveCustomer: permissions.can('create', 'Clinic', 'customerId'),
  };
  const { showSuccess, showError } = useSnackBar();
  const handleSubmit = async (
    { haveCustomer, customerId, locationId, ...values },
    { setSubmitting },
  ) => {
    try {
      const location = await ClinicResource.save({
        ...values,
        ...(data.id && { id: data.id }),
        ...(customerId && { customerId: customerId.value }),
        locationId: locationId.value,
      });

      onClose(null, 'submitClick', location);
      showSuccess(`Successfully ${data.id ? 'updated' : 'added'} Clinic`);
    } catch ({ message }) {
      showError(message || 'An error occurred, please try again');
    } finally {
      setSubmitting(false);
    }
  };
  const fetchCustomerOptions = async name => {
    try {
      const { results } = await CustomerResource.getAll({ name });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };
  const fetchLocationOptions = async (name, customerId) => {
    try {
      const { results } = await LocationResource.getAll({
        name,
        limit: 5,
        customerId,
      });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };

  return (
    <ModalForm
      open={open}
      title={title}
      onClose={onClose}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validations}
      submitLabel={data.id ? 'Save' : 'Create'}
      render={({ setFieldValue, values: { customerId, haveCustomer } }) => (
        <Grid container spacing={2}>
          <Can I="create customerId" a="Clinic">
            <Grid item xs={12}>
              <AutoCompleteSelectField
                name="customerId"
                label="Customer"
                loadOptions={fetchCustomerOptions}
                onChange={() => setFieldValue('locationId', '')}
              />
            </Grid>
          </Can>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="name"
              type="text"
              label="Clinic Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AutoCompleteSelectField
              name="locationId"
              label="Location"
              disabled={!customerId && haveCustomer}
              loadOptions={n => fetchLocationOptions(n, customerId.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="street"
              type="text"
              label="Street"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="city"
              type="text"
              label="City"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectField
              fullWidth
              name="state"
              label="State"
              variant="outlined"
              options={states}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="zipCode"
              type="number"
              label="ZIP Code"
              variant="outlined"
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

ClinicsForm.propTypes = propTypes;
ClinicsForm.defaultProps = defaultProps;

export default ClinicsForm;
