import React from 'react';
import moment from 'moment';
import { Button, Typography, makeStyles } from '@material-ui/core';
import MessageModal from '../../../Messages/MessageModal/MessageModal';
import { useAuth } from '../../../../contexts/AuthContext';
import useModal from '../../../../hooks/modal';
import { usePADetailContext } from '../PatientAccountDetailContext';

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: spacing(1.5),
    height: '100%',
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '50%',
  },
}));

const BasicInfo = () => {
  const classes = useStyles();
  const { paAccount } = usePADetailContext();
  const { isAttorney, isParalegal } = useAuth();
  const { isModalOpen, showModal, hideModal } = useModal();
  const messageModalData = {
    patientAccountId: {
      label: `#${paAccount.accountNumber} - ${paAccount.patient.firstName} ${paAccount.patient.lastName}`,
      value: paAccount.id,
    },
  };

  return (
    <div className={classes.container}>
      <div className={classes.dataContainer}>
        <Typography gutterBottom>
          <strong>Clinic:</strong> {paAccount.clinic.name}
        </Typography>
        <Typography gutterBottom>
          <strong>Location:</strong>{' '}
          {paAccount.clinic.location ? paAccount.clinic.location.name : '-'}
        </Typography>
        <Typography gutterBottom>
          <strong>Firm:</strong> {paAccount.firm.name}
        </Typography>
        <Typography gutterBottom>
          <strong>Attorneys:</strong>{' '}
          {paAccount.attorneys.map(a => a.name).join(', ')}
        </Typography>
        <Typography gutterBottom>
          <strong>Date of Accident:</strong>{' '}
          {moment.utc(paAccount.dateOfAccident).format('MMM DD, YYYY')}
        </Typography>
      </div>
      <div>
        <Button
          fullWidth
          color="primary"
          onClick={showModal}
          variant="outlined"
        >
          Make a question about
          <br />
          this {isAttorney || isParalegal ? 'client' : 'patient'}
        </Button>
      </div>
      <MessageModal
        open={isModalOpen}
        onClose={hideModal}
        data={messageModalData}
      />
    </div>
  );
};

export default BasicInfo;
