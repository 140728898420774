import React from 'react';
import { Formik, Form } from 'formik';
import { Grid, Button } from '@material-ui/core';
import { UserResource } from '../../../services/http';
import { useAuth } from '../../../contexts/AuthContext';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import TextField from '../../../components/TextField/TextField';
import SelectField from '../../../components/SelectField/SelectField';
import { NOTIFICATIONS_LIST } from '../../../constants/email-notifications';
import { ROLE_LIST } from '../../../constants/roles';
import states from '../../../constants/states';
import validations from './validations';

const ProfileForm = () => {
  const { user, setUser } = useAuth();
  const { showSuccess, showError } = useSnackBar();
  const initialValues = {
    firstName: user.firstName,
    lastName: user.lastName || '',
    email: user.email,
    role: user.role,
    notifications: user.notifications,
    city: user.city || '',
    phone: user.phone || '',
    state: user.state || '',
    street: user.street || '',
    zipCode: user.zipCode || '',
  };
  const handleSubmit = async (
    { role, state, ...fields },
    { setSubmitting },
  ) => {
    try {
      const response = await UserResource.save({
        id: user.id,
        ...fields,
        ...(state && { state }),
      });

      setUser(response);
      showSuccess('Profile was successfully updated.');
    } catch {
      showError('An error occurs, please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validations}
      onSubmit={handleSubmit}
      render={({ isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                name="firstName"
                type="text"
                label="First name"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                name="lastName"
                type="text"
                label="Last name"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectField
                disabled
                fullWidth
                name="role"
                label="Role"
                variant="outlined"
                options={ROLE_LIST}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                name="phone"
                type="tel"
                label="Phone Number"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                name="email"
                type="email"
                label="Email Address"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectField
                fullWidth
                name="notifications"
                label="Email Notifications"
                variant="outlined"
                options={NOTIFICATIONS_LIST}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <SelectField
                fullWidth
                name="state"
                label="State"
                variant="outlined"
                options={states}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                name="city"
                type="text"
                label="City"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                name="street"
                type="text"
                label="Street"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                name="zipCode"
                type="number"
                label="ZIP Code"
                variant="outlined"
              />
            </Grid>
            <Grid container item justify="flex-end" xs={12}>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                Save Profile
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    />
  );
};

export default ProfileForm;
