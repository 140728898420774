import axiosClient from '../client';

const createBaseResource = client => (baseUrl, actions = () => {}) => {
  const get = id => {
    return client.get(`${baseUrl}/${id}`);
  };

  const getAll = (params = {}) => {
    return client.get(baseUrl, { params });
  };

  const handleSave = (data, headers = {}, options = {}) => {
    const id = data.get && data.get('id') ? data.get('id') : data.id;
    const method = id ? 'put' : 'post';
    const url = id ? `${baseUrl}/${id}` : baseUrl;

    return client.request({
      method,
      url,
      data,
      headers,
      ...options,
    });
  };

  const save = data => {
    return handleSave(data);
  };

  const saveMultipart = data => {
    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));

    return handleSave(
      formData,
      { 'Content-Type': 'multipart/form-data;' },
      { timeout: 0 },
    );
  };

  const bulkCreate = items => {
    return client.post(baseUrl, { items });
  };

  const bulkUpdate = items => {
    return client.patch(baseUrl, { items });
  };

  const remove = id => {
    return client.delete(`${baseUrl}/${id}`);
  };

  const resource = {
    get,
    getAll,
    save,
    saveMultipart,
    bulkCreate,
    bulkUpdate,
    remove,
  };

  return Object.assign(resource, actions(client));
};

export default createBaseResource(axiosClient);
