import React from 'react';
import { string, func } from 'prop-types';
import Dropzone from 'react-dropzone';
import { Field, ErrorMessage } from 'formik';
import {
  FormControl,
  FormHelperText,
  IconButton,
  Typography,
  Tooltip,
} from '@material-ui/core';
import {
  Clear,
  CloudUpload,
  InsertDriveFile,
  VerticalAlignBottom,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useSnackBar } from '../../contexts/SnackBarContext';

const propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  accept: string.isRequired,
  onDownload: func.isRequired,
};

const useStyles = makeStyles(({ spacing, typography }) => ({
  container: {
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: typography.pxToRem(55),
  },
  notData: {
    cursor: 'pointer',
    padding: spacing(0.5, 2),
    border: '2px dashed rgba(0, 0, 0, 0.23)',
  },
  withData: {
    padding: spacing(0.5),
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
  title: { flex: '0 0 auto' },
  spacer: { flex: '1 1 100%' },
  actions: { display: 'flex' },
}));

const UploadFile = ({ name, label, accept, onDownload }) => {
  const classes = useStyles();
  const { showWarning } = useSnackBar();

  return (
    <Field
      name={name}
      render={({ field, form: { touched, errors, setFieldValue } }) => {
        const handleDropFile = file => setFieldValue(field.name, file[0]);
        const handleRemoveFile = () => setFieldValue(field.name, null);
        const handleDropRejectedFile = file =>
          showWarning(`Not soported file: ${file[0].name}`);

        const shortenName = file => {
          const splitted = file.split('.');
          if (splitted[0].length > 20) {
            const extension = splitted[1];
            const filename = splitted[0].slice(0, 17).concat('....');
            return filename.concat(extension);
          }
          return file;
        };

        const getFieldName = file => {
          if (file && file.name) {
            return shortenName(file.name);
          }
          const splittedFile = file.split('/').pop();
          return shortenName(splittedFile);
        };

        return (
          <FormControl
            fullWidth
            error={Boolean(touched[field.name] && errors[field.name])}
          >
            <Dropzone
              accept={accept}
              multiple={false}
              onDrop={handleDropFile}
              onDropRejected={handleDropRejectedFile}
            >
              {({ getRootProps, getInputProps }) =>
                field.value ? (
                  <div className={`${classes.container} ${classes.withData}`}>
                    <InsertDriveFile color="secondary" />
                    <div className={classes.title}>
                      <Typography variant="body2">
                        {getFieldName(field.value)}
                      </Typography>
                    </div>
                    <div className={classes.spacer} />
                    <div className={classes.actions}>
                      {!field.value.name ? (
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={onDownload}
                        >
                          <VerticalAlignBottom />
                        </IconButton>
                      ) : null}
                      <IconButton size="small" onClick={handleRemoveFile}>
                        <Clear color="error" />
                      </IconButton>
                    </div>
                  </div>
                ) : (
                  <Tooltip title={`Drag and Drop file here (${accept})`}>
                    <div
                      className={`${classes.container} ${classes.notData}`}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <CloudUpload fontSize="large" color="secondary" />
                      <Typography variant="body2">{label}</Typography>
                    </div>
                  </Tooltip>
                )
              }
            </Dropzone>
            <FormHelperText>
              <ErrorMessage name={name} />
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

UploadFile.propTypes = propTypes;

export default UploadFile;
