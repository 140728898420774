import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { string, bool, func, shape } from 'prop-types';
import { Can, usePermissions } from '../../../contexts/AuthContext';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import TextField from '../../../components/TextField/TextField';
import ModalForm from '../../../components/ModalForm/ModalForm';
import UploadField from '../../../components/UploadField/UploadFile';
import SelectField from '../../../components/SelectField/SelectField';
import AutoCompleteSelectField from '../../../components/AutoCompleteSelectField/AutoCompleteSelectField';
import { ProvidersResource, CustomerResource } from '../../../services/http';
import states from '../../../constants/states';
import validations from './validations';

const propTypes = {
  title: string.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  data: shape({}),
};

const defaultProps = {
  data: {},
};

const FindAProviderForm = ({ title, open, onClose, data }) => {
  const { showSuccess, showError } = useSnackBar();
  const permissions = usePermissions();
  const initialValues = {
    firstName: data.firstName || '',
    lastName: data.lastName || '',
    avatar: data.avatar || '',
    address: data.address || '',
    city: data.city || '',
    state: data.state || '',
    clinic: data.clinic || '',
    specialty: data.specialty || '',
    residency: data.residency || '',
    medicalSchool: data.medicalSchool || '',
    boardCertification: data.boardCertification || '',
    hospitalAffiliation: data.hospitalAffiliation || '',
    professionalOrganizations: data.professionalOrganizations || '',
    customerId: data.customer
      ? { label: data.customer.name, value: data.customer.id }
      : '',
    haveCustomer: permissions.can('create', 'Provider', 'customerId'),
  };
  const handleSubmit = async (
    { haveCustomer, customerId, avatar, ...values },
    { setSubmitting },
  ) => {
    try {
      const provider = await ProvidersResource.saveMultipart({
        ...values,
        ...(data.id && { id: data.id }),
        ...(customerId && { customerId: customerId.value }),
        avatar,
      });

      onClose(null, 'submitClick', provider);
      showSuccess(`Successfully ${data.id ? 'updated' : 'added'} Provider`);
    } catch {
      showError('An error occurs, please try again.');
    } finally {
      setSubmitting(false);
    }
  };
  const handleDownload = () =>
    window.open(ProvidersResource.getImage(data.id), '__blank', 'noopener');

  const fetchCustomerOptions = async name => {
    try {
      const { results } = await CustomerResource.getAll({ name });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };

  return (
    <ModalForm
      fullWidth
      open={open}
      title={title}
      onClose={onClose}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validations}
      submitLabel={data.id ? 'Save' : 'Create'}
      render={() => (
        <Grid container spacing={2}>
          <Can I="create customerId" a="Provider">
            <Grid item xs={12}>
              <AutoCompleteSelectField
                label="Customer"
                name="customerId"
                loadOptions={fetchCustomerOptions}
              />
            </Grid>
          </Can>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="text"
              name="firstName"
              label="First Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="text"
              name="lastName"
              label="Last Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UploadField
              name="avatar"
              label="Avatar"
              accept="image/png,image/jpeg,image/jpg"
              onDownload={handleDownload}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="text"
              name="clinic"
              label="Clinic"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="text"
              name="address"
              label="Address"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="text"
              name="city"
              label="City"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField
              fullWidth
              name="state"
              label="State"
              variant="outlined"
              options={states}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="text"
              name="medicalSchool"
              label="Medical School"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="text"
              name="residency"
              label="Residency"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography paragraph>
              All the fields below accept multiple entries. Please separate each
              entry using the comma (,) symbol.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="text"
              name="boardCertification"
              label="Board Certification"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="text"
              name="hospitalAffiliation"
              label="Hospital Affiliation"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="text"
              name="professionalOrganizations"
              label="Professional Organizations"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="text"
              name="specialty"
              label="Specialty"
              variant="outlined"
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

FindAProviderForm.propTypes = propTypes;
FindAProviderForm.defaultProps = defaultProps;

export default FindAProviderForm;
