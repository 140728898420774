import React from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import TextField from '../../../../../components/TextField/TextField';
import ModalForm from '../../../../../components/ModalForm/ModalForm';
import validations from './validations';
import { usePADetailContext } from '../../PatientAccountDetailContext';

const PaymentForm = () => {
  const {
    formData,
    isPaymentFormOpen,
    paymentFormTitle,
    hidePaymentForm,
    handleSubmitPayment,
  } = usePADetailContext();
  const initialValues = {
    from: formData.from || '',
    amount: formData.amount || '',
  };

  return (
    <ModalForm
      fullWidth
      maxWidth="sm"
      open={isPaymentFormOpen}
      onClose={hidePaymentForm}
      onSubmit={handleSubmitPayment}
      validationSchema={validations}
      initialValues={initialValues}
      submitLabel={formData.id ? 'Save' : 'Create'}
      title={paymentFormTitle}
      render={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              name="amount"
              type="number"
              label="Payment amount"
              variant="outlined"
              InputProps={{
                inputProps: { min: 1, step: '0.01' },
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              name="from"
              type="text"
              label="Payment received from"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default PaymentForm;
