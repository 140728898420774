import Resource from './base';

const baseUrl = '/conversations';

const actions = client => ({
  async getUnreadsCount() {
    return client.get(`${baseUrl}/unreads`);
  },
});

export default Resource(baseUrl, actions);
