import React, { Fragment } from 'react';
import { bool, func, string, shape, number } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import {
  Avatar,
  Checkbox,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
} from '@material-ui/core';

const propTypes = {
  marked: bool,
  selected: bool,
  onSelect: func,
  onMarked: func,
  data: shape({
    identifier: number.isRequired,
    name: string.isRequired,
    subject: string.isRequired,
    schedule: string.isRequired,
    read: bool.isRequired,
  }).isRequired,
};

const defaultProps = {
  marked: false,
  selected: false,
  onSelect: () => {},
  onMarked: () => {},
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  itemIcon: { minWidth: 'auto' },
  item: { paddingRight: spacing(1) },
  avatar: {
    overflow: 'visible',
    position: 'relative',
    backgroundColor: grey[700],
    '& span': {
      top: 0,
      right: 0,
      width: 12,
      height: 12,
      position: 'absolute',
      backgroundColor: palette.primary.main,
      borderRadius: '50%',
      border: '2px solid #fff',
    },
  },
  schedule: {
    float: 'right',
    marginLeft: spacing(1),
  },
  selected: {
    color: palette.primary.contrastText,
    backgroundColor: `${palette.primary.main} !important`,
  },
  contrastCheckbox: { color: palette.primary.contrastText },
}));

const getInitials = name => {
  return name
    .split(' ')
    .map(n => n[0])
    .slice(0, 3)
    .join('')
    .toUpperCase();
};

const Item = ({ data, marked, selected, onSelect, onMarked }) => {
  const classes = useStyles();

  const handleMarked = e => {
    e.stopPropagation();
    onMarked(data.identifier);
  };

  return (
    <ListItem
      button
      divider
      disableGutters
      selected={selected}
      onClick={() => onSelect(data)}
      classes={{ root: classes.item, selected: classes.selected }}
    >
      <ListItemIcon className={classes.itemIcon}>
        <Checkbox
          checked={marked}
          onClick={handleMarked}
          color={selected ? 'default' : 'primary'}
          classes={{ root: selected ? classes.contrastCheckbox : null }}
        />
      </ListItemIcon>
      <ListItemAvatar>
        <Avatar className={classes.avatar}>
          {!data.read ? <span /> : null}
          {getInitials(data.name)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <Fragment>
            <Typography variant="caption" className={classes.schedule}>
              {data.schedule}
            </Typography>
            <Typography noWrap>{data.name}</Typography>
          </Fragment>
        }
        secondary={
          <Typography noWrap variant="body2">
            {data.subject}
          </Typography>
        }
      />
    </ListItem>
  );
};

Item.propTypes = propTypes;
Item.defaultProps = defaultProps;

export default Item;
