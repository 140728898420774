import { object, string } from 'yup';

export default object().shape({
  name: string()
    .required()
    .label('Location name'),
  customerId: object()
    .when('haveCustomer', {
      is: true,
      then: object().required(),
    })
    .label('Customer'),
});
