import React from 'react';
import { string } from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import { TextField as MuiTextField } from '@material-ui/core';

const propTypes = {
  name: string.isRequired,
};

const TextField = ({ name, ...props }) => (
  <Field
    name={name}
    render={({ field, form: { touched, errors } }) => (
      <MuiTextField
        error={Boolean(touched[field.name] && errors[field.name])}
        helperText={<ErrorMessage name={name} />}
        {...field}
        {...props}
      />
    )}
  />
);

TextField.propTypes = propTypes;

export default TextField;
