import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { red } from '@material-ui/core/colors';
import { Typography } from '@material-ui/core';
import { Create as EditIcon } from '@material-ui/icons';
import { ConversationResource } from '../../../services/http';
import MessagesOn from '../../../assets/images/message-on.png';
import MessagesOff from '../../../assets/images/message-off.png';
import ButtonLink from '../../../components/Link/Button';
import Card from '../../../components/Card/Card';
import MessageModal from '../../Messages/MessageModal/MessageModal';
import useModal from '../../../hooks/modal';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  imgResponsive: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
    margin: '0 auto',
  },
  withMessages: {
    position: 'relative',
    '& span': {
      top: 40,
      width: 40,
      height: 40,
      color: '#fff',
      display: 'flex',
      fontWeight: 600,
      position: 'absolute',
      alignItems: 'center',
      borderRadius: '100%',
      justifyContent: 'center',
      backgroundColor: red.A700,
      fontSize: typography.pxToRem(18),
      [breakpoints.only('xs')]: { right: '36.5%' },
      [breakpoints.only('sm')]: { right: '33.5%' },
      [breakpoints.only('md')]: { right: '26.5%' },
      [breakpoints.only('lg')]: { right: '36%' },
      [breakpoints.up('xl')]: { right: '38%' },
    },
  },
}));

const DashBoardPage = () => {
  const classes = useStyles();
  const [unreads, setUnreads] = useState(0);
  const [loading, setLoading] = useState(true);
  const { isModalOpen, showModal, hideModal } = useModal();

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const counts = await ConversationResource.getUnreadsCount();
        const total = counts.reduce(
          (acc, val) => parseInt(acc, 10) + parseInt(val, 10),
          0,
        );
        setUnreads(total);
      } catch {
        setUnreads(0);
      } finally {
        setLoading(false);
      }
    };

    fetchCounts();
  }, []);

  return (
    <Card
      title="Messages"
      actionLabel="Compose"
      loading={loading}
      onAction={showModal}
      actionIcon={<EditIcon />}
    >
      {unreads > 0 ? (
        <div className={classes.withMessages}>
          <span>{unreads}</span>
          <img
            src={MessagesOn}
            alt="Messages Icon"
            className={classes.imgResponsive}
          />
        </div>
      ) : (
        <div className={classes.noMessages}>
          <img
            src={MessagesOff}
            alt="Messages Icon"
            className={classes.imgResponsive}
          />
        </div>
      )}
      <Typography gutterBottom variant="body1" align="center">
        You have
        <br />
        <strong>{unreads} new messages</strong>
      </Typography>
      <ButtonLink fullWidth to="/messages" variant="text" color="primary">
        View all messages
      </ButtonLink>
      <MessageModal isGeneral open={isModalOpen} onClose={hideModal} />
    </Card>
  );
};

export default DashBoardPage;
