import { useState } from 'react';

export default () => {
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);

  return {
    total,
    setTotal,
    limit,
    setLimit,
    page,
    setPage,
  };
};
