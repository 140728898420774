import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { shape } from 'prop-types';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { Typography, Grid, Button, Hidden } from '@material-ui/core';
import {
  ClinicResource,
  LocationResource,
  PatientAccountResource,
} from '../../../services/http';
import AutoCompleteSelectField from '../../../components/AutoCompleteSelectField/AutoCompleteSelectField';
import TextField from '../../../components/TextField/TextField';
import ButtonLink from '../../../components/Link/Button';
import Card from '../../../components/Card/Card';
import useModal from '../../../hooks/modal';
import PatientAccountForm from '../../PatientAccount/PatientAccountForm/PatientAccountForm';
import { usePermissions, useAuth } from '../../../contexts/AuthContext';

const useStyles = makeStyles(({ spacing, typography, breakpoints }) => ({
  content: {
    width: 210,
    height: 210,
    display: 'flex',
    color: grey[400],
    fontWeight: 500,
    textAlign: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    marginBottom: spacing(3),
    justifyContent: 'center',
    fontSize: typography.pxToRem(90),
    border: `10px solid ${grey[200]}`,
    [breakpoints.down('md')]: {
      width: 175,
      height: 175,
      fontSize: typography.pxToRem(80),
    },
    '& div p': { margin: 0 },
    '& div span': {
      display: 'block',
      fontWeight: 400,
      color: grey[350],
      marginTop: -spacing(2.5),
      fontSize: typography.pxToRem(16),
    },
  },
}));

const propTypes = {
  history: shape({}).isRequired,
};

const PatientAccount = ({ history }) => {
  const initialValues = {
    clinic: '',
    patient: '',
    location: '',
  };
  const classes = useStyles();
  const permissions = usePermissions();
  const [users, setUsers] = useState(0);
  const [loading, setLoading] = useState(true);
  const { isModalOpen, showModal, hideModal } = useModal();
  const { isParalegal, isAttorney } = useAuth();
  const changeTitle = isAttorney || isParalegal;
  const handleSubmit = ({ patient, clinic, location }) => {
    const values = {
      ...(patient !== '' && { patient }),
      ...(clinic !== '' && { clinic: clinic.value }),
      ...(location !== '' && { location: location.value }),
    };
    const queryString = Object.keys(values)
      .map(key => `${key}=${values[key]}`)
      .join('&');

    history.push(`/patient-account?${queryString}`);
  };
  const fetchClinicsOptions = async name => {
    try {
      const { results } = await ClinicResource.getAll({ name });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };
  const fetchLocationsOptions = async name => {
    try {
      const { results } = await LocationResource.getAll({ name });
      return results.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } catch {
      return [];
    }
  };

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const { patientAccounts } = await PatientAccountResource.counts();
        setUsers(patientAccounts);
      } catch {
        setUsers(0);
      } finally {
        setLoading(false);
      }
    };

    fetchCounts();
  }, []);

  return (
    <Card
      title={`${changeTitle ? 'Clients' : 'Patient Accounts'} Search`}
      loading={loading}
      {...(permissions.can('create', 'PatientAccount') && {
        actionLabel: 'Add patient account',
        actionIcon: <AddIcon />,
        onAction: showModal,
      })}
      showOverflow
    >
      <Grid container spacing={2} alignItems="center">
        <Hidden smDown>
          <Grid item container justify="center" md={5}>
            <div className={classes.content}>
              <div>
                <p>{users}</p>
                <span>{changeTitle ? 'Clients' : 'Accounts'} Created</span>
              </div>
            </div>
            <ButtonLink
              fullWidth
              variant="text"
              color="primary"
              to="/patient-account"
            >
              View all {changeTitle ? 'clients' : 'patients accounts'}
            </ButtonLink>
          </Grid>
        </Hidden>
        <Grid item sm={12} md={7}>
          <Typography paragraph align="center" variant="subtitle2">
            Find a {changeTitle ? 'client' : 'patient account'} using one or all
            of search functions
          </Typography>
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            render={() => (
              <Form>
                <TextField
                  fullWidth
                  label="Patient"
                  name="patient"
                  variant="outlined"
                />
                <AutoCompleteSelectField
                  label="Location"
                  name="location"
                  loadOptions={fetchLocationsOptions}
                />
                <AutoCompleteSelectField
                  label="Clinic"
                  name="clinic"
                  loadOptions={fetchClinicsOptions}
                />
                <Button
                  fullWidth
                  type="submit"
                  color="secondary"
                  variant="contained"
                >
                  Search
                </Button>
              </Form>
            )}
          />
        </Grid>
      </Grid>
      <PatientAccountForm
        title="Add new patient account"
        open={isModalOpen}
        onClose={hideModal}
      />
    </Card>
  );
};

PatientAccount.propTypes = propTypes;

export default withRouter(PatientAccount);
