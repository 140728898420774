import { object, string } from 'yup';

export default object().shape({
  customerId: object()
    .when('haveCustomer', {
      is: true,
      then: object().required(),
    })
    .label('Customer'),
  patientAccountId: object()
    .when('havePatient', {
      is: true,
      then: object().required(),
    })
    .label('Patient account'),
  recipientId: object()
    .when('haveRecipient', {
      is: true,
      then: object().required(),
    })
    .label('To'),
  subject: string()
    .required()
    .label('Subject'),
  body: string()
    .required()
    .label('Comment'),
});
