import Resource from './base';

const baseUrl = '/reports';

const actions = client => ({
  download(id) {
    return `${
      client.defaults.baseURL
    }${baseUrl}/${id}/download/?access_token=${client.$getToken()}`;
  },
});

export default Resource(baseUrl, actions);
