import Resource from './base';

const baseUrl = '/patient-accounts';

const actions = client => ({
  counts() {
    return client.get(`${baseUrl}/counts`);
  },
});

export default Resource(baseUrl, actions);
