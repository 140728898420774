import { object, string, number } from 'yup';

export default object().shape({
  amount: number()
    .min(1)
    .required()
    .label('Payment amount'),
  from: string()
    .required()
    .label('Payment received from'),
});
