import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '../../../../components/TextField/TextField';
import UploadField from '../../../../components/UploadField/UploadFile';
import ModalForm from '../../../../components/ModalForm/ModalForm';
import validations from './validations';

const propTypes = {
  open: bool.isRequired,
  title: string.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  onDownload: func.isRequired,
  data: shape({}),
  type: string.isRequired,
};

const defaultProps = {
  data: {},
};

const FileForm = ({
  open,
  title,
  onClose,
  onSubmit,
  data,
  type,
  onDownload,
}) => {
  const initialValues = {
    file: data.file || '',
    title: data.title || '',
    type,
  };

  return (
    <ModalForm
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      validationSchema={validations}
      initialValues={initialValues}
      submitLabel={data.id ? 'Save' : 'Create'}
      title={title}
      render={() => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="title"
              type="text"
              label="Title"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UploadField
              name="file"
              label="File"
              accept="application/pdf"
              onDownload={() => onDownload(data)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="type"
              type="text"
              label="Type"
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

FileForm.propTypes = propTypes;
FileForm.defaultProps = defaultProps;

export default FileForm;
