import React from 'react';
import { bool, func, string } from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  useMediaQuery,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import useTheme from '@material-ui/styles/useTheme';

const propTypes = {
  title: string.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  subtitle: string,
  submitLabel: string,
};

const defaultProps = {
  submitLabel: 'Submit',
  subtitle: null,
};

const ModalDialog = ({
  title,
  open,
  onClose,
  onConfirm,
  subtitle,
  submitLabel,
  ...props
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen} {...props}>
      <DialogTitle>{title}</DialogTitle>
      {subtitle && (
        <DialogContent>
          <DialogContentText>{subtitle}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={onConfirm}>
          {submitLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModalDialog.propTypes = propTypes;
ModalDialog.defaultProps = defaultProps;

export default ModalDialog;
